import React from "react";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import iconBan from "assets/img/iconBan.svg";
import iconPrefer from "assets/img/iconPrefer.svg";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";


export default function usePreferBan(disabled, isRatingAll = false) {
  return {
    Header: "",
    id: "ban",
    styles: {
      minWidth: '30px'
    },
    sortable: !isRatingAll,
    Cell: ({ row: { original: { workerId } } }) => {
      const prefered = useSelector(JobDetailSelectors.getPreferred);
      const ban = useSelector(JobDetailSelectors.getBanned);
      const canPrefer = prefered.includes(workerId)
      const canBan = ban.includes(workerId)
      return (
        <>
          <Typography variant="body1">
            {canPrefer && <img src={iconPrefer} />}
            {canBan && <img src={iconBan} />}
          </Typography>
        </>
      );
    },
  };
}
