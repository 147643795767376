import React from "react";
import TextField from "@mui/material/TextField";

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

export default function Control(props) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                disableUnderline: true,
                inputProps: {
                    className: props.selectProps.classes.input || "",
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}
