import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
  },
  frame: {
    flex: 1,
    marginTop: -73,
    [theme.breakpoints.down("sm")]: {
      marginTop: -8,
    },
    border: "none"
  },
  loader: {
    top: 0,
    position: "absolute",
    width: "100%"
  }
}));

export default function Index() {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  const handleStopLoading = () => {
    setTimeout(() => {
      setIsLoading(false)
    }, 2000);
  };

  return (
    <div className={classes.root}>
      {isLoading && <div className={classes.loader}>
        <div className="spinner" style={{ width: "100%" }}>
          <div className="bounce1" style={{marginTop:50}} />
          <div className="bounce2" style={{marginTop:50}} />
          <div className="bounce3" style={{marginTop:50}} />
        </div>
      </div>}
      <iframe
        className={classes.frame}
        src='/chat-support-iframe?isWeb=true'
        onError={handleStopLoading}
        onLoad={handleStopLoading} />
    </div>
  )
}
