/* eslint-disable react/prop-types, react/jsx-handler-names, no-unsafe-optional-chaining */

import React from "react";
import AsyncSelect from 'react-select/async';
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";

import debounce from "debounce-promise";

import Control from "./components/Control";
import Menu from "./components/Menu";
import MultiValue from "./components/MultiValue";
import NoOptionsMessage from "./components/NoOptionsMessage";
import Option from "./components/Option";
import Placeholder from "./components/Placeholder";
import SingleValue from "./components/SingleValue";
import ValueContainer from "./components/ValueContainer";

import { fetchJobs } from "./helpers/actions";
import baseStyles from "./helpers/styles";
import { connect } from "react-redux";

const styles = theme => {
    const base = baseStyles(theme);
    return {
        ...base,
        paper: {
            ...base.paper,
            position: "absolute !important",
            bottom: "auto !important",
            top: "calc(100% - 10px) !important",
        },
    };
};
class JobAutocomplete extends React.Component {
    state = { isFocused: false };
    selectComponents = {
        Control,
        Menu,
        MultiValue,
        NoOptionsMessage,
        Option,
        Placeholder,
        SingleValue,
        ValueContainer,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
    };

    get selectStyles() {
        return {
            container: base => {
                return {
                    ...base,
                    width: '100%'
                };
            },
            clearIndicator: base => {
                return {
                    ...base,
                    padding: 0,
                    cursor: "pointer",
                };
            },
            loadingIndicator: base => {
                return {
                    ...base,
                    padding: 0,
                };
            },
            input: base => {
                return {
                    ...base,
                    color: this.props.theme.palette.text.primary,
                    "& input": {
                        font: "inherit",
                    },
                };
            },
        };
    }

    componentDidMount() {
        const { searchWithEmployeId, accountId, accountType } = this.props;
        fetchJobs('', searchWithEmployeId ? { accountId, accountType } : '')
    }

    render() {
        const { classes, searchWithEmployeId, accountId, accountType, ...passProps } = this.props;
        return (
            <AsyncSelect
                onMenuOpen={() => this.setState({ isFocused: true })}
                onMenuClose={() => this.setState({ isFocused: false })}
                classes={classes}
                styles={this.selectStyles}
                isClearable
                value={this.props.value}
                placeholder=""
                defaultOptions
                loadOptions={debounce((e) => fetchJobs(e, searchWithEmployeId ? { accountId, accountType } : ''), 700)}
                onChange={this.props.onChange}
                components={this.selectComponents}
                textFieldProps={{
                    label: this.props.label || "",
                    required: this.props.required || false,
                    error: this.props.error || '',
                    helperText: this.props.helperText || '',
                    InputLabelProps: {
                        shrink: Boolean(this.props.value || this.state.isFocused),
                    }
                }}
            />
        );
    }
}

JobAutocomplete.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    searchWithEmployeId: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const { me } = state?.login;
    return {
        accountId: me?.accountId,
        accountType: me?.accountType
    }
}

export default connect(mapStateToProps, () => ({}))(withStyles(styles, { withTheme: true })(JobAutocomplete));
