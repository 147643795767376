import {gql} from "@apollo/client";

export default gql`
  query getRatingOption($platform: String!) {
    ratingOption(platform: $platform) {
        id
        option
        platform
        isActive
    }
  }
`;
