import React from 'react'
import Dialog from "components/Dialog/Dialog";
import { Grid, Typography } from "@mui/material";
function PostJobDialog(props) {
    const { handleClose, openPostJob, confirmJob, Time} = props;
    return (
        <>
            <Dialog
                open={openPostJob}
                onClose={handleClose}
                alertType="form"
                confirmText="Post Job"
                onConfirm={confirmJob}
                onCancel={handleClose}
            >
                <Grid container direction="column" alignItems="left">
                    <Typography variant="h4" align="left" mb={2}>
                        This shift is<span style={{ color: "red" }}> 10+ hours.</span>
                    </Typography>
                </Grid>
                <Grid container direction="column" alignItems="center">
                    <Typography variant="body1" align="left">
                        <div>
                            The standard hours of work for employees are either 8 hours a day or 7.5 hours a day.
                        </div>
                        <div>
                            {Time}
                        </div>
                    </Typography>
                </Grid>
            </Dialog>
        </>
    )
}
export default PostJobDialog