import React from 'react';
import {
  useTable, usePagination, useRowSelect, useExpanded, useSortBy, useFilters
} from 'react-table';
import {
  CircularProgress, Paper, Table,
  TableFooter, TableRow, Typography
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import DefaultColumnFilter from './filterFunctions/DefaultColumnFilter';
import IndeterminateCheckbox from './filterFunctions/IndeterminateCheckbox';
import TablePaginationActions from './TablePaginationActions';
import '../../assets/scss/react-table.css';

const ReactTableSimple = ({ columns, data, loading, classes, title, onChangeSelection, resetSelection, expandView, hasFilter }) => {

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,
    page,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
    toggleAllRowsSelected,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: {
        filters: [],
        pageIndex: 0,
      },
      // manualPagination: true, // Tell the usePagination
      // debug: true,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  //Listen for changes in select checkboxes
  React.useEffect(() => {
    if (onChangeSelection) {
      onChangeSelection(selectedFlatRows);
    }
  }, [selectedRowIds]);

  React.useEffect(() => {
    toggleAllRowsSelected(false)
  }, [resetSelection]);

  // Render the UI for your table
  return (
    <>
      <Paper className="react-table-main">
        {title && (
          <Typography
            variant="h5"
            className="title"
            color="primary"
          >
            {title}
          </Typography>
        )}
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <React.Fragment key={i}>
                <tr {...headerGroup.getHeaderGroupProps()} key={i} className="header-row">
                  {onChangeSelection && (<th>
                    <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                  </th>)}
                  {headerGroup.headers.map((column, index) => (
                    <th key={index} {...column.getHeaderProps(column.getSortByToggleProps())} className={column.canSort ? 'cursor-pointer' : ''} style={column.styles ? column.styles : {}}>
                      {column.render('Header')}
                      {column.canSort && <span className='sort-arrow'>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? <i className="fas fa-caret-down"></i>
                            : <i className="fas fa-caret-up"></i>
                          : <i className="fas fa-sort"></i>}
                      </span>}
                    </th>
                  ))}
                </tr>
                {hasFilter && <tr className="header-filter-row">
                  {/* Render the columns filter UI */}
                  {headerGroup.headers.map((column, ind) => (
                    <th {...column.getHeaderProps()} key={ind}>
                      <div>{column.canFilter ? column.render('Filter') : null}</div>
                    </th>
                  ))}
                </tr>}
              </React.Fragment>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr {...row.getRowProps()} className="data-row">
                    {onChangeSelection && (<td>
                      <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                    </td>)}
                    {row.cells.map((cell, index) => {
                      return <td key={index} {...cell.getCellProps()} className={cell.column.className ? cell.column.className : ''} style={cell.column.styles ? cell.column.styles : {}}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>
                        {expandView(row)}
                      </td>
                    </tr>
                  ) : null}
                </React.Fragment>
              )
            })}
            {page.length === 0 && !loading && (
              <tr className="data-row">
                <td colSpan={30} className='align-center'>No rows found</td>
              </tr>
            )}
            {loading && (
              // Use our custom loading state to show a loading indicator
              <tr>
                <td style={{ padding: 0 }}>
                  <div className={classes.progressOverlay}>
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Table>
          <TableFooter>
            <TableRow>
              <TablePagination
                className="table-pagination-footer"
                colSpan={3}
                count={data.length}
                rowsPerPage={pageSize}
                page={pageIndex}
                onPageChange={(e, page) => {
                  gotoPage(page)
                }}
                onRowsPerPageChange={e => {
                  setPageSize(Number(e.target.value))
                }}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    </>
  )
};

export default ReactTableSimple;
