import {gql} from "@apollo/client";
import PositionWorkerFields from "../fragments/position-worker-fragment";

export default gql`
  query getJobWorkers($jobId: Int!, $employerId: String!) {
    workers(jobId: $jobId, positionCountByEmployer: $employerId) {
      edges {
        node {
          ...PositionWorkerFields
          jobRating(jobId: $jobId){
            id
            workerId
            workerRatingComment
            ratings{
              id
              ratingOptionId
              rating
              ratingOption{
                id
                option
              }
            }
          }
          workerId: id
          positionCountByEmployer(employerId: $employerId)
        }
      }
    }
  }
  ${PositionWorkerFields}
`;
