export const sharedReactTableProps = {
    showPageSizeOptions: true,
    manual: true,
    sortable: false,
    defaultSortDesc: false,
    multiSort: false,
    showPaginationBottom: true,
    filterable: true,
    showPageJump: false,
    showPaginationTop: false,
    resizable: false,
    className: "-highlight",
    minRows: 0,
    pageSizeOptions: [10, 20, 40, 80, 100], // No more than this
};
export const weekDaysList = [
    { label: 'MO', value: 2 },
    { label: 'TU', value: 3 },
    { label: 'WE', value: 4 },
    { label: 'TH', value: 5 },
    { label: 'FR', value: 6 },
    { label: 'SA', value: 7 },
    { label: 'SU', value: 1 }
];
export const monthDaysList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
