import {gql} from "@apollo/client";

export const postJobMutation = gql`
    mutation creatingJob($data: CreatingJobInput!) {
      creatingJob(data: $data) {
        id
      }
    }
`;

export default postJobMutation;