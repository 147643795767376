import React from "react";
import { Grid } from "@mui/material";
import Dialog from "components/Dialog/Dialog";
import useStyles from "./styles";

function FeesDialog(props) {
    const classes = useStyles();
    const { feesDialog, handleClickCloseFeesDialog } = props;
    return (
        <Dialog
            open={feesDialog}
            onConfirm={handleClickCloseFeesDialog}
            confirmText="Ok"
            alertType="info"
        >
            <div className={classes.total} style={{ textAlign: "left" }}>What Your fees pay for</div>
            <div className={classes.feesTextAlign}>
                <Grid container xs={12}>
                    <Grid item xs={2} className={classes.feesTextItem}>17%</Grid>
                    <Grid item xs={10} className={classes.feesTextItem}>Worker Deductions</Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={2} className={classes.feesTextItem}>60%</Grid>
                    <Grid item xs={10} className={classes.feesTextItem}>Internal Payroll</Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={2} className={classes.feesTextItem}>9%</Grid>
                    <Grid item xs={10} className={classes.feesTextItem}>Financing and Legal</Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={2} className={classes.feesTextItem}>5%</Grid>
                    <Grid item xs={10} className={classes.feesTextItem}>Marketing</Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={2} className={classes.feesTextItem}>3.5%</Grid>
                    <Grid item xs={10} className={classes.feesTextItem}>Software</Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={2} className={classes.feesTextItem}>5.5%</Grid>
                    <Grid item xs={10} className={classes.feesTextItem}>Other Operational costs</Grid>
                </Grid>
            </div>
        </Dialog>
    )
}

export default FeesDialog