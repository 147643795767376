import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete } from "@mui/icons-material";
import MobileTimePicker from "components/DateTimePicker/MobileTimePicker";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Grid, IconButton, MenuItem, Select, Button, TextField, Typography } from "@mui/material";
import { find, isEqual, remove, cloneDeep } from "lodash";
import moment from "moment";
import ButtonCustom from "components/Button/Button";
import { weekDaysList } from "utils/constant";
import { ordinalSuffixOf } from "utils/stringUtils";
import { convertUTCToTimezone, getDaysOfWeekBetweenDates, getTotalTime } from "utils/time";
import { actions as PostJobActions, selectors as PostJobSelectors } from "store/modules/postJob";
import useStyles from "./styles";

function AddAnnouncement({ handleNext }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const announcement = useSelector(PostJobSelectors.getAnnouncement);
  const { timezone, ...postJobData } = useSelector(PostJobSelectors.getPostJobFormData);
  const [selectedWeekDays, setSelectedWeekDays] = useState([]);
  const [totalTimeInHours, setTotalTimeInHours] = useState(0);
  const isRecurring = postJobData.isRecurring === "true";
  const startTime = cloneDeep(postJobData.startTime);
  const recurringEndDate = cloneDeep(postJobData.recurringEndDate);
  const [allValidTime, setAllValidTime] = useState([...Array(announcement.length)].fill(true));

  useEffect(() => {
    if (selectedWeekDays && selectedWeekDays.length) {
      const data = announcement.map(a => {
        if (a.days && a.days.length) return { ...a, days: a.days.filter(d => selectedWeekDays.includes(d.value)) }
        // announcement[index].time = cloneDeep(startTime);
        return a;
      })
      dispatch(PostJobActions.setAnnouncement([...data]));
    }
  }, [selectedWeekDays]);

  useEffect(() => {
    moment.tz.setDefault(timezone);
    setTotalTimeInHours(getTotalTime(isRecurring ? recurringEndDate : startTime, moment()))
    setSelectedWeekDays(getDaysOfWeekBetweenDates(moment(), isRecurring ? recurringEndDate : startTime))
  }, [isRecurring, timezone]);

  const handleChange = (index, key, e) => {
    announcement[index][key] = e;
    if (key === "repeatType")
      announcement[index].time = cloneDeep(startTime);
    dispatch(PostJobActions.setAnnouncement([...announcement]));
  };

  const handleChangeAnnouncementTime = (index, e) => {
    announcement[index].time = e;
    const currentDay = moment().format("dd").toUpperCase();
    const jobStartDay = startTime.format("dd").toUpperCase();

    dispatch(PostJobActions.setAnnouncement([...announcement]));

    const data = [...Array(announcement.length)].map((_, i) => {
      return allValidTime.length ? allValidTime[i] : true;
    }),
      format = 'HH:mm',
      time = moment(e.format(format), format),
      currentTime = moment(moment().format(format), format),
      jobStartTime = moment(startTime.format(format), format);
    let isValid = true;

    if (find(announcement[index].days, (a) => isEqual(a.label, currentDay))) {
      isValid = isValid && currentTime.isBefore(time);
    }
    if (find(announcement[index].days, (a) => isEqual(a.label, jobStartDay))) {
      isValid = isValid && jobStartTime.isAfter(time);
    }

    if (totalTimeInHours < 24 && !isRecurring) {
      if (+currentTime.format("HH") > +jobStartTime.format("HH"))
        isValid = !time.isBetween(jobStartTime, currentTime)
      else
        isValid = time.isBetween(currentTime, jobStartTime);
    }
    data[index] = isValid;
    setAllValidTime(data);
  };

  const handleChangeRepeatDays = (index, e) => {
    const weekDays = announcement[index].days;
    if (find(weekDays, n => isEqual(n, e))) {
      remove(weekDays, n => isEqual(n, e));
    } else {
      weekDays.push(e);
    }
    announcement[index].days = weekDays;
    const currentDay = moment().format("dd").toUpperCase();
    const jobStartDay = startTime.format("dd").toUpperCase();
    const data = [...Array(announcement.length)].map((_, i) => {
      return allValidTime.length ? allValidTime[i] : true;
    });

    announcement.forEach((el, i) => {
      const format = 'HH:mm',
        time = moment(el.time.format(format), format),
        currentTime = moment(moment().format(format), format),
        jobStartTime = moment(startTime.format(format), format);
      let isValid = true;

      if (find(announcement[i].days, (a) => isEqual(a.label, currentDay))) {
        isValid = isValid && currentTime.isBefore(time);
      }
      if (find(announcement[i].days, (a) => isEqual(a.label, jobStartDay))) {
        isValid = isValid && jobStartTime.isSameOrAfter(time);
      }

      if (totalTimeInHours < 24 && !isRecurring) {
        if (+currentTime.format("HH") > +jobStartTime.format("HH"))
          isValid = !time.isBetween(jobStartTime, currentTime)
        else
          isValid = time.isBetween(currentTime, jobStartTime);
      }
      data[i] = isValid;
    });
    setAllValidTime(data);

    dispatch(PostJobActions.setAnnouncement([...announcement]));
  };

  const deleteAnnouncement = index => {
    announcement.splice(index, 1);
    setAllValidTime(a => {
      const data = [...a];
      data.splice(index, 1);
      return data;
    });
    dispatch(PostJobActions.setAnnouncement([...announcement]));
  };

  const addAnotherAnnouncement = () => {
    announcement.push({
      title: "",
      description: "",
      isRecurring: "false",
      time: cloneDeep(startTime),
      repeatType: "Once",
      before: 15,
      days: [],
    });
    dispatch(PostJobActions.setAnnouncement([...announcement]));
  };

  const skipAnnouncement = () => {
    dispatch(PostJobActions.setAnnouncement([{
      title: "",
      description: "",
      isRecurring: "false",
      time: moment(),
      repeatType: "Once",
      before: 15,
      days: [],
    }]));
    handleNext()
  }


  //check all announcment are valid or not
  const isValidTimes = announcement.every(a => {
    return a.repeatType !== "Weekly" || (a.days && a.days.length);
  });

  return (
    <>
      <h3>Add Announcement</h3>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Add an announcement that will notify the workers of this job
      </Typography>
      {announcement &&
        announcement.map((n, index) => (
          <div key={index}>
            {index != 0 && (
              <div className={classes.announcementHeading}>
                <p>{ordinalSuffixOf(index + 1)} announcement</p>
                <IconButton
                  className="closeButton"
                  color="primary"
                  onClick={() => deleteAnnouncement(index)}
                  aria-label="delete"
                  component="span"
                >
                  <Delete className="delete" />
                </IconButton>
              </div>
            )}
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  inputProps={{ maxLength: 100 }}
                  id="title"
                  name="title"
                  label="Announcement Title"
                  value={n.title}
                  onChange={e => handleChange(index, "title", e.target.value)}
                  fullWidth
                  margin="none"
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  inputProps={{ maxLength: 250 }}
                  id="description"
                  name="description"
                  label="Description"
                  value={n.description}
                  onChange={e => handleChange(index, "description", e.target.value)}
                  fullWidth
                  margin="none"
                  multiline
                  minRows={3}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                  REPEAT
                </Typography>
              </Grid>

              <Grid item md={6} xs={12}>
                <Select
                  labelId="repeat-type-select-label"
                  id="repeat-type-select-label"
                  value={n.repeatType}
                  onChange={e => handleChange(index, "repeatType", e.target.value)}
                  fullWidth
                >
                  <MenuItem value={"Once"}>Once</MenuItem>
                  <MenuItem value={"Weekly"}>Weekly</MenuItem>
                </Select>
              </Grid>
              <Grid item md={6} xs={12}>
                {n.repeatType === "Once" && (
                  <Select
                    labelId="before-select-label"
                    id="before-select-label"
                    value={n.before}
                    onChange={e => handleChange(index, "before", e.target.value)}
                    fullWidth
                  >
                    <MenuItem value={15}>15 minutes before</MenuItem>
                    <MenuItem value={30}>30 minutes before</MenuItem>
                    <MenuItem value={45}>45 minutes before</MenuItem>
                    <MenuItem value={60}>60 minutes before</MenuItem>
                  </Select>
                )}
                {n.repeatType === "Weekly" && (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileTimePicker
                      label="Time"
                      value={convertUTCToTimezone(n.time, timezone)}
                      onChange={e => handleChangeAnnouncementTime(index, e)}
                      renderInput={params => <TextField fullWidth {...params} error={allValidTime && !allValidTime[index]} helperText={allValidTime && !allValidTime[index] ? "Please select valid time" : ""} />}
                    />
                  </LocalizationProvider>
                )}
              </Grid>
              <Grid item xs={12}>
                {n.repeatType === "Weekly" && (
                  <div className={classes.weekDays} style={{ marginTop: "10px" }}>
                    {weekDaysList.map(day => (
                      <div
                        key={day.value}
                        onClick={() => handleChangeRepeatDays(index, day)}
                        className={`${classes.days} ${!selectedWeekDays.includes(day.value) && classes.disabledDay} ${n.days.indexOf(day) > -1 ? classes.selectedDays : ""}`}>
                        {day.label}
                      </div>
                    ))}
                  </div>
                )}
              </Grid>
              <Grid item md={12} sm={12} xs={12} />
            </Grid>
            <br />
          </div>
        ))}
      <p className={classes.addAnother} onClick={addAnotherAnnouncement}>
        + Add another announcement
      </p>
      <div className={classes.announceFooter}>
        <Button variant="text" onClick={skipAnnouncement}>Skip</Button>
        <ButtonCustom
          className={classes.continueButton}
          size="small"
          disabled={!announcement || !announcement.every(a => !!a.title && !!a.description) || !isValidTimes || (allValidTime.length && allValidTime.some(a => !a))}
          onClick={handleNext}
          label={"Continue"}
        />
      </div>
    </>
  );
}

export default AddAnnouncement;
