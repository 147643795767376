import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DialogActions, IconButton, MenuItem } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import iconEdit from "assets/img/iconEdit.svg";
import avatar from "assets/img/profile-placeholder.png";
import { Form, Formik } from "formik";
import * as yup from 'yup';
import Button from "components/CustomButtons/Button";
import Dialog from "components/Dialog/Dialog";
import { actions as ManagerActions, selectors as ManagerSelectors } from "store/modules/managers";

const useStyles = makeStyles(() => ({
  countryCode: {
    width: '100%',
    '& .MuiInputBase-root': {
      borderRadius: '4px 0 0 4px'
    },
  },
  mobilePhone: {
    '& .MuiInputBase-root': {
      borderRadius: '0 4px 4px 0'
    },
  },
  avatarImg: {
    margin: '10px auto',
    width: '100px',
    position: 'relative',
    '& img': {
      borderRadius: '100px',
      border: '2px solid #c4c4c4'
    },
    '& label': {
      position: 'absolute',
      right: '6px',
      background: '#ffffff',
      bottom: '0',
      padding: '0px',
      '& img': {
        padding: '2px',
      },
    }
  }
}));
function EditManagerDialog(props) {
  const { open, onClose, onCancel, onConfirm, data } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(ManagerSelectors.getIsAddingManager)
  const [profileURL, setProfileURL] = useState();
  const handleProfileFile = file => {
    const item = new Blob([file], { type: file.type });
    item.name = `profile_${file.name}`;
    setProfileURL(URL.createObjectURL(item))
    dispatch(ManagerActions.uploadManagerProfileImage(data.manager.id, item));
  }
  useEffect(() => {
    setProfileURL(data.manager.signedProfileImageUrl);
  }, [data])
  return (
    <Dialog
      open={open}
      title="Add Manager"
      onClose={onClose}
      onCancel={onCancel}
      alertType={'form'}
      hideActions
      size="sm"
      modalType="scrollable"
    >
      <Grid item xs={12} style={{ width: "100%", paddingLeft: 0, paddingRight: 0 }}>
        <Formik
          initialValues={{
            managerId: data.managerId,
            firstName: data.manager.firstName,
            lastName: data.manager.lastName,
            title: data.title,
            email: data.manager.email,
            mobilePhone: data.manager.mobilePhone,
            countryCode: '+1'
          }}
          onSubmit={(values, actions) => {
            onConfirm(values);
            actions.setSubmitting(false);
          }}
          validationSchema={yup.object().shape({
            email: yup.string().email('Please enter valid email').required("Required").max(100, 'Email address must be less than 100 characters'),
            // title: yup.string().required("Required"),
            firstName: yup.string().required("Required").max(20, 'First name must be less than 20 characters'),
            lastName: yup.string().required("Required").max(20, 'Last name must be less than 20 characters'),
            title: yup.string().required("Required").max(20, 'Title must be less than 20 characters'),
            // mobilePhone: yup.string().max(10, 'Please enter valid phone').min(10, 'Please enter valid phone').required("Required"),
            countryCode: yup.string().required("Required"),
          })}>
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
            } = props;

            const [mobilePhone, setMobilePhone] = useState(values.mobilePhone);
            const [changedMobilePhone, setChangedMobilePhone] = useState(false);

            const handleMobilePhoneChange = (e) => {
              if (e.target.value.match(/[0-9]/g)) {
                if (e.target.value.length <= 10) {
                  setMobilePhone(e.target.value)
                  values.mobilePhone = e.target.value
                  if (values.mobilePhone.length === 10) {
                    setChangedMobilePhone(true)
                  }
                  else {
                    setChangedMobilePhone(false)
                  }
                }
              }
              else {
                setMobilePhone("")
                setChangedMobilePhone(false)
              }
            }

            return (
              <Form>
                <Grid
                  item
                  container
                  direction="row"
                  xs={12}
                >
                  <Grid container item xs={12} md={12} spacing={1}>
                    <div className={classes.avatarImg}>
                      <img width={'100'} height={'100'} src={profileURL || avatar} onError={(e) => { e.target.onerror = null; e.target.src = avatar }} alt="..." />
                      <IconButton className="edit-icon" disableRipple component="label">
                        <img src={iconEdit} />
                        <input type="file" name="profileImage" hidden onChange={files => {
                          handleProfileFile(files.target.files[0])
                          // setFieldValue("profileImage", files.currentTarget.files[0])
                        }} />
                      </IconButton>
                    </div>
                  </Grid>
                  <Grid container item xs={12} md={12} spacing={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        className={classes.textField}
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        error={errors.firstName && touched.firstName}
                        helperText={errors.firstName && touched.firstName && errors.firstName}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        className={classes.textField}
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        error={errors.lastName && touched.lastName}
                        helperText={errors.lastName && touched.lastName && errors.lastName}
                        margin="normal"
                      />
                    </Grid>

                  </Grid>
                  <Grid item xs={12} md={12} >
                    <TextField
                      id="title"
                      name="title"
                      label="Title"
                      className={classes.textField}
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={errors.title && touched.title}
                      helperText={errors.title && touched.title && errors.title}
                      margin="normal"
                    />
                  </Grid>
                  <Grid container item xs={12} md={12}>
                    <TextField
                      id="email"
                      name="email"
                      label=" Email Address"
                      className={classes.textField}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={errors.email && touched.email}
                      helperText={errors.email && touched.email && errors.email}
                      margin="normal"
                      disabled
                    />
                  </Grid>
                  <Grid container direction="row" item xs={12} sm={12}>
                    <Grid container item xs={4} sm={2}>
                      <TextField
                        select
                        name="countryCode"
                        label="Code"
                        className={classes.countryCode}
                        value={values.countryCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                        helperText={
                          errors.countryCode && touched.countryCode && errors.countryCode
                        }
                        margin="normal"
                      >
                        <MenuItem value={'+1'}>
                          +1
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid container item xs={8} sm={10}>
                      <TextField
                        name="mobilePhone"
                        label="Mobile Phone"
                        className={classes.mobilePhone}
                        value={mobilePhone}
                        onChange={handleMobilePhoneChange}
                        fullWidth
                        type="number"
                        disabled
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <DialogActions className={classes.footer}>
                  <Button
                    type="button"
                    className="outline"
                    onClick={onCancel}
                    color="transparent"
                    round
                  >
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    round
                    disabled={!_.isEmpty(errors) || isLoading}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Dialog>
  );
}

export default EditManagerDialog;
