export const paginationSettings = {
  todayTotal: {
    prefix: "pagination_TodayTotal",
    defaultPageSize: 10,
  },
  todayActive: {
    prefix: "pagination_TodayActive",
    defaultPageSize: 10,
  },
  todayUnfilled: {
    prefix: "pagination_TodayUnfilled",
    defaultPageSize: 10,
  },
  todayFilled: {
    prefix: "pagination_TodayFilled",
    defaultPageSize: 10,
  },
  todayCompleted: {
    prefix: "pagination_TodayCompleted",
    defaultPageSize: 10,
  },
  tomorrowTotal: {
    prefix: "pagination_TomorrowTotal",
    defaultPageSize: 10,
  },
  tomorrowUnfilled: {
    prefix: "pagination_TomorrowUnfilled",
    defaultPageSize: 10,
  },
  tomorrowFilled: {
    prefix: "pagination_TomorrowFilled",
    defaultPageSize: 10,
  },
  upcomingTotal: {
    prefix: "pagination_UpcomingTotal",
    defaultPageSize: 10,
  },
  upcomingUnfilled: {
    prefix: "pagination_UpcomingUnfilled",
    defaultPageSize: 10,
  },
  upcomingFilled: {
    prefix: "pagination_UpcomingFilled",
    defaultPageSize: 10,
  },
  history: {
    prefix: "pagination_History",
    defaultPageSize: 10,
  },
  drafts: {
    prefix: "pagination_Drafts",
    defaultPageSize: 10,
  },
  queued: {
    prefix: "pagination_Queued",
    defaultPageSize: 10,
  },
};
