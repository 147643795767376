import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import TableComponent from "../TableComponent";

const FilledPage = (props) => {
  const dispatch = useDispatch();
  const pageInfo = useSelector(JobSelectors.getTodayFilledPaginationData)
  const jobListData = useSelector(JobSelectors.getListData({ timeframe: 'today', status: 'filled', part: "data" }))
  const pageFilter = useSelector(JobSelectors.getListData({ timeframe: 'today', status: 'filled', part: "filter" }))
  const pageSort = useSelector(JobSelectors.getListData({ timeframe: 'today', status: 'filled', part: "sort" }))
  const fetchData = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
    dispatch(JobActions.setTodayFilledPageSize(pageSize));
    dispatch(JobActions.setTodayFilledFilter(filters));
    dispatch(JobActions.setTodayFilledSort(sortBy));
    dispatch(JobActions.fetchTodayFilledJobs(pageIndex));
  }, []);

  return <TableComponent
    pageInfo={pageInfo}
    jobListData={jobListData}
    pageFilter={pageFilter}
    pageSort={pageSort}
    fetchData={fetchData}
    history={props.history}
  />
}

export default FilledPage