import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { find } from "lodash";
import Button from "components/Button/Button";
import { actions as PostJobActions, selectors as PostJobSelectors } from "store/modules/postJob";
import useStyles from "./styles";

const DESCRIPTION_LENGTH = 3000;

function WorkType({ handleNext }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const workType = useSelector(PostJobSelectors.getWorkTypeList);
  const selectedWorkType = useSelector(PostJobSelectors.getWorkType);
  const description = useSelector(PostJobSelectors.getDescription);

  useEffect(() => {
    dispatch(PostJobActions.fetchWorkTypes());
  }, []);

  const handleChange = e => {
    dispatch(PostJobActions.setWorkType(find(workType, n => n.value == e.target.value)));
  };

  const handleChangeDescription = e => {
    if (e.target.value.length <= DESCRIPTION_LENGTH) {
      dispatch(PostJobActions.setDescription(e.target.value));
    }
  };
  return (
    <>
      <h3>Type Of Work</h3>
      <div>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedWorkType.value}
          onChange={e => handleChange(e)}
        >
          {workType &&
            workType.map(n => (
              <FormControlLabel
                className={`${classes.checkboxLabel} ${selectedWorkType.value === n.value ? classes.selectedWorkType : ""
                  }`}
                key={n.value}
                value={n.value}
                control={<Radio />}
                label={n.label}
              />
            ))}
        </RadioGroup>
      </div>
      <h3>Description</h3>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Add details description for the job
      </Typography>
      <div className={classes.gearTextBox}>
        <TextField
          id="description"
          name="description"
          // label="Description"
          placeholder="Write here"
          value={description}
          onChange={handleChangeDescription}
          fullWidth
          margin="none"
          multiline
          minRows={4}
        />
      </div>
      <div className={classes.descriptionCountdown}>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">{`${description?.length || 0}/${DESCRIPTION_LENGTH} characters`}</Typography>
      </div>
      <Button
        className={classes.continueButton}
        size="small"
        disabled={selectedWorkType.value === 0 || !description}
        onClick={handleNext}
        label={"Continue"}
      />
    </>
  );
}

export default WorkType;
