import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import { Autocomplete, Chip, Divider, IconButton, TextField, Typography, FormControlLabel, Switch } from "@mui/material";
import { remove } from "lodash";
import Button from "components/Button/Button";
import { actions as MetaActions } from "store/modules/meta";
import { actions as PostJobActions, selectors as PostJobSelectors } from "store/modules/postJob";
import { actions as ProfileActions, selectors as ProfileSelectors } from "store/modules/profile";
import useStyles from "./styles";

function RequiredGear({ handleNext }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [gear, setGear] = useState("");
  const requiredGear = useSelector(PostJobSelectors.getRequiredGear);
  const gearList = useSelector(PostJobSelectors.getGearList);
  const [tag, setTag] = useState("");
  const employerData = useSelector(ProfileSelectors.getEmployerDetail);
  const isPrivate = useSelector(PostJobSelectors.getIsPrivateJob);
  const tags = useSelector(PostJobSelectors.getTags)
  const canPostPrivateJob = employerData.canPostPrivateJob
  const [gearTextLength, setGearTextLength] = useState(0)

  useEffect(() => {
    dispatch(PostJobActions.fetchGear());
    dispatch(ProfileActions.fetchEmployerDetail());
  }, []);

  useEffect(() => {
    if (!isPrivate) {
      dispatch(PostJobActions.setTags([]));
    }
  }, [isPrivate])

  const onIsPrivateChange = (isPrivate) => {
    dispatch(PostJobActions.setIsPrivateJob(isPrivate))
  };

  const handleChangeTag = (e) => {
    if (e.target.value.length <= 50) {
      setTag(e.target.value);
    }
  }

  const addTag = () => {
    if (tags.indexOf(tag) < 0) {
      if (tag.replace(/^\s+|\s+$/gm, '')) {
        const seletedTags = [...tags];
        seletedTags.push(tag);
        dispatch(PostJobActions.setTags(seletedTags));
        setTag("");
      }
    } else {
      dispatch(MetaActions.errorToast(`Tag already added`));
    }
  };

  const handleDeleteTags = (value) => {
    const seletedTags = [...tags];
    remove(seletedTags, n => n === value);
    dispatch(PostJobActions.setTags(seletedTags));
  }

  const handleChange = e => {
    if (e) {
      setGearTextLength(e.length)
      if (e.trim() && e.length <= 50) {
        setGear(e.trim());
      }
    } else {
      setGear(e);
    }
  };
  const addGear = () => {
    if (requiredGear.indexOf(gear) < 0) {
      if (gear.trim()) {
        const seleted = [...requiredGear];
        seleted.push(gear);
        dispatch(PostJobActions.setRequiredGear(seleted));
        setGear("");
      }
    } else {
      dispatch(MetaActions.errorToast(`Gear already added`));
    }
  };
  const handleDelete = value => {
    const seleted = [...requiredGear];
    remove(seleted, n => n === value);
    dispatch(PostJobActions.setRequiredGear(seleted));
  };

  const [skill, setSkill] = useState("");
  const skills = useSelector(PostJobSelectors.getSkills);

  const handleChangeSkill = e => {
    if (e.target.value.length <= 50) {
      setSkill(e.target.value);
    }
  };
  const addSkill = () => {
    if (skills.indexOf(skill) < 0) {
      if (skill.replace(/^\s+|\s+$/gm, '')) {
        const seleted = [...skills];
        seleted.push(skill);
        dispatch(PostJobActions.setSkills(seleted));
        setSkill("");
      }
    } else {
      dispatch(MetaActions.errorToast(`Skill already added`));
    }
  };
  const handleDeleteSkill = value => {
    const seleted = [...skills];
    remove(seleted, n => n === value);
    dispatch(PostJobActions.setSkills(seleted));
  };
  return (
    <>
      <h3>Required gear</h3>
      <div className={classes.gearTextBox}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          disableClearable={!gear}
          onChange={(event, newValue) => {
            handleChange(newValue)
          }}
          value={gear || ""}
          fullWidth
          options={gearList.map((option) => option.gearName)}
          renderInput={(params) =>
            <TextField
              {...params}
              value={gear}
              placeholder="Enter required gear"
              onChange={e => handleChange(e.target.value)}
              onKeyPress={e => {
                if (e.key === "Enter" && e.target.value) {
                  addGear();
                }
              }}
            />
          }
        />
        {/* <TextField
          id="gear"
          name="gear"
          placeholder="Enter required gear"
          value={gear}
          onChange={handleChange}
          fullWidth
          margin="none"
          onKeyPress={e => {
            if (e.key === "Enter" && e.target.value) {
              addGear();
            }
          }}
        /> */}
        <Button
          // size="small"
          disabled={!gear || gearTextLength > 50}
          onClick={addGear}
          label={"Add"}
        />
      </div>
      {requiredGear.length > 0 && (
        <div className={classes.selectedSection}>
          <Typography
            sx={{ fontSize: 14 }}
            color="text.secondary"
            gutterBottom
            justifyContent={"space-between"}
          >
            Selected ({requiredGear.length})
          </Typography>
          {requiredGear &&
            requiredGear.map((n, index) => (
              <Chip key={index} label={n} variant="outlined" onDelete={() => handleDelete(n)} />
            ))}
        </div>
      )}
      <br />
      <Divider />
      <h3>Suggested skills {canPostPrivateJob ? 'and tags' : ''}</h3>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        Make sure you add only critical information and keep this section very brief.
      </Typography>
      <div className={classes.skillsTagsSection}>
        {canPostPrivateJob && (
          <div>
            <div className={classes.tags}>
              <h6 style={{ marginRight: "3px", marginTop: '12px' }}>This Job is private</h6>
              <Switch
                id="isPrivate"
                defaultChecked={isPrivate}
                onChange={(e) => onIsPrivateChange(e.target.checked)}
                name="isPrivate"
                color="secondary"
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            {isPrivate && (
              <>
                <div className={classes.gearTextBox}>
                  <TextField
                    id="tag"
                    name="tag"
                    placeholder="Enter tags"
                    value={tag}
                    onChange={handleChangeTag}
                    fullWidth
                    margin="none"
                    onKeyPress={e => {
                      if (e.key === "Enter" && e.target.value) {
                        addTag();
                      }
                    }}
                  />
                  <Button
                    // size="small"
                    disabled={!tag}
                    onClick={addTag}
                    label={"Add"}
                  />
                </div>
                {tags.length > 0 && (
                  <div className={classes.selectedSection}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                      justifyContent={"space-between"}
                    >
                      Added Tags
                    </Typography>
                    {tags &&
                      tags.map((n, index) => (
                        <Chip key={index} label={n} variant="outlined" onDelete={() => handleDeleteTags(n)} />
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <div>
          {canPostPrivateJob && (
            <h6>Suggested skills</h6>
          )}
          <div className={classes.gearTextBox}>
            <TextField
              id="skill"
              name="skill"
              placeholder="Enter skills individually"
              value={skill}
              onChange={handleChangeSkill}
              fullWidth
              margin="none"
              onKeyPress={e => {
                if (e.key === "Enter" && e.target.value) {
                  addSkill();
                }
              }}
            />
            <Button
              // size="small"
              disabled={!skill}
              onClick={addSkill}
              label={"Add"}
            />
          </div>
          <div className={classes.selectedSection}>
            {skills.length > 0 && (
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                justifyContent={"space-between"}
              >
                Added Skills
              </Typography>
            )}
            {skills &&
              skills.map((n, index) => (
                <div key={index} className={classes.addedSkillList}>
                  <div className="content">{n}</div>
                  <IconButton
                    className="closeButton"
                    color="primary"
                    onClick={() => handleDeleteSkill(n)}
                    aria-label="delete"
                    component="span"
                  >
                    <Close />
                  </IconButton>
                </div>
              ))}
          </div>
          <Button
            className={classes.continueButton}
            size="small"
            disabled={requiredGear.length === 0 || skills.length === 0 || (isPrivate && tags.length === 0)}
            onClick={handleNext}
            label={"Continue"}
          />
        </div>
      </div>
    </>
  );
}

export default RequiredGear;
