import React from "react";
import Typography from "@mui/material/Typography";
import Line from "components/Placeholders/Line";

export default function useLocation() {
  return {
    Header: "Location",
    id: "location",
    styles: {
      minWidth: '100px'
    },
    sortable: false,
    Cell: ({ row: { original: { city, region } } }) => {
      return (
        <>
            <Typography variant="body1">
                {!city || !region ? <Line /> : `${city}, ${region}`}
            </Typography>
        </>
      );
    },
  };
}
