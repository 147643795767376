/* eslint-disable import-helpers/order-imports */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { convertUTCToTimezoneWithOffset, displayLocalDateTime, formattedTimeWithOffset } from 'utils/time';
import moment from 'moment';
import { useStyles } from '../styles';
import ReactTableSimple from 'components/ReactTable/ReactTableSimple';
import { actions as communicationActions, selectors as communicationSelectors } from "store/modules/communication";
import AnnouncementSubTable from '../AnnouncementSubTable';

export default function Table(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const announcement = useSelector(communicationSelectors.getAnnouncementsListData("data"))
    const isLoading = useSelector(communicationSelectors.getIsPageLoading)

    useEffect(() => {
        const params = new URLSearchParams(props.history.location.search);
        const tab = parseInt(params.get("tab"), 10);
        dispatch(communicationActions.fetchAnnouncements(tab));
    }, []);

    const columns = [
        {
            Header: "ID",
            id: "id",
            accessor: "id",
            Cell: ({ row: { original, isExpanded, ...row } }) => {
                return (
                    <span className={classes.expandCell}>
                        <IconButton {...row.getToggleRowExpandedProps()} style={{ padding: 3, marginLeft: -10 }}>
                            {isExpanded ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                        {original.id}
                    </span>
                )
            }
        },
        {
            Header: "Work Type",
            id: "workType.label",
            accessor: "workType.label",
            className: "-cursor-pointer",
            Cell: ({ row: { original } }) => {
                return original.workType ? original.workType.label : ''
            }
        },
        {
            Header: "Start Date",
            disableFilters: true,
            id: "startUtc",
            accessor: "startUtc",
            styles: {
                minWidth: '125px'
            },
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {convertUTCToTimezoneWithOffset(original.startUtc, original.timezone, "MMM Do, YYYY")}
                        <br />
                        {displayLocalDateTime(original.startUtc, original.timezone, "MMM Do, YYYY")}
                    </div>
                );
            },
            sortable: false,
            className: "-cursor-pointer",
        },
        {
            Header: "Shift",
            id: "Shift",
            disableFilters: true,
            disableSortBy: true,
            Cell: ({ row: { original } }) => {
                return formattedTimeWithOffset(original.startUtc, original.endUtc, original.timezone);
            },
            accessor: ({ startUtc, endUtc }) => {
                const startTime = moment(startUtc).format("h:mm a");
                const endTime = moment(endUtc).format("h:mm a");
                return `${startTime} - ${endTime}`;
            },

            className: "-cursor-pointer",
            styles: {
                minWidth: '150px'
            },
        },
        {
            Header: "Location",
            id: "city",
            accessor: ({ city, region }) => `${city}, ${region}`,
            maxWidth: 200,
            className: "-cursor-pointer",
            styles: {
                minWidth: '100px'
            },
        },
    ];

    return (
        <>
            <ReactTableSimple
                hasFilter
                classes={classes}
                loading={isLoading}
                columns={columns}
                data={announcement}
                expandView={(row) => {
                    return <AnnouncementSubTable data={row.original} />
                }}
            />
        </>
    )
}
