import React from 'react'
import { MobileDateTimePicker as MUIMobileDateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';

export default function MobileDateTimePicker({ onChange, ...props }) {
    return (
        <MUIMobileDateTimePicker
            {...props}
            onChange={e => onChange(moment(e))}
        />
    )
}
