
export const BanReasonsLabel = {
  LateOrNoShow: "Being late or not showing up",
  NotPrepared: "Worker was unprepared for work",
  Unprofessional: "Worker was unprofessional",
  PoorPerformance: "Poor worker performance",
  Other: "Other",
};

export const JobStatusesMap = {
  NotStarted: "NotStarted",
  InProgress: "InProgress",
  Completed: "Completed",
};

export const JobStatusesArray = ["NotStarted", "InProgress", "Completed"];

export const JobStatusesLabel = {
  NotStarted: "Not Started",
  InProgress: "In Progress",
  Completed: "Completed",
};

export const TimeframeMap = {
  Today: "Today",
  Tomorrow: "Tomorrow",
  Upcoming: "Upcoming",
  Past: "Past",
};

export const TimeframeArray = ["Today", "Tomorrow", "Upcoming", "Past"];

export const TimeframeLabel = {
  Today: "Today",
  Tomorrow: "Tomorrow",
  Upcoming: "Upcoming",
  Past: "History",
};

export const ManagerRoleLabel = {
  SiteManager: "Site manager",
  CheckInManager: "Check-in manager",
  CheckOutManager: "Check-out manager",
};

export const overTimeText = "Overtime pay is calculated: Hourly pay rate x 1.5 x overtime hours worked. Here is an example of total pay for an employee who worked 10 hours for the day: Regular pay rate x 8 hours = Regular pay, plus. Regular pay rate x 1.5 x 2 hours = Overtime pay, equals."

export const doubleTimeText = "Doubletime pay is calculated: Hourly pay rate x 2 x double-time hours worked. Here is an example of total pay for an employee who worked 15 hours for the day: Regular pay rate x 8 hours = Regular pay, plus. Regular pay rate x 1.5 x 4 hours = Overtime pay, plus. Regular pay rate x 2 x 3 hours = Doubletime pay, equals."
