import {gql} from "@apollo/client";

export default gql`
  query getAllWorkers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $bannedBy: String
    $preferredBy: String
    $employerId: String!
    $order: [WorkerOrderInput!]
    $like: [WorkerFilterInput!]
  ) {
    workers(
      first: $first
      last: $last
      after: $after
      before: $before
      bannedBy: $bannedBy
      preferredBy: $preferredBy
      positionCountByEmployer: $employerId
      like: $like
      order: $order
      isActive: true
      isApproved: true
      isDeleted: false
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          positionCountByEmployer(employerId: $employerId)
          fullName
          firstName
          lastName
          email
          phone
          profileImageUrl
          signedProfileImageUrl
          vaccinationStatus
          rating
          city
          region
          positionCount
          banCount
          noShowCount
          isSuspended
        }
      }
    }
  }
`;
