import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccessTime, AddCircle, RemoveCircleOutline } from "@mui/icons-material";
import MobileDatePicker from "components/DateTimePicker/MobileDatePicker";
import MobileTimePicker from "components/DateTimePicker/MobileTimePicker";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { FormControlLabel, Grid, IconButton, Radio, RadioGroup, TextField, Typography, MenuItem } from "@mui/material";
import { find, isEqual } from "lodash";
import { remove } from "lodash";
import * as moment from "moment";
import Button from "components/Button/Button";
import { monthDaysList, weekDaysList } from "utils/constant";
import { calcIsNextDayEnd, convertLocalToUTC, convertUTCToTimezone, getWeeksInTwoDates, getDaysOfWeekBetweenDates, getTimezoneAbbreviation, getDaysInTwoDates, getMonthsInTwoDates, getDaysOfMonthsBetweenDates, getTotalTime } from "utils/time";
import { actions as PostJobActions, selectors as PostJobSelectors } from "store/modules/postJob";
import useStyles from "./styles";

function DateDetail({ handleNext }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  // const [duration, setDuration] = useState('');
  const [selectedWeekDays, setSelectedWeekDays] = useState([]);
  const [selectedMonthDays, setSelectedMonthDays] = useState([]);
  const [totalRepeatEvery, setTotalRepeatEvery] = useState(1);
  const [validEndTime, setValidEndTime] = useState({ error: false, message: '' });
  const [validStartTime, setValidStartTime] = useState({ error: false, message: '' });
  const {
    isRecurring,
    date,
    startTime,
    endTime,
    breakType,
    breakDuration,
    recurringType,
    weekDays,
    repeatEvery,
    monthDate,
    recurringEndDate,
    duration,
    timezone
  } = useSelector(PostJobSelectors.getPostJobFormData);
  const [selectedRecurringType, setSelectedRecurringType] = useState(recurringType);

  const getDurationHours = () => {
    const hours = (duration.hours * 60) + duration.minutes;
    return Math.round((hours / 60) * 100) / 100
  }
  React.useEffect(() => {
    if (getDurationHours() <= 5) {
      dispatch(PostJobActions.setBreakType('unpaid'));
      dispatch(PostJobActions.setBreakDuration(0));
    } else {
      if (breakDuration === 0) {
        dispatch(PostJobActions.setBreakDuration(30));
      }
    }
  }, [duration])

  React.useEffect(() => {
    moment.tz.setDefault(timezone)
    if (!date || !startTime || !endTime) {
      const currentDate = moment(moment().format('YYYY-MM-DD HH:mm'));
      const remainder = 15 - (currentDate.minute() % 15);

      dispatch(PostJobActions.setDate(moment(currentDate).add(510 + remainder, 'minutes')));
      dispatch(PostJobActions.setStartTime(moment(currentDate).add(510 + remainder, 'minutes')));
      dispatch(PostJobActions.setEndTime(moment(currentDate).add(1020 + remainder, 'minutes')));
    }
  }, [timezone])

  React.useEffect(() => {
    if (endTime > startTime) {
      if (endTime.diff(startTime, 'hours') >= 4) {
        setValidEndTime({ error: false, message: '' })
      } else {
        setValidEndTime({ error: true, message: 'Minimum duration should be 4 hours' })
      }
    } else {
      setValidEndTime({ error: true, message: 'End time should be grater than start time' })
    }
  }, [endTime, startTime])

  useEffect(() => {
    if (isRecurring === "true") {
      let repeat = 1;

      switch (recurringType) {
        case "Daily": {
          repeat = getDaysInTwoDates(startTime, recurringEndDate)
          setTotalRepeatEvery(repeat);
          break;
        }
        case "Weekly": {
          repeat = getWeeksInTwoDates(startTime, recurringEndDate);
          setTotalRepeatEvery(repeat);
          const days = getDaysOfWeekBetweenDates(startTime, recurringEndDate);
          setSelectedWeekDays(days)
          dispatch(PostJobActions.setWeekDays(weekDays.filter(w => find(days, n => n === w?.value))));
          break;
        }
        case "Monthly": {
          repeat = getMonthsInTwoDates(startTime, recurringEndDate);
          const dates = getDaysOfMonthsBetweenDates(startTime, recurringEndDate);
          setTotalRepeatEvery(repeat)
          setSelectedMonthDays(dates)
          dispatch(PostJobActions.setMonthDate(monthDate.filter(m => find(dates, n => n === m))));
          break;
        }
      }
      handleRepeatEvery(1);
    }
  }, [recurringType, isRecurring, recurringEndDate]);


  const handleChangeDate = (e) => {
    if (e) {
      const start = moment({
        year: e.year(),
        month: e.month(),
        date: e.date(),
        hour: startTime.hour(),
        minute: startTime.minute(),
      });
      dispatch(PostJobActions.setDate(start));
      handleChangeStartTime(start, start);
      if (isRecurring == "true")
        handleChangeRecurringEndDate(moment(start).add(1, getMinRecurringEndDate(selectedRecurringType)));
    }
  };

  const handleChangeStartTime = (e, date) => {
    if (e) {
      const start = moment({
        year: date.year(),
        month: date.month(),
        date: date.date(),
        hour: e.hour(),
        minute: e.minute(),
      });
      if (start > moment()) {
        setValidStartTime({ error: false, message: '' })
      } else {
        setValidStartTime({ error: true, message: 'Start time should be greater then current time' })
      }
      const endDate = moment(start).add(510, 'minutes')
      dispatch(PostJobActions.setDuration({
        hours: 8,
        minutes: 30,
      }));
      dispatch(PostJobActions.setStartTime(start));
      dispatch(PostJobActions.setEndTime(endDate));
    }
  };


  const handleChangeEndTime = (e) => {
    if (e) {
      const start = moment({
        year: date.year(),
        month: date.month(),
        date: date.date(),
        hour: startTime.hour(),
        minute: startTime.minute(),
      });

      const end = moment({
        year: date.year(),
        month: date.month(),
        date: date.date(),
        hour: e.hour(),
        minute: e.minute(),
      });
      const isNextDayEnd = calcIsNextDayEnd(start, end);
      const duration = isNextDayEnd
        ? end.add(1, "day").diff(start, "minutes")
        : end.diff(start, "minutes");

      if (end > startTime) {
        if (duration >= 240) {
          setValidEndTime({ error: false, message: '' })
        } else {
          setValidEndTime({ error: true, message: 'Minimum duration should be 4 hours' })
        }
      } else {
        setValidEndTime({ error: true, message: 'End time should be grater than start time' })
      }

      dispatch(PostJobActions.setDuration({
        hours: Math.floor(duration / 60),
        minutes: Math.round(duration % 60),
      }));
      dispatch(PostJobActions.setEndTime(end));
    }
  }
  const handleChangeBreakType = (e) => {
    if (getDurationHours() <= 5) {
      dispatch(PostJobActions.setBreakType('unpaid'));
      dispatch(PostJobActions.setBreakDuration(0));
    }
    else {
      dispatch(PostJobActions.setBreakType(e.target.value));
    }
  }
  const handleChangeBreakMins = (e) => {
    if (e.target.value <= 60) {
      dispatch(PostJobActions.setBreakDuration(parseInt(e.target.value)));
    }
  }
  const handleChangeIsRecurring = (e) => {
    dispatch(PostJobActions.setIsRecurring(e.target.value));
    if (e.target.value === "true")
      handleChangeRecurringEndDate(moment(date).add(1, getMinRecurringEndDate(recurringType)));
  }
  const handleChangeRecurringType = (e) => {
    setSelectedRecurringType(e.target.value)
    dispatch(PostJobActions.setRecurringType(e.target.value));
    handleChangeRecurringEndDate(moment(date).add(1, getMinRecurringEndDate(e.target.value)));
  }
  const handleChangeWeekDays = (e) => {
    if (find(weekDays, n => isEqual(n, e))) {
      remove(weekDays, n => isEqual(n, e));
      dispatch(PostJobActions.setWeekDays([...weekDays]));
    } else {
      weekDays.push(e);
      dispatch(PostJobActions.setWeekDays([...weekDays]));
    }
  }
  const handleChangeMonthDate = (e) => {
    if (find(monthDate, n => n === e)) {
      remove(monthDate, n => n === e);
      dispatch(PostJobActions.setMonthDate([...monthDate]));
    } else {
      monthDate.push(e);
      dispatch(PostJobActions.setMonthDate([...monthDate]));
    }
  }

  const handleRepeatEvery = (e) => {
    dispatch(PostJobActions.setRepeatEvery((e > 0 ? e : 1)));
  }

  const handleChangeRecurringEndDate = (e) => {
    dispatch(PostJobActions.setRecurringEndDate(e));
  }

  //Get minimum recurring end date by recurring type
  const getMinRecurringEndDate = (type) => {
    if (!type || type === "Daily") return 'days';
    else if (type === "Weekly") return 'weeks';
    else if (type === "Monthly") return 'months';
  }


  return (
    <>
      <h3 className={classes.pageHeading}>
        Details
        <RadioGroup
          row
          aria-labelledby="row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={isRecurring}
          className={classes.breakRadioGroup}
          onChange={handleChangeIsRecurring}
        >
          <FormControlLabel className={`paidLabel ${isRecurring === 'true' ? 'selected' : ''}`} value={'true'} control={<Radio />} label="RECURRING" />
          <FormControlLabel className={`unpaidLabel ${isRecurring === 'false' ? 'selected' : ''}`} value={'false'} control={<Radio />} label="ONE TIME" />
        </RadioGroup>
      </h3>

      <div className={classes.gearTextBox}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Grid container spacing={2}>
            <Grid item md={12} xs={12} sm={12}>
              <MobileDatePicker
                label={`Job Date (${getTimezoneAbbreviation(timezone)})`}
                inputFormat="DD/MM/YYYY"
                value={date}
                minDate={moment().add(8, 'hours')}
                onChange={handleChangeDate}
                renderInput={(params) => <TextField fullWidth {...params} />}
                fullWidth
                error
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MobileTimePicker
                label={`Start Time (${getTimezoneAbbreviation(timezone)})`}
                value={startTime}
                // maxTime={endTime}
                onChange={(e) => handleChangeStartTime(e, date)}
                renderInput={(params) => <TextField fullWidth {...params} error={validStartTime.error} helperText={validStartTime.message} />}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <MobileTimePicker
                label={`End Time (${getTimezoneAbbreviation(timezone)})`}
                value={endTime}
                onChange={handleChangeEndTime}
                renderInput={(params) => <TextField fullWidth {...params} error={validEndTime.error} helperText={validEndTime.message} />}
              />
            </Grid>
            <Grid item md={12} xs={12} sm={12}>
              <div className={classes.durationInfo}>
                <AccessTime />&nbsp; DURATION IS {duration.hours} HOURS {duration.minutes} MINUTES
              </div>
            </Grid>
            {getDurationHours() > 5 && (
              <Grid item md={6} xs={6} sm={12} style={{ marginTop: "8px" }}>
                <TextField
                  id="breakMin"
                  name="breakMin"
                  label="Break Min"
                  inputProps={{ min: 0 }}
                  value={breakDuration}
                  type="number"
                  onChange={handleChangeBreakMins}
                  fullWidth
                  margin="none"
                />
              </Grid>
            )}
            {getDurationHours() > 5 && (
              <Grid item md={6} xs={6} sm={12} style={{ marginTop: "8px" }}>
                <TextField
                  select
                  fullWidth
                  label="Break Type"
                  value={breakType}
                  onChange={handleChangeBreakType}
                  margin="none"
                >
                  <MenuItem value="unpaid">Unpaid</MenuItem>
                  <MenuItem value="paid">Paid</MenuItem>
                </TextField>
              </Grid>
            )}
          </Grid>
        </LocalizationProvider>
      </div>
      <br />
      {isRecurring === 'true' && <div>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom justifyContent={'space-between'}>
          RECURRING TYPE
        </Typography>
        <RadioGroup
          row
          aria-labelledby="row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={recurringType}
          className={classes.recurringTypeRadioGroup}
          onChange={handleChangeRecurringType}
        >
          <FormControlLabel className={` ${recurringType === 'Daily' ? 'selected' : ''}`} value={'Daily'} control={<Radio />} label="Daily" />
          <FormControlLabel className={` ${recurringType === 'Weekly' ? 'selected' : ''}`} value={'Weekly'} control={<Radio />} label="Weekly" />
          <FormControlLabel className={` ${recurringType === 'Monthly' ? 'selected' : ''}`} value={'Monthly'} control={<Radio />} label="Monthly" />
        </RadioGroup>
        <br />

        <div>
          <div className={classes.repeatEvery}>
            <div className="tite">
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom justifyContent={'space-between'}>
                REPEAT EVERY
              </Typography>
            </div>
            <div className="number">
              <IconButton disabled={repeatEvery <= 1} className="closeButton" color="primary" onClick={() => handleRepeatEvery(repeatEvery - 1)} aria-label="delete" component="span">
                <RemoveCircleOutline className="substract" />
              </IconButton>
              {repeatEvery}
              <IconButton disabled={repeatEvery >= totalRepeatEvery} className="closeButton" color="primary" onClick={() => handleRepeatEvery(repeatEvery + 1)} aria-label="delete" component="span">
                <AddCircle className="add" />
              </IconButton>
            </div>
            <div className="tite">
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom justifyContent={'space-between'}>
                {recurringType === 'Daily' && 'DAY'}
                {recurringType === 'Weekly' && 'WEEK'}
                {recurringType === 'Monthly' && 'MONTH'}
              </Typography>
            </div>
            <div>
              {/* <Select
                labelId="repeat-type-select-label"
                id="repeat-type-select-label"
                value={repeatType}
                // label="Age"
                onChange={handleChangeRepeatType}
              >
                <MenuItem value={'day'}>Day</MenuItem>
                <MenuItem value={'week'}>Week</MenuItem>
                <MenuItem value={'month'}>Month</MenuItem>
              </Select> */}
            </div>
          </div>
          <br />
          {recurringType === 'Weekly' && <div className={classes.weekDays}>
            {weekDaysList.map(n => <div key={n.value} onClick={() => handleChangeWeekDays(n)} className={`${classes.days} ${!selectedWeekDays.includes(n.value) && classes.disabledDay} ${weekDays.indexOf(n) > -1 ? classes.selectedDays : ''}`}>{n.label}</div>)}
          </div>}
          {/* {repeatType === 'week' && <div className={classes.weekDays}>
            {weekDaysList.map(n => <div key={n.value} onClick={() => handleChangeRepeatDays(n)} className={`${classes.days} ${repeatDays.indexOf(n) > -1 ? classes.selectedDays : ''}`}>{n.label}</div>)}
          </div>} */}
          {recurringType === 'Monthly' && <div className={classes.monthDate}>
            {monthDaysList.map(n => <div key={n} onClick={() => handleChangeMonthDate(n)} className={`${classes.date} ${!selectedMonthDays.includes(n) && classes.disabledDay} ${monthDate.indexOf(n) > -1 ? classes.selectedDays : ''}`}>{n}</div>)}
          </div>}
        </div>
        <br />
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom justifyContent={'space-between'}>
          ENDS
        </Typography>
        <div>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MobileDatePicker
              inputFormat="DD/MM/YYYY"
              value={convertUTCToTimezone(recurringEndDate, timezone)}
              minDate={moment(date).add(1, getMinRecurringEndDate(recurringType))}
              onChange={handleChangeRecurringEndDate}
              renderInput={(params) => <TextField fullWidth {...params} />}
              fullWidth
            />
          </LocalizationProvider>
        </div>
      </div>}
      <Button
        className={classes.continueButton}
        size="small"
        disabled={
          !date ||
          (!breakDuration && getDurationHours() > 5) ||
          validStartTime.error ||
          validEndTime.error ||
          (isRecurring === 'true' && (
            (recurringType === 'Weekly' && !weekDays?.length) ||
            (recurringType === 'Monthly' && !monthDate?.length))
          )}
        onClick={handleNext}
        label={'Continue'}
      />
    </>
  );
}

export default DateDetail;
