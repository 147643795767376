import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DialogActions } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import * as yup from 'yup';
import AddressAutocomplete from "components/Autocomplete/AddressAutocomplete";
import Button from "components/CustomButtons/Button";
import Dialog from "components/Dialog/Dialog";
import { selectors as ProfileSelectors } from "store/modules/profile";

const useStyles = makeStyles(() => ({
  avatarImg: {
    margin: '10px auto',
    width: '100px',
    position: 'relative',
    '& img': {
      borderRadius: '100px',
    },
    '& button': {
      position: 'absolute',
      right: '6px',
      background: '#ffffff',
      bottom: '0',
      padding: '2px'
    }
  }
}));
function EditBillingInfoDialog(props) {
  const { open, onClose, onCancel, onConfirm, profileData } = props;
  const { billingFirstName, billingLastName, billingJobRole, billingEmail } = profileData;
  const classes = useStyles();
  const [selectedLocation, setSelectedLocation] = useState('');
  const [location, setLocation] = useState({});
  const [address, setAddress] = useState("");
  const isLoading = useSelector(ProfileSelectors.getIsUpdatingProfileLoading)
  const onAddressChange = (selectedLocation, location) => {
    // setFieldValue()
    setSelectedLocation(selectedLocation)
    setLocation(location);
    // dispatch(EmployerActions.setLocation(selectedLocation, location));
  }
  useEffect(() => {
    const address = profileData.street + ' ' + profileData.city + ' ' + profileData.region + ' ' + profileData.country
    setSelectedLocation({ value: undefined, label: address })
    setAddress(address)
  }, [profileData])


  return (
    <Dialog
      open={open}
      title="Update Billing Information"
      onClose={onClose}
      onCancel={onCancel}
      alertType={'form'}
      hideActions
      size="sm"
      modalType="scrollable"
      disableBackdropClick={false}
    >
      <Grid item xs={12} style={{ width: "100%", paddingLeft: 0, paddingRight: 0 }}>
        <Formik
          initialValues={{
            billingFirstName: profileData.billingFirstName,
            billingLastName: profileData.billingLastName,
            billingJobRole: profileData.billingJobRole,
            billingEmail: profileData.billingEmail,
          }}
          onSubmit={(values, actions) => {
            const request = {
              ...values,
              street: location.street,
              city: location.city,
              country: location.country,
              region: location.region,
              latitude: location.lat,
              longitude: location.lng,
              postalCode: location.postalCode,
            }
            onConfirm(request);
            actions.setSubmitting(false);
          }}
          validationSchema={yup.object().shape({
            billingFirstName: yup.string().required("Please enter first name").max(55, 'First name must be less than 55 characters').nullable(),
            billingLastName: yup.string().required("Please enter last name").max(55, 'Last name must be less than 55 characters').nullable(),
            billingJobRole: yup.string().required("Please enter job role").max(55, 'Job Role must be less than 55 characters').nullable(),
            billingEmail: yup.string().email('Please enter valid email').max(100, 'Email address must be less than 100 characters').required("Please enter email address").nullable(),
          })}>
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
            } = props;

            return (
              <Form>
                <Grid
                  item
                  container
                  direction="row"
                  xs={12}
                >
                  <Grid container item xs={12} md={12} spacing={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="billingFirstName"
                        name="billingFirstName"
                        label="First Name *"
                        className={classes.textField}
                        value={values.billingFirstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={billingFirstName}
                        fullWidth
                        error={errors.billingFirstName && touched.billingFirstName}
                        helperText={errors.billingFirstName && touched.billingFirstName && errors.billingFirstName}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        id="billingLastName"
                        name="billingLastName"
                        label="Last Name *"
                        className={classes.textField}
                        value={values.billingLastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={billingLastName}
                        fullWidth
                        error={errors.billingLastName && touched.billingLastName}
                        helperText={errors.billingLastName && touched.billingLastName && errors.billingLastName}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} >
                    <TextField
                      id="billingJobRole"
                      name="billingJobRole"
                      label="Job Role *"
                      className={classes.textField}
                      value={values.billingJobRole}
                      disabled={billingJobRole}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      error={errors.billingJobRole && touched.billingJobRole}
                      helperText={errors.billingJobRole && touched.billingJobRole && errors.billingJobRole}
                      margin="normal"
                    />
                  </Grid>
                  <Grid container item xs={12} md={12}>
                    <TextField
                      id="billingEmail"
                      name="billingEmail"
                      label="Billing Email Address *"
                      className={classes.textField}
                      value={values.billingEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={billingEmail}
                      fullWidth
                      error={errors.billingEmail && touched.billingEmail}
                      helperText={errors.billingEmail && touched.billingEmail && errors.billingEmail}
                      margin="normal"
                    />
                  </Grid>
                  {address && (
                    <Grid container item xs={12} md={12}>
                      <TextField
                        id="address"
                        name="address"
                        label="Address *"
                        className={classes.textField}
                        value={address}
                        disabled={address}
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  )}
                  {!address && (
                    <Grid container item xs={12} md={12}>
                      <AddressAutocomplete
                        id="address"
                        name="address"
                        label="Address"
                        value={selectedLocation}
                        error={errors.address && touched.address}
                        helperText={errors.address && touched.address && errors.address}
                        margin="normal"
                        onChange={onAddressChange}
                        required
                      />
                    </Grid>
                  )}
                </Grid>
                <DialogActions className={classes.footer}>
                  <Button
                    type="button"
                    className="outline"
                    onClick={onCancel}
                    color="transparent"
                    round
                  >
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    round
                    disabled={!_.isEmpty(errors) || isLoading || !selectedLocation}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Dialog>
  );
}

export default EditBillingInfoDialog;
