import {gql} from "@apollo/client";

export default gql`
query getManager($id: ID!) {
  getManager(id: $id) {
    id
    firstName
    lastName
    defaultEmployerId
    email
    countryCode
    mobilePhone
    signedProfileImageUrl
    employerManager {
      id
      title
      employerId
      employer {
        id
        firstName
        lastName
        companyName
        title
      }
    }
  }
}
`;
