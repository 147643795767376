import { createReducer } from "utils/redux";
import types from "./types";

const initialLoadingState = {
  page: false,
  addManager: false,
};
const initialState = {
  isOpenAddManagerDialog: false,
  list: [],
  allList:[],
  loading: { ...initialLoadingState },
};

const actionsMap = {

  [types.SET_MANAGER_LIST_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      list: data
    };
  },
  [types.SET_ALL_MANAGER_LIST_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      allList: data
    };
  },
  [types.SET_IS_ADD_MANAGER_DIALOG_STATE]: (state, { payload }) => {
    const { value } = payload;
    return {
      ...state,
      isOpenAddManagerDialog: value
    };
  },
  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },
};

export default createReducer(initialState, actionsMap);
