import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent } from "@mui/material";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import { actions as SettingsActions, selectors as SettingsSelectors } from "store/modules/settings";
import Button from "components/Button/Button";

function CompanyJobTemplate({ viewDetail }) {
  const dispatch = useDispatch();
  const companyJobTemplateList = useSelector(SettingsSelectors.getCompanyJobTemplateListData);
  useEffect(() => {
    dispatch(SettingsActions.fetchCompanyJobTemplate());
  }, []);

  const columns = [
    {
      Header: "Name",
      id: "templateName",
      accessor: ({ templateName }) => templateName,
    },
    {
      Header: "Work Type",
      id: "workType",
      accessor: ({ workType }) => workType.label,
    },
    {
      Header: "Address",
      id: "street",
      accessor: ({ street }) => street,
      Cell: ({ row: { original: { street, city, region } } }) => (
        <>
          {(street || "") + ' ' + (city || "") + ' ' + (region || "")}
        </>
      ),
    },
    {
      Header: "",
      id: "action",
      disableFilters: true,
      disableSortBy: true,
      accessor: ({ street }) => street,
      styles: {
        textAlign: 'right'
      },
      Cell: ({ row: { original } }) => (
        <div>
          <Button
            label="View"
            size="small"
            alignIcon="left"
            onClick={() => viewDetail(original)}
          />
        </div>
      ),
    }
  ];

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <ReactTableSimple
            columns={columns}
            data={companyJobTemplateList}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default CompanyJobTemplate;
