import React from "react";
import Avatar from "@mui/material/Avatar";
import DisableCellOverlay from "../DisableCellOverlay";

export default function useAvatar(disabled) {
  return {
    Header: "",
    width: 80,
    sortable: false,
    id: "profileImage",
    Cell: ({ row: { original: { fullName, signedProfileImageUrl, vaccinationStatus } } }) => (
      <>
        <Avatar alt={fullName} src={signedProfileImageUrl} style={vaccinationStatus === 'Approved' ? { border: '3px solid #3e938b' } : {}}/>
        <DisableCellOverlay visible={disabled} />
      </>
    ),
  };
}
