export const getCommunicationState = (part) => (state) => {
  return state.communication[part];
};

export const getAnnouncementsListData = (part) => (state) => {
  return state.communication.announcements[part];
};

export const getArchiveAnnouncementsListData = (part) => (state) => {
  return state.communication.archiveAnnouncements[part];
};

// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.communication.loading[loader] || false;
};

export const getIsPageLoading = getLoadingStateFactory("page");
export const getIsCreateAnnouncementLoading = getLoadingStateFactory("createAnnouncement");

// dialog selectors
export const getDialogStateFactory = key => state => {
  return state.communication.dialog[key] || false;
};

export const getCreateAnnouncementDialog = getDialogStateFactory("newAnnouncement");