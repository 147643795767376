import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    root: {
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "white",
        opacity: "0.7",
        top: 0,
        left: 0,
    },
});

function DisableCellOverlay(props) {
    const classes = useStyles();
    if (!props.visible) {
        return false;
    }
    return <div className={classes.root} />;
}

DisableCellOverlay.propTypes = {
    visible: PropTypes.bool,
};

DisableCellOverlay.defaultProps = {
    visible: false,
};

export default DisableCellOverlay;
