import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, TextField } from "@mui/material";
import * as tzlookup from 'tz-lookup';
import AddressAutocomplete from "components/Autocomplete/AddressAutocomplete";
import Button from "components/Button/Button";
import { actions as PostJobActions, selectors as PostJobSelectors } from "store/modules/postJob";
import useStyles from "./styles";

function Location({ handleNext }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const address = useSelector(PostJobSelectors.getAddress)
  const unit = useSelector(PostJobSelectors.getUnit)
  const floor = useSelector(PostJobSelectors.getFloor)
  const locationInstruction = useSelector(PostJobSelectors.getLocationInstruction)

  const handleChangeUnit = (e) => {
    if (e.target.value.match(/[0-9]/g)) {
      if (e.target.value.length <= 10)
        dispatch(PostJobActions.setUnit(e.target.value));
    }
    else {
      dispatch(PostJobActions.setUnit(''));
    }
  }
  const handleChangeFloor = (e) => {
    if (e.target.value.length <= 10) {
      dispatch(PostJobActions.setFloor(e.target.value));
    }
  }
  const handleChangeInstruction = (e) => {
    if (e.target.value.length <= 100) {
      dispatch(PostJobActions.setLocationInstruction(e.target.value));
    }
  }

  const onAddressChange = (selectedLocation, location) => {
    if (location) {
      const timezone = tzlookup(location.lat, location.lng);
      dispatch(PostJobActions.setTimezone(timezone));
      dispatch(PostJobActions.setStreet(location.street));
      dispatch(PostJobActions.setCity(location.city));
      dispatch(PostJobActions.setRegion(location.region));
      dispatch(PostJobActions.setLatitude(location.lat));
      dispatch(PostJobActions.setLongitude(location.lng));
      dispatch(PostJobActions.setPostalCode(location.postalCode));
      dispatch(PostJobActions.setCountry(location.country));
    }
    dispatch(PostJobActions.setAddress(selectedLocation));
  }

  return (
    <>
      <h3>Job Location</h3>

      <div className={classes.gearTextBox}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} sm={12} lg={12}>
            <AddressAutocomplete
              id="address"
              name="address"
              label="Address"
              value={address}
              margin="none"
              onChange={onAddressChange}
            />
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <TextField
              id="unit"
              name="unit"
              label="Unit #"
              value={unit}
              onChange={handleChangeUnit}
              fullWidth
              inputProps={{ maxLength: 50 }}
              margin="none"
            />
          </Grid>
          <Grid item md={6} xs={12} sm={6}>
            <TextField
              id="floor"
              name="floor"
              label="Floor #"
              value={floor}
              onChange={handleChangeFloor}
              fullWidth
              margin="none"
            />
          </Grid>
          <Grid item md={12} xs={12} sm={12}>
            <TextField
              id="locationInstruction"
              name="locationInstruction"
              label="Additional instructions"
              value={locationInstruction}
              onChange={handleChangeInstruction}
              fullWidth
              margin="none"
            />
          </Grid>
        </Grid>

      </div>
      <Button
        className={classes.continueButton}
        size="small"
        disabled={!address || address.length === 0}
        onClick={handleNext}
        label={'Continue'}
      />
    </>
  );
}

export default Location;
