import React, { useEffect } from 'react';
import { generateZenDeskScript, hideZenDeskHeader, openZenDesk, removeZenDesk } from 'utils/zen-desk';

export default function ChatSupport(props) {
    const params = new URLSearchParams(props.location.search);
    useEffect(() => {
        generateZenDeskScript(loadSuccess, loadError);
        linkStyles("./zenDeskStyle.css");
        // linkStyles("./hideChatButtonStyle.css");

        return removeZenDesk;
    }, []);

    function linkStyles(fileName) {
        const link = document.createElement("link");
        link.href = fileName;
        link.rel = "stylesheet";
        link.type = "text/css";
        document.head.appendChild(link);
    };

    function loadError(onError) {
        console.error(`%cFailed :- ${onError.target.src} didn't load ZenDesk  correctly`);
    };

    function loadSuccess() {
        openZenDesk();
        if (!params.get("isWeb")) hideZenDeskHeader();
    };

    return <div className='only-app-chat-support' />;
}
