import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import iconBill from "assets/img/iconBill.svg";
import iconEdit from "assets/img/iconEdit.svg";
import iconJob from "assets/img/iconJob.svg";
import avatar from "assets/img/profile-placeholder.png";
import MainContent from "components/Containers/MainContent";
import { getMe } from "store/modules/login/selectors";
import { actions as ProfileActions, selectors as ProfileSelectors } from "store/modules/profile";
import EditBillingInfoDialog from "./EditBillingInfoDialog";
import EditCompanyDialog from "./EditCompanyDialog";
import EditProfileDialog from "./EditProfileDialog";
import useStyles from "./styles";

function ProfileDetail() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { accountType } = useSelector(getMe);
  const profileData = useSelector(ProfileSelectors.getProfileData);
  const employerData = useSelector(ProfileSelectors.getEmployerDetail);
  const openProfileDialog = useSelector(ProfileSelectors.getIsProfileDialog);
  const openCompanyDialog = useSelector(ProfileSelectors.getIsCompanyDialog);
  const openBillingInfoDialog = useSelector(ProfileSelectors.getIsBillingInfoDialog);

  const showProfileDialog = () => {
    dispatch(ProfileActions.setIsProfileDialog(true));
  };
  const hideProfileDialog = () => {
    dispatch(ProfileActions.setIsProfileDialog(false));
  };
  const saveProfile = data => {
    const request = {
      firstName: data.firstName,
      lastName: data.lastName,
    };
    dispatch(ProfileActions.updateProfile(request));
  };

  const showCompanyDialog = () => {
    dispatch(ProfileActions.setIsCompanyDialog(true));
  };
  const hideCompanyDialog = () => {
    dispatch(ProfileActions.setIsCompanyDialog(false));
  };
  const saveCompany = data => {
    dispatch(ProfileActions.updateProfile(data));
  };

  const showBillingInfoDialog = () => {
    dispatch(ProfileActions.setIsBillingInfoDialog(true));
  };
  const hideBillingInfoDialog = () => {
    dispatch(ProfileActions.setIsBillingInfoDialog(false));
  };
  const saveBillingInfo = data => {
    dispatch(ProfileActions.updateProfile(data));
  };

  return (
    <>
      <div className={classes.bannerSection} />
      <div className={classes.profilePhoto}>
        <div>
          <img
            src={profileData.signedProfileImageUrl || avatar}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = avatar;
            }}
            className={classes.avatarImg}
            alt="..."
          />
        </div>
        <IconButton className="edit-icon" onClick={showProfileDialog} disableRipple>
          <img src={iconEdit} />
        </IconButton>
      </div>
      <div className={classes.detailSection}>
        <h3>{`${profileData.firstName} ${profileData.lastName}`}</h3>
        {/* <p>{`${profileData.title ? profileData.title : ""}`}</p> */}
        <p>{`${profileData.countryCode} ${profileData.mobilePhone}`}</p>
        <p>{profileData.email}</p>
      </div>
      <MainContent>
        <Grid container display="row" spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Card>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  className={classes.cardHeading}
                  color="text.secondary"
                  gutterBottom
                  justifyContent={"space-between"}
                >
                  <div>About Company</div>
                  {accountType === "employer" && (
                    <IconButton className="edit-icon" onClick={showCompanyDialog} disableRipple>
                      <img src={iconEdit} />
                    </IconButton>
                  )}
                </Typography>
                <CardHeader
                  className={classes.companyName}
                  avatar={
                    <img
                      src={employerData.signedLogoImageUrl || avatar}
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = avatar;
                      }}
                      className={classes.companyLogo}
                      alt="..."
                    />
                  }
                  title={employerData.companyName ? employerData.companyName : ""}
                  subheader={employerData.title}
                />
                <Grid container display="row" className={classes.emailSection}>
                  <Grid item xs={12} sm={12} md={12}>
                    {employerData.workEmail}
                  </Grid>
                </Grid>

                <Grid container display="row" className={classes.secondSection}>
                  <Grid item xs={6} sm={6} md={6} className="leftBlock">
                    <div className="value">
                      <img src={iconJob} />
                      <span>
                        &nbsp;
                        {employerData.jobCount ? employerData.jobCount : "0"}
                      </span>
                    </div>
                    <span>Total Jobs</span>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <div className="value">
                      <span>
                        &nbsp;
                        {employerData.fillRate ? `${employerData.fillRate}%` : "0%"}
                      </span>
                    </div>
                    <span className="label">Fill Rate</span>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Card className={classes.billingCard}>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14 }}
                  className={classes.cardHeading}
                  color="text.secondary"
                  gutterBottom
                  justifyContent={"space-between"}
                >
                  <div>Billing Information</div>
                  {accountType === "employer" && (
                    <IconButton className="edit-icon" onClick={showBillingInfoDialog} disableRipple>
                      <img src={iconEdit} />
                    </IconButton>
                  )}
                </Typography>
                <CardHeader
                  className={classes.billingName}
                  avatar={<img src={iconBill} />}
                  title={
                    (employerData.billingFirstName ? employerData.billingFirstName : "") +
                    " " +
                    (employerData.billingLastName ? employerData.billingLastName : "")
                  }
                  subheader={employerData.billingJobRole}
                />
                <Grid container display="row" className={classes.billingSection}>
                  <Grid item xs={12} sm={12} md={12}>
                    {employerData.billingEmail}
                    <Typography
                      sx={{ fontSize: 14 }}
                      className={classes.address}
                      color="text.secondary"
                    >
                      {employerData.street ? employerData.street + " " : ""}
                      {employerData.city ? employerData.city + " " : ""}
                      {employerData.region ? employerData.region + " " : ""}
                      <br />
                      {employerData.country ? employerData.country + " " : ""}
                      {employerData.postalCode ? employerData.postalCode + " " : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </MainContent>
      <EditProfileDialog
        open={openProfileDialog}
        profileData={profileData}
        onClose={hideProfileDialog}
        onCancel={hideProfileDialog}
        onConfirm={saveProfile}
      />
      <EditCompanyDialog
        open={openCompanyDialog}
        profileData={employerData}
        onClose={hideCompanyDialog}
        onCancel={hideCompanyDialog}
        onConfirm={saveCompany}
      />
      <EditBillingInfoDialog
        open={openBillingInfoDialog}
        profileData={employerData}
        onClose={hideBillingInfoDialog}
        onCancel={hideBillingInfoDialog}
        onConfirm={saveBillingInfo}
      />
    </>
  );
}

export default ProfileDetail;
