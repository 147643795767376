import {gql} from "@apollo/client";

export const uploadManagerProfileImageMutation = gql`
  mutation uploadManagerProfileImage($file: Upload!, $id: String!) {
    uploadManagerProfileImage(file: $file, id: $id) {
      profileImageUrl
    }
  }
`;

export default uploadManagerProfileImageMutation;