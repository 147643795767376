import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import isNil from "lodash/isNil";
import Button from "components/Button/Button";
import Line from "components/Placeholders/Line";
import { actions as JobDetailActions } from "store/modules/jobDetails";
import RatingDialog from "../Dialogs/RatingDialog";
import DisableCellOverlay from "../DisableCellOverlay";

export default function useRating(isRatingAll, disabled) {
  const dispatch = useDispatch();
 
  return {
    Header: "Rating",
    id: "rating",
    canFilter: isRatingAll,
    width: !isRatingAll ? 100 : undefined,
    sortable: !isRatingAll,
    styles: {
      textAlign: 'center'
    },
    Filter: () => {
      const [isRating, setIsRating] = useState(false) 
      const openRatingDialog = () => {
        setIsRating(true)
      }
      const closeRatingDialog = () => {
        setIsRating(false)
      }
      const handleRating = (ratings) => {
        dispatch(JobDetailActions.batchUpdatePositionData("ratings")(ratings));
      }
    return (
        <div style={{ textAlign: 'center' }}>
          <Button label="Rate" size={'small'} onClick={openRatingDialog} />
          <RatingDialog
            open={isRating}
            onClose={closeRatingDialog}
            onCancel={closeRatingDialog}
            onConfirm={handleRating}
            position={{ fullName: 'All'}}
          />
        </div>
    );
  },
    Cell: ({ row: { original } }) => {
      // if (isRatingAll) {
      //   return (
      //     <div>
      //       <Rating value={original.rating} size={20} />
      //     </div>
      //   );
      // }

      return (
        <>
          {isNil(original.rating) ? (
            <Line />
          ) : (
            <Typography variant="body1">{original.rating}</Typography>
          )}
          <DisableCellOverlay visible={disabled} />
        </>
      );
    },
  };
}
