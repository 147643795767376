import * as pagination from "utils/graphql-pagination";
import { paginationSettings } from "./constants";

export const getBannedWorkersPaginationData = state => {
  return state.workers[pagination.getPageInfoAttr(paginationSettings.bannedWorker)];
};
export const getBannedListData = ({ part }) => (state) => {
  return state.workers.bannedWorker[part];
};
export const getPreferredWorkersPaginationData = state => {
  return state.workers[pagination.getPageInfoAttr(paginationSettings.preferredWorker)];
};
export const getPreferredListData = ({ part }) => (state) => {
  return state.workers.preferredWorker[part];
};