import {gql} from "@apollo/client";

export default gql`
  query managerByEmployerId(
    $employerId: ID,
    $isAll: Boolean
  ) {
    managerByEmployerId(
      employerId: $employerId
      isAll: $isAll
    ) {
      id
      isActive
      title
      managerId
      employerId
      manager{
        id
        firstName
        lastName
        email
        countryCode
        mobilePhone
        profileImageUrl
        signedProfileImageUrl
        createdAt
      }
    }
  }
`;
