import React from "react";
import { Chip, DialogActions, Divider, Typography } from "@mui/material";
import Button from "components/CustomButtons/Button";
import iconPinLocation from "assets/img/iconPinLocation.svg";
import useStyles from "./styles";

function ViewTemplateDetail({ detail, onCancel, onConfirm }) {
  const classes = useStyles();

  if (!detail) {
    return "";
  }

  return (
    <div style={{ textAlign: "left" }}>
      <h3>{detail.workType && detail.workType.label}</h3>
      <div className={classes.location}>
        <div className="icon">
          <img src={iconPinLocation} />
        </div>
        <div className="address">
          <div>
            {detail.street && detail.street} {detail.city && detail.city}{" "}
            {detail.region && detail.region}
          </div>
          {detail.unit && (
            <div>
              <b>Unit: </b> {detail.unit}{" "}
            </div>
          )}
          {detail.floor && (
            <div>
              <b>Floor: </b> {detail.floor}{" "}
            </div>
          )}
          {detail.locationInstruction && (
            <div>
              <b>Additional instructions:</b> {detail.locationInstruction}
            </div>
          )}
        </div>
      </div>
      {detail.gears &&
        detail.gears.length > 0 && (
          <React.Fragment>
            <br />
            <Divider />
            <div className={classes.requiredGear}>
              <br />
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                justifyContent={"space-between"}
              >
                REQUIRED GEAR
              </Typography>
              {detail.gears.map((n, index) => (
                <Chip key={index} label={n} variant="outlined" />
              ))}
            </div>
            <br />
          </React.Fragment>
        )}
      {detail.skills &&
        detail.skills.length > 0 && (
          <React.Fragment>
            <Divider />
            <div className={classes.requiredGear}>
              <br />
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                justifyContent={"space-between"}
              >
                SUGGESTED SKILLS {detail.tags && detail.tags.length > 0 && 'AND TAGS'}
              </Typography>
              <ul>
                {detail.skills.map((n, index) => (
                  <li key={index}>{n}</li>
                ))}
              </ul>
              {detail.tags && detail.tags.length > 0 && (
                <div style={{ marginBottom: "20px", paddingLeft: "20px"}}>
                  {detail.tags.map((n, index) => (
                    <Chip key={index} label={n} variant="outlined" />
                  ))}
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      <Divider />
      <div className={classes.requiredGear}>
        <br />
        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
          justifyContent={"space-between"}
        >
          DESCRIPTION
        </Typography>
        <ul>
          <li>{detail.description}</li>
        </ul>
      </div>
      <Divider />
      <DialogActions className={classes.footer}>
        <Button type="button" className="outline" onClick={onCancel} color="transparent" round>
          Close
        </Button>
        <Button variant="contained" color="primary" type="submit" round onClick={onConfirm}>
          Use Template
        </Button>
      </DialogActions>
    </div>
  );
}

export default ViewTemplateDetail;
