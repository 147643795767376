import React from "react";
import ReactTable from "components/ReactTable"
import { sharedColumns } from "../helpers/tableProps";
import { confirmedColoumn } from "../helpers/tableProps";
import { reconfirmedColoumn } from "../helpers/tableProps";

function TableComponent({ pageInfo, jobListData, pageFilter, pageSort, fetchData, extraColumn, history }) {  
  return (
    <React.Fragment>
      <ReactTable
        columns={[
          ...sharedColumns,
          ...(extraColumn ? extraColumn : [] )
        ]}
        data={jobListData}
        fetchData={fetchData}
        defaultFilter={pageFilter}
        defaultSort={pageSort}
        currentPage={pageInfo.currentPage}
        defaultPageSize={pageInfo.pageIndex}
        loading={pageInfo.isLoading}
        pageCount={pageInfo.totalCount}
        onRowClick={({ original }) => {
          history.push(`/jobs/${original.id}`);
        }}
      />
    </React.Fragment>
  )
}
export default TableComponent