// import { emphasize } from "@mui/material/styles/colorManipulator";

export default theme => ({
  input: {
    display: "flex",
    // padding: "6px 8px",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: `${theme.spacing(0.5)} ${theme.spacing(0.25)}`,
  },
  chipFocused: {
    // backgroundColor: emphasize(
    //   theme.palette.type === "light" ? theme.palette.grey[300] : theme.palette.grey[700],
    //   0.08,
    // ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: "absolute",
    left: 10,
    fontSize: 14,
  },
  paper: {
    position: "absolute",
    zIndex: 11,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    bottom: '10px'
  },
});
