import {gql} from "@apollo/client";
import EmployerFields from "../fragments/employer-fragment";
import JobFields from "../fragments/job-fragment";
// import PositionFields from "../fragments/position-fragment";

export default gql`
  query getJob($id: ID!, $timezone: String!) {
    job(id: $id, timezone: $timezone) {
      ...JobFields
      employer {
        ...EmployerFields
      }
      positions {
        id
        isEditable
        isPayable
        isDeletable
        createdAt
        billingRate
        payRate
        createdAtUtc
        employerRating
        workerRating
        startShift
        endShift
        startShiftUtc
        endShiftUtc
        workerId
        defaultWorkerRating
        breakMins
        durationMins
        bonus
        confirmedAt
        confirmedAtUtc
        isReconfirmed
        isConfirmed
        reconfirmedAt
        reconfirmedAtUtc
        checkedInAtUtc
        checkedOutAtUtc
      }
    }
  }
  ${JobFields}
  ${EmployerFields}
`;
// #   ${PositionFields}
