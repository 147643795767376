export const getProfileData = (state) => {
  return state.profile.profileDetail;
};
export const getEmployerDetail = (state) => {
  return state.profile.employerDetail;
};

// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.profile.loading[loader] || false;
};

export const getIsPageLoading = getLoadingStateFactory("page");
export const getIsUpdatingProfileLoading = getLoadingStateFactory("updateProfile");
export const getIsChangeCompanyLoading = getLoadingStateFactory("changeCompany");

// Dialog selectors
export const getDialogStateFactory = loader => state => {
  return state.profile.dialog[loader] || false;
};

export const getIsProfileDialog = getDialogStateFactory("profileDialog");
export const getIsCompanyDialog = getDialogStateFactory("companyDialog");
export const getIsBillingInfoDialog = getDialogStateFactory("billingInfoDialog");