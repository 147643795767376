import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent } from "@mui/material";
import ReactTableSimple from "components/ReactTable/ReactTableSimple";
import { actions as SettingsActions, selectors as SettingsSelectors } from "store/modules/settings";
import ManagerMenu from "./ManagerMenu";

function MyJobTemplate({ history }) {
  const dispatch = useDispatch();
  const myJobTemplateList = useSelector(SettingsSelectors.getMyJobTemplateListData);
  useEffect(() => {
    dispatch(SettingsActions.fetchMyJobTemplate());
  }, []);

  const columns = [
    {
      Header: "Name",
      id: "templateName",
      accessor: ({ templateName }) => templateName,
    },
    {
      Header: "Work Type",
      id: "workType",
      accessor: ({ workType }) => workType.label,
    },
    {
      Header: "Address",
      id: "street",
      accessor: ({ street }) => street,
      Cell: ({ row: { original: { street, city, region } } }) => (
        <>
          {(street || "") + ' ' + (city || "") + ' ' + (region || "")}
        </>
      ),
    },
    {
      Header: "Action",
      id: "id",
      accessor: "id",
      disableFilters: true,
      styles: {
        textAlign: 'right'
      },
      Cell: ({ row: { original } }) => {
        return (
          <>
            <ManagerMenu
              data={original}
              history={history}
            />
          </>
        )
      }
    },
  ];

  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <ReactTableSimple
            columns={columns}
            data={myJobTemplateList}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default MyJobTemplate;
