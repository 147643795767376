/* eslint-disable import-helpers/order-imports */
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MomentUtils from "@date-io/moment";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import Line from "components/Placeholders/Line";
import { convertUTCToTimezone, convertUTCToTimezoneWithOffset, isDateBigger } from "utils/time";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DisableCellOverlay from "../DisableCellOverlay";
import MobileDateTimePicker from "components/DateTimePicker/MobileDateTimePicker";
import { useStyles } from "./styles";

export default function useCheckOut(isCheckingOutAll, disabled, timezone) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const jobEndUtc = useSelector(JobDetailSelectors.getJobEndUtc);
  const [batchCheckoutValue, setBatchCheckoutValue] = useState(convertUTCToTimezone(jobEndUtc, timezone));

  const handleBatchApply = time => {
    setBatchCheckoutValue(time);
    dispatch(JobDetailActions.batchUpdatePositionData("endShiftUtc")(time));
  };

  const renderCell = useCallback((p) => {
    return <RenederCell {...p} extraData={{ isCheckingOutAll, disabled, timezone }} />;
  }, [isCheckingOutAll, disabled, timezone]);


  return {
    Header: "Check out",
    id: "endShift",
    canFilter: isCheckingOutAll,
    sortable: false,
    style: isCheckingOutAll ? { position: "unset" } : {},
    headerStyle: isCheckingOutAll ? { position: "unset" } : {},
    styles: {
      minWidth: '150px'
    },
    Filter: () => {
      const pickerProps = {
        hideTabs: true,
        value: batchCheckoutValue,
        DialogProps: { className: classes.dateTimePicker },
        onChange: date => handleBatchApply(date),
      };
      return (
        <LocalizationProvider dateAdapter={MomentUtils}>
          <MobileDateTimePicker renderInput={(props) => <TextField {...props} variant="standard" />} {...pickerProps} />
        </LocalizationProvider>
      );
    },
    Cell: renderCell,
  };
};


const RenederCell = ({ row: { original }, extraData: { isCheckingOutAll, disabled, timezone }, ...props }) => {
  const positionUpdates = useSelector(JobDetailSelectors.getPositionUpdates);
  const dispatch = useDispatch();
  const classes = useStyles();
  const updatePositionCheckout = JobDetailActions.updatePositionData("endShiftUtc");

  if (isCheckingOutAll && original.isEditable && (!original.endShiftUtc || isDateBigger(original.checkedOutAtUtc))) {
    const { endShiftUtc } = positionUpdates.find(({ id }) => id === original.id);
    const onChange = (date) => {
      dispatch(updatePositionCheckout(original.id, date));
    };
    const pickerProps = {
      hideTabs: true,
      readOnly: !(isCheckingOutAll && original.isEditable),
      disabled: !(isCheckingOutAll && original.isEditable),
      value: convertUTCToTimezone(endShiftUtc, timezone),
      DialogProps: { className: classes.dateTimePicker },
      onChange: date => onChange(date),
    };
    return (
      <LocalizationProvider dateAdapter={MomentUtils}>
        <MobileDateTimePicker renderInput={(props) => <TextField {...props} variant="standard" />} {...pickerProps} />
      </LocalizationProvider>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      {!original.endShift ? (
        <Line />
      ) : (
        <Typography variant="body1">{convertUTCToTimezoneWithOffset(original.endShiftUtc, timezone)}</Typography>
      )}
      <DisableCellOverlay visible={disabled || (isCheckingOutAll && !original.isEditable)} />
    </div>
  );
}
