import {gql} from "@apollo/client";

export default gql`
  query getMe($authId: String!) {
    me(authId: $authId) {
      accountId
      accountType
      employer{
        id
        email
        firstName
        lastName
        signedProfileImageUrl
        signedLogoImageUrl
      }
      manager{
        id
        firstName
        lastName
        email
        signedProfileImageUrl
        defaultEmployer{
          id
          firstName
          lastName
          email
          companyName
        }
      }
    }
  }
`;
