import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles(({ spacing, shape, palette }) => ({
    expandCell: {
        display: 'flex',
        alignItems: "center"
    },
    progressOverlay: {
        background: "#0000001c",
        width: "100%",
        height: "100%",
        top: 0,
        position: "absolute",
        textAlign: "center"
    },
    buttonProgress: {
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    dayChip: {
        marginRight: 5
    },
    formContainer: {
        marginTop: spacing(3),
        maxWidth: 420
    },
    weekDays: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    days: {
        padding: '14px 0',
        border: '1px solid',
        borderRadius: '100px',
        width: '50px',
        height: '50px',
        textAlign: 'center',
        marginRight: '10px',
        fontWeight: '400',
        borderColor: '#b2b2c4',
        color: '#848499',
        cursor: 'pointer',
        marginBottom: '10px'
    },
    selectedDays: {
        borderColor: '#00c8a9 !important',
        color: '#00c8a9 !important',
        background: '#FFF',
        fontWeight: '400'
    },
    repeatEvery: {
        display: 'flex',
        justifyContent: 'start',
        fontSize: '25px',
        '& .tite p': {
            lineHeight: '3.8',
            margin: '0'
        },
        '& .number': {
            lineHeight: '1.9'
        },
        '& .add': {
            color: '#57c4a6',
            width: '1.5em',
            height: '1.5em'
        },
        '& .substract': {
            width: '1.5em',
            height: '1.5em'
        }
    },
    disabledDay: {
        cursor: "not-allowed",
        pointerEvents: "none",
        touchAction: "none",
        color: "#84849985"
    },
    location: {
        color: "#6e7a8a",
        display: "flex",
        fontSize: 12,
        "& .icon": {
            width: "30px",
            "& img": {
                height: 16,
            }
        },
    },
    address: {
        wordBreak: "break-word"
    },
    JobTitleContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center',
        marginBottom: 5
    },
    jobInfo: {
        marginTop: 10
    },
    workersContainer: {
        display: "flex",
        flexWrap: "wrap"
    },
    workerChip:{
        marginRight:5,
        marginTop:5,
    }
}));