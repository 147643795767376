import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import { tabStyles } from "../../styles";
import TabDetail from "../TabDetail";
import CompletedPage from "./CompletedPage";
import FilledPage from "./FilledPage";
import InProgressPage from "./InProgressPage";
import TotalPage from "./TotalPage";
import UnFilledPage from "./UnFilledPage";

function TodayTab(props) {
  const classes = tabStyles();
  const dispatch = useDispatch();
  const tabClass = {
    root: classes.tabRoot,
    selected: classes.tabSelected,
  };
  const todaysState = useSelector(JobSelectors.getListStats("today"))

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (props.history.location.search) {
      const params = new URLSearchParams(props.history.location.search);
      params.set("timeframe", 0);
      params.set("listType", newValue);
      props.history.push(`/job-overview?${params.toString()}`);
    }
    setValue(newValue);
  };

  useEffect(() => {
    if (props.history.location.search) {
      const params = new URLSearchParams(props.history.location.search);
      const timeframe = parseInt(params.get("timeframe"), 10);
      const listType = parseInt(params.get("listType"), 10);
      if (!isNaN(listType) && timeframe === 0) {
        setValue(listType);
        handleChange('', listType);
      }
    } else {
      handleChange('', value);
    }
    dispatch(JobActions.fetchListStats("Today"));

  }, [])

  return (
    <React.Fragment>
      <div style={{ display: "flex", marginBottom: '15px' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          classes={{ indicator: classes.tabIndicator }}
          variant="scrollable"
        >
          <Tab
            label={
              <TabDetail
                title="Total"
                headerClass={classes.tabHeader}
                labelClass={classes.tabLabel}
                {...todaysState.total}
              />
            }
            classes={tabClass}
          />
          <Tab
            label={
              <TabDetail
                title="In Progress"
                headerClass={classes.tabHeader}
                labelClass={classes.tabLabel}
                {...todaysState.active}
              />
            }
            classes={tabClass}
          />
          <Tab
            label={
              <TabDetail
                title="Unfilled"
                headerClass={classes.tabHeader}
                labelClass={classes.tabLabel}
                {...todaysState.unfilled}
              />
            }
            classes={tabClass}
          />
          <Tab
            label={
              <TabDetail
                title="Filled"
                headerClass={classes.tabHeader}
                labelClass={classes.tabLabel}
                {...todaysState.filled}
              />
            }
            classes={tabClass}
          />
          <Tab
            label={
              <TabDetail
                title="Completed"
                headerClass={classes.tabHeader}
                labelClass={classes.tabLabel}
                {...todaysState.completed}
              />
            }
            classes={tabClass}
          />
        </Tabs>
      </div>
      <div className={classes.contentContainer}>
        <div>
          {value === 0 && <TotalPage history={props.history} />}
        </div>
        <div>
          {value === 1 && <InProgressPage history={props.history} />}
        </div>
        <div>
          {value === 2 && <UnFilledPage history={props.history} />}
        </div>
        <div>
          {value === 3 && <FilledPage history={props.history} />}
        </div>
        <div>
          {value === 4 && <CompletedPage history={props.history} />}
        </div>
      </div>
    </React.Fragment>
  )
}
export default TodayTab