import {gql} from "@apollo/client";

export default gql`
  query employers(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $order: [OrderInput!]
    $like: [FilterInput!]
    $canPost: Boolean
  ) {
    employers(
      first: $first
      last: $last
      after: $after
      before: $before
      order: $order
      like: $like
      canPost: $canPost
    ) {
      edges {
        node {
          id
          companyName
          street
          city
          region
          country
          firstName
          lastName
          email
          logoImageUrl
          signedLogoImageUrl
          mobilePhone
          billingRate
          payRate
        }
      }
    }
  }
`;
