import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import { withStyles } from '@mui/styles';
const isMobile = false;
const styles = ({ palette }) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: isMobile ? "15px" : "15px 32px",
  },
  actionLoadingContainer: {
    padding: "11px 32px 15px 32px",
  },
  progress: {
    color: palette.secondary.light,
    position: "absolute",
    top: "50%",
    left: "45%",
  },
  noTopPadding: {
    paddingTop: 4,
  },
  actionLoadingNoTopPadding: {
    paddingTop: 0,
  },
});

function MainContent({
  className,
  style,
  classes,
  children,
  isLoading,
  disabled,
  isApplyingAction,
  noTopPadding,
}) {
  const props = {
    className: classNames({
      [classes.container]: !isApplyingAction,
      [classes.actionLoadingContainer]: isApplyingAction,
      [classes.noTopPadding]: !isApplyingAction && noTopPadding,
      [classes.actionLoadingNoTopPadding]: isApplyingAction && noTopPadding,
      [className]: !!className,
    }),
  };

  if (style) {
    props.style = style;
  }

  let containerStyle = {};
  if (!isLoading) {
    containerStyle = { position: "relative", width: "100%", height: "100%" };
  }

  return (
    <div style={containerStyle}>
      {isApplyingAction && <LinearProgress color="secondary" />}
      {isLoading ? (
        <CircularProgress classes={{ root: classes.progress }} />
      ) : (
        <div {...props}>{children}</div>
      )}
      {disabled && (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#FFF",
            width: "100%",
            height: "100%",
            opacity: 0.5,
            top: 0,
            left: 0,
            zIndex: 1000,
          }}
        />
      )}
    </div>
  );
}

MainContent.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  isApplyingAction: PropTypes.bool,
  noTopPadding: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

MainContent.defaultProps = {
  style: null,
  className: null,
  isLoading: false,
  isApplyingAction: false,
  noTopPadding: false,
  disabled: false,
};

export default withStyles(styles)(MainContent);
