export default ({ spacing, shape, palette }) => ({
  pageContainer: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    // height: "100vh",
    minHeight: "100%",
  },
  loginContainer: {
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: palette.grey[900],
    height: "100vh",
    width: "100%",
  },
  loginItemContainer: {
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  buttonContainer: {
    alignContent: "flex-end",
    alignItems: "center",
    display: "flex",
    // justifyContent: "space-between",
    width: "100%",
    marginTop: '20px'
  },
  nextButton: {
    paddingLeft: 0,
    paddingRight: '16px'
  },
  resendSection: {
    color: '#FFF',
    '& .minutes':{
      color: '#00c8a9'
    },
    '& .link': {
      cursor: 'pointer'
    },
    '& .resend-disable': {
      color: '#9a9fb1'
    }
  },
  logo: {
    padding: `${spacing(5)} 0`,
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    justifyContent: "left",
    width: "100%",
    '& img': {
      width: "225px"
    }
  },
  labelStyle: {
    color: palette.grey[600],
  },
  inputStyle: {
    border: `1px solid ${palette.grey[800]}`,
    borderRadius: shape.borderRadius,
    backgroundColor: `${palette.grey[800]} !important`,
    outline: 0,
    "& input": {
      border: "none",
      color: palette.common.white,
      backgroundColor: `${palette.grey[800]} !important`,
      outline: 0,
    },
    "& input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus": {
      color: `${palette.common.white} !important`,
      "-webkit-text-fill-color": palette.common.white,
      boxShadow: `0 0 0px 1000px ${palette.grey[800]} inset`,
      "-webkit-box-shadow": `0 0 0px 1000px ${palette.grey[800]} inset`,
      borderColor: "none !important",
      outline: 0,
      backgroundColor: `${palette.grey[800]} !important`,
    },
  },
  emailStyle: {
    color: "#fff !important",
  },

  linkStyle: {
    float: "right",
    paddingRight: 0,
    color: palette.secondary.light,
  },

  imageContainer: {
    alignContent: "center",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    backgroundColor: palette.background.default,
  },
  footerSection: {
    color: '#FFF',
    marginTop: '48px'
  },
  footerTitle: {
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '16px',
    lineHeight: '20px'
  },
  footerSubtitle: {
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#E1E3E6',
    marginTop: '4px'
  },
  GoogleLink: {
    marginTop: '24px',
    marginRight: '18px'
  },
  AppleLink: {
    marginTop: '24px',
  }
});
