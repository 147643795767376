import { createNetworkTypes } from "utils/redux";

export const networkTypes = {
  FETCH_TIMEFRAME_STATS: "FETCH_TIMEFRAME_STATS",
  FETCH_LIST_STATS: "FETCH_LIST_STATS",
};

export default {
  ...createNetworkTypes(networkTypes.FETCH_TIMEFRAME_STATS, "jobs"),
  ...createNetworkTypes(networkTypes.FETCH_LIST_STATS, "jobs"),
  SET_LIST_DATA: "jobs/SET_LIST_DATA",
};
