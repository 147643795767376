import { createReducer } from "utils/redux";
import types from "./types";

const initialLoadingState = {
  page: false,
  updateTimezone: false,
  updateTemplate: false
};
const initialState = {
  timezoneList: [],
  myJobTemplateList: [],
  companyJobTemplateList: [],
  templateDetail: {},
  loading: { ...initialLoadingState },
};

const actionsMap = {

  [types.SET_TIMEZONE_LIST_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      timezoneList: data
    };
  },
  [types.SET_MY_JOB_TEMPLATE_LIST]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      myJobTemplateList: data
    };
  },
  [types.SET_COMPANY_JOB_TEMPLATE_LIST]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      companyJobTemplateList: data
    };
  },
  [types.SET_TEMPLATE_DETAIL]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      templateDetail: data
    };
  },
  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },
  [types.SET_DIALOG_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      dialog: {
        ...state.dialog,
        [key]: value,
      },
    };
  },
};

export default createReducer(initialState, actionsMap);
