import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Edit, MoreVert, RemoveCircleSharp, ToggleOff, ToggleOn } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import Dialog from "components/Dialog/Dialog";
import { actions as ManagerActions } from "store/modules/managers";
import DisableDialog from "./DisableDialog";
import EditManagerDialog from "./EditManagerDialog";

const useStyles = makeStyles(({ spacing, typography }) => ({
  icon: {
    fontSize: typography.pxToRem(20),
    marginRight: spacing(2),
  },
}));

function ManagerMenu({ data }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showRemoveManagerDialog, setShowRemoveManagerDialog] = useState(false)
  const [showDisableManagerDialog, setShowDisableManagerDialog] = useState(false)
  const [isOpenEditManagerDialog, setIsOpenEditManagerDialog] = useState(false)

  const menuId = `action-menu-${data.id}`;
  const openMenu = e => {
    setAnchorEl(e.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const hideRemoveManager = () => setShowRemoveManagerDialog(false);

  const showRemoveDialog = () => {
    closeMenu();
    setShowRemoveManagerDialog(true)
  }

  const handleRemoveManager = () => {
    dispatch(ManagerActions.removeManager(data.manager.id))
    setShowRemoveManagerDialog(false)
  }

  const enableManager = () => {
    dispatch(ManagerActions.enableManager(data.manager.id))
  }

  const showDisableDialog = () => {
    closeMenu();
    setShowDisableManagerDialog(true)
  }
  const hideDisableDialog = () => {
    setShowDisableManagerDialog(false)
  }
  const handleDisableManager = (reason) => {
    dispatch(ManagerActions.disableManager(data.manager.id,reason))
  }

  const showEditDialog = () => {
    closeMenu();
    setIsOpenEditManagerDialog(true)
  }
  const hideEditDialog = () => {
    setIsOpenEditManagerDialog(false)
  }
  const handleEditManager = (values) => {
    dispatch(ManagerActions.editManager(values))
  }

  return (
    <>
      <IconButton aria-controls={menuId} aria-haspopup="true" onClick={openMenu} disableRipple>
        <MoreVert color="primary" />
      </IconButton>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}>
          <MenuItem onClick={showEditDialog}>
            <Edit className={classes.icon} color="action" />
            Edit
          </MenuItem>
        {!data.isActive && (
          <MenuItem onClick={enableManager}>
            <ToggleOn className={classes.icon} color="action" />
            Enable
          </MenuItem>
        )}
        {data.isActive && (
          <MenuItem onClick={showDisableDialog}>
            <ToggleOff className={classes.icon} color="action" />
            Disable
          </MenuItem>
        )}
        <MenuItem onClick={showRemoveDialog}>
          <RemoveCircleSharp className={classes.icon} color="action" />
          Delete
        </MenuItem>
      </Menu>
      <Dialog
        open={showRemoveManagerDialog}
        title="Are you sure?"
        onClose={hideRemoveManager}
        onConfirm={handleRemoveManager}
        onCancel={hideRemoveManager}
        alertType="warning"
        icon="remove_circle"
      />
      <DisableDialog 
        open={showDisableManagerDialog}
        title="Are you sure?"
        onClose={hideDisableDialog}
        onConfirm={handleDisableManager}
        onCancel={hideDisableDialog}
        fullName={data.manager.firstName + ' ' +data.manager.lastName}
        alertType="warning"
        icon="remove_circle"
      />
      <EditManagerDialog
        open={isOpenEditManagerDialog}
        onClose={hideEditDialog}
        onCancel={hideEditDialog}
        onConfirm={handleEditManager}
        data={data}
      />
    </>
  );
}

export default ManagerMenu;
