import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RemoveCircleSharp } from "@mui/icons-material";
import { Avatar, IconButton } from "@mui/material";
import MainContent from "components/Containers/MainContent";
import Dialog from "components/Dialog/Dialog";
import ReactTable from "components/ReactTable"
import { actions as WorkersActions, selectors as WorkersSelectors } from "store/modules/workers";

function BanWorkers() {
  const dispatch = useDispatch();
  const [showRemoveBanDialog, setShowRemoveBanDialog] = useState(false)
  const [workerId, setWorkerId] = useState()
  const pageInfo = useSelector(WorkersSelectors.getBannedWorkersPaginationData)
  const bannWorkerData = useSelector(WorkersSelectors.getBannedListData({ part: "data" }))
  const pageFilter = useSelector(WorkersSelectors.getBannedListData({ part: "filter" }))
  const pageSort = useSelector(WorkersSelectors.getBannedListData({ part: "sort" }))
  const fetchData = useCallback(({ pageIndex, filters, sortBy }) => {
    dispatch(WorkersActions.changeBannedWorkersFilter(filters));
    dispatch(WorkersActions.changeBannedWorkersSort(sortBy));
    dispatch(WorkersActions.fetchBannedWorkers(pageIndex));
  }, []);

  const columns = [
    {
      Header: "",
      id: "signedProfileImageUrl",
      disableFilters: true,
      disableSortBy: true,
      accessor: ({ signedProfileImageUrl }) => {
        return signedProfileImageUrl;
      },
      Cell: ({ row: { original: { firstName, signedProfileImageUrl } } }) => (
        <>
          <Avatar alt={firstName} src={signedProfileImageUrl} />
        </>
      ),
    },
    {
      Header: "First Name",
      id: "firstName",
      isSorted: true,
      accessor: ({ firstName }) => {
        if (!firstName) {
          return <i>Missing first name</i>;
        }

        return firstName;
      },
    },
    {
      Header: "Last Name",
      id: "lastName",
      accessor: ({ lastName }) => {
        if (!lastName) {
          return <i>Missing last name</i>;
        }

        return lastName;
      },
    },
    {
      Header: "Location",
      id: "city",
      accessor: ({ city, region }) => {
        if (!city || !region) {
          return <i>Missing location</i>;
        }
        return `${city}, ${region}`;
      },
    },
    {
      Header: "Rating",
      id: "rating",
      accessor: ({ rating }) => {
        if (!rating) {
          return <i>Not rated.</i>;
        }
        return rating;
      },
      disableFilters: true,
    },
    {
      Header: "",
      id: "id",
      accessor: "id",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row: { original } }) => {
        return (
          <>
            <IconButton onClick={() => showRemoveDialog(original.id)}>
              <RemoveCircleSharp color="error" />
            </IconButton>
          </>
        )
      }
    },
  ];

  const hideRemoveBan = () => setShowRemoveBanDialog(false);

  const showRemoveDialog = (id) => {
    setWorkerId(id)
    setShowRemoveBanDialog(true)
  }

  const handleRemoveBan = () => {
    dispatch(WorkersActions.removeBanWorker(workerId, 'ban'))
    setShowRemoveBanDialog(false)
  }

  return (
    <>
      <MainContent>
        <ReactTable
          columns={columns}
          data={bannWorkerData}
          fetchData={fetchData}
          defaultFilter={pageFilter}
          defaultSort={pageSort}
          currentPage={pageInfo.currentPage}
          defaultPageSize={pageInfo.pageIndex}
          loading={pageInfo.isLoading}
          pageCount={pageInfo.totalCount}
        />
        <Dialog
          open={showRemoveBanDialog}
          title="Are you sure?"
          onClose={hideRemoveBan}
          onConfirm={handleRemoveBan}
          onCancel={hideRemoveBan}
          alertType="warning"
          icon="remove_circle"
        />
      </MainContent>
    </>
  );
}

export default BanWorkers;
