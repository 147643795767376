import {gql} from "@apollo/client";

export const updateNotificationMutation = gql`
    mutation setJobReminder($data: EmployerInput!) {
        setJobReminder(data: $data) {
            id
        }
    }
`;

export default updateNotificationMutation;