import {gql} from "@apollo/client";

export default gql`
  fragment PositionFields on Position {
    id
    isPayable
    isEditable
    isDeletable
    createdAt
    createdAtUtc
    employerRating
    workerRating
    startShift
    endShift
    startShiftUtc
    endShiftUtc
    workerId
    defaultWorkerRating
    breakMins
    durationMins
    isConfirmed
    confirmedAt
    confirmedAtUtc
    isReconfirmed
    reconfirmedAt
    reconfirmedAtUtc
    checkedInAtUtc
    checkedOutAtUtc
  }
`;
