import { createReducer } from "utils/redux";
import types from "./types";

const initialLoadingState = {
  page: false,
  updateProfile: false,
  changeCompany:false
};
const initialDialogState = {
  profileDialog: false,
  companyDialog: false,
};
const initialState = {
  profileDetail: {},
  employerDetail: {},
  loading: { ...initialLoadingState },
  dialog: { ...initialDialogState },
};

const actionsMap = {
  [types.SET_EMPLOYER_PROFILE_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      profileDetail: data,
    };
  },
  [types.SET_EMPLOYER_DETAIL_DATA]: (state, { payload }) => {
    const { data } = payload;
    return {
      ...state,
      employerDetail: data,
    };
  },
  [types.SET_LOADING_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      loading: {
        ...state.loading,
        [key]: value,
      },
    };
  },
  [types.SET_DIALOG_STATE]: (state, { payload }) => {
    const { key, value } = payload;
    return {
      ...state,
      dialog: {
        ...state.dialog,
        [key]: value,
      },
    };
  },
};

export default createReducer(initialState, actionsMap);
