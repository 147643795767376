import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import { tabStyles } from "../../styles";
import TableComponent from "../TableComponent";

export default function HistoryTab(props) {
    const classes = tabStyles();
    const dispatch = useDispatch();
    const pageInfo = useSelector(JobSelectors.getHistoryPaginationData)
    const jobListData = useSelector(JobSelectors.getListData({ timeframe: 'history', part: "data" }))
    const pageFilter = useSelector(JobSelectors.getListData({ timeframe: 'history', part: "filter" }))
    const pageSort = useSelector(JobSelectors.getListData({ timeframe: 'history', part: "sort" }))
    const fetchData = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
        dispatch(JobActions.setHistoryPageSize(pageSize));
        dispatch(JobActions.setHistoryFilter(filters));
        dispatch(JobActions.setHistorySort(sortBy));
        dispatch(JobActions.fetchHistoryJobs(pageIndex));
    }, []);

    useEffect(() => {
        dispatch(JobActions.fetchListStats("Past"));
    }, [])

    return (
        <React.Fragment>
            <div className={classes.contentContainer}>
                <TableComponent
                    pageInfo={pageInfo}
                    jobListData={jobListData}
                    pageFilter={pageFilter}
                    pageSort={pageSort}
                    fetchData={fetchData}
                    history={props.history}
                />
            </div>
        </React.Fragment>

    )
}
