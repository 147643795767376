import React from "react";
import { matchPath } from "react-router";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import cx from "classnames";
// @mui/material components
import { ChevronLeft } from "@mui/icons-material";
import Menu from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Hidden from "@mui/material/Hidden";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { withStyles } from '@mui/styles';
// material-ui icons
import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";
import CustomButton from "components/CustomButtons/Button.jsx";

function getRoute(routes = [], pathname) {
  return routes.reduce(
    (acc, route) => {
      const { collapse, views, path } = route;

      const match = matchPath(pathname, {
        path: path,
        exact: true,
        strict: true,
      });

      if (collapse) {
        const sub = views.reduce((viewAcc, subRoute) => {
          return subRoute.path === pathname ? subRoute : viewAcc;
        }, null);
        return sub || acc;
      }

      return match && match.isExact && acc.name === "Default" ? route : acc;
    },
    { name: "Default" }
  );
}

function Header(props) {
  const { classes, color, routes, location } = props;

  const appBarClasses = cx({
    [" " + classes[color]]: color,
  });
  const route = getRoute(routes, location.pathname);
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        {route.backButton ? (
          <IconButton
            className={classes.backButton}
            onClick={() => props.history.goBack()}
          >
            <ChevronLeft />
          </IconButton>
        ) : null}
        <div className={classes.flex}>
          <Typography variant="h5">{route.name}</Typography>
        </div>

        <Hidden mdUp implementation="css">
          <CustomButton
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}>
            <Menu />
          </CustomButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  handleDrawerToggle: PropTypes.func,
};

Header.defaultProps = {
  handleDrawerToggle: () => {},
};

export default withStyles(theme => ({
  ...headerStyle(theme),
  backButton: {
    marginRight: "24px",
      padding: 0
  },
}))(withRouter(Header));
