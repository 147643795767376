import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actions as ProfileActions } from "store/modules/profile";
import ProfileDetail from "./components/ProfileDetail";
function Profile() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(ProfileActions.fetchEmployerDetail());
    dispatch(ProfileActions.fetchProfile());
  }, []);
  return (
    <>
      <ProfileDetail />
    </>
  );
}

export default Profile;
