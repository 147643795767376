import React, { useState } from "react";
import { MoreVert, ContentCopy } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(({ spacing, typography }) => ({
    icon: {
        fontSize: typography.pxToRem(20),
        marginRight: spacing(2),
    },
}));

function CompanyTemplateMenu({ data, history }) {
    
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const id = data.id;
    const menuId = `action-menu-${data.id}`;
    const openMenu = e => {
        setAnchorEl(e.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };

    const showDuplicateTemplate = () => {
        closeMenu();
        history.push(`/duplicate-template?id=${id}&type=duplicate`);
    }

    return (
        <>
            <IconButton aria-controls={menuId} aria-haspopup="true" onClick={openMenu} disableRipple>
                <MoreVert color="primary" />
            </IconButton>
            <Menu
                id={menuId}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={closeMenu}>
                <MenuItem onClick={showDuplicateTemplate}>
                    <ContentCopy className={classes.icon} color="action" />
                    Duplicate
                </MenuItem>
            </Menu>
        </>
    )
}

export default CompanyTemplateMenu