import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';

const styles = ({ spacing }) => ({
  header: {},
  label: {
    lineHeight: 1.5,
    marginRight: `${spacing(2)}`,
    "& + span": {
      fontWeight: 500,
    },
  },
});

function CategoryTab({
  classes,
  className,
  title,
  count,
  headerClass,
}) {
  return (
    <Grid className={className} container direction="column" alignItems="flex-start">
      <Typography
        className={classNames(classes.header, headerClass)}
        variant="overline"
        color="textSecondary">
        {title}
      </Typography>
      <Typography variant="h4">{count}</Typography>
      {/* <React.Fragment>
        <Grid container item justifyContent="space-between" alignItems="center">
          <Typography
            className={classNames(classes.label, labelClass)}
            variant="overline"
            color="textSecondary">
            Hours
          </Typography>
          <Typography variant="caption">{hours}</Typography>
        </Grid>
      </React.Fragment> */}
    </Grid>
  );
}

CategoryTab.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  headerClass: PropTypes.string,
  labelClass: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  bookings: PropTypes.number,
  revenue: PropTypes.number,
  hours: PropTypes.number,
};

CategoryTab.defaultProps = {
  className: null,
  headerClass: null,
  labelClass: null,
  title: "",
  count: 0,
  bookings: 0,
  revenue: 0,
  hours: 0,
};

export default withStyles(styles)(CategoryTab);
