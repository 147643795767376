import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  TextField,
  Typography,
  IconButton
} from "@mui/material";
import iconDateTime from "assets/img/iconDateTime.svg";
import iconFloppyDisk from "assets/img/iconFloppyDisk.svg";
import iconPinLocation from "assets/img/iconPinLocation.svg";
import { isEmpty } from "lodash";
import moment from "moment";
import Button from "components/Button/Button";
import { InfoRounded } from "@mui/icons-material";
import { formatMoney } from "utils/stringUtils";
import { convertUTCToTimezoneWithOffset, formattedTimeWithOffset, SplitTime } from "utils/time";
import { actions as PostJobActions, selectors as PostJobSelectors } from "store/modules/postJob";
import useStyles from "./styles";
import PostJobDialog from "./PostJobDialog";
import Dialog from "components/Dialog/Dialog";
import FeesDialog from "./FeesDialog";
import Tooltip from "@mui/material/Tooltip";
import { overTimeText, doubleTimeText } from "helpers/constants"

function Review({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isTemplateSaved, setIsTemplateSaved] = useState(false);
  const canTemplateSelected = useSelector(PostJobSelectors.getCanTemplateSelected);
  const address = useSelector(PostJobSelectors.getAddress);
  const calculatedBill = useSelector(PostJobSelectors.getCalculatedJobBill);
  const {
    workType,
    requiredGear,
    tags,
    skills,
    description,
    date,
    startTime,
    endTime,
    noOfWorkers,
    breakType,
    siteManager,
    checkInManager,
    checkOutManager,
    announcement,
    isRecurring,
    recurringType,
    repeatEvery,
    weekDays,
    monthDate,
    recurringEndDate,
    duration,
    breakDuration,
    timezone,
    templateName,
    locationInstruction,
    unit,
    floor,
  } = useSelector(PostJobSelectors.getPostJobFormData);
  const [feesDialog, setFeesDialog] = useState(false);
  const isPostJobDialog = useSelector(PostJobSelectors.getIsPostJobDialog);
  const isPostJobLoading = useSelector(PostJobSelectors.getIsPostJobLoading);
  const RegularPayHours = (SplitTime(calculatedBill.regularPayHours));
  const OvertimePayHours = (SplitTime(calculatedBill.overtimePayHours));
  const DoublePayHours = (SplitTime(calculatedBill.doublePayHours));
  const hours = `${duration.hours}`;
  const minutes = `${duration.minutes}`;
  const selectedManagers = !isEmpty(checkInManager) || !isEmpty(siteManager) || !isEmpty(checkOutManager);
  const [openConfirmJob, setOpenConfirmJob] = useState(false);

  useEffect(() => {
    dispatch(
      PostJobActions.calculateEmployerBill({
        breakMins: breakDuration,
        peopleNeeded: Number(noOfWorkers),
        jobStartTime: startTime,
        jobEndTime: endTime,
        isBreakPaid: breakType === "paid"
      }),
    );
  }, []);

  const hoursCounting = () => {
    let doubleTimePay;
    let overTimePay;
    if (hours >= 10 && hours <= 12) {
      overTimePay = `${hours - 8}`
      return (
        <div>
          <b>8 hours</b> : normal pay <br />
          <b>{overTimePay} hours {minutes} minutes</b>: overtime pay <br />
        </div>
      )
    }
    else if (hours > 12) {
      doubleTimePay = `${hours - 12}`
      overTimePay = `${hours - 8 - doubleTimePay}`
      return (
        <div>
          <b>8 hours</b> : normal pay <br />
          <b>{overTimePay} hours</b>: overtime pay <br />
          <b>{doubleTimePay} hours {minutes} minutes</b> : doubletime pay
        </div>
      )
    }
  };

  let countedHours = hoursCounting();

  // To open the model box
  const handleClickOpen = () => {
    if (hours >= 10 && hours <= 12) {
      setOpenConfirmJob(true);
    }
    else if (hours > 12) {
      setOpenConfirmJob(true);
    }
    else {
      dispatch(PostJobActions.postJob())
    }
  }

  const handleClose = () => {
    setOpenConfirmJob(false);
  };

  const confirmJob = () => {
    setOpenConfirmJob(false);
    dispatch(PostJobActions.postJob())
  };

  const postJobConfirm = () => {
    dispatch(PostJobActions.setIsPostJobDialog(false));
    history.push(`/jobs`);
  }

  const saveTemplate = () => {
    dispatch(PostJobActions.saveTemplate()).then((data) => {
      if (data) {
        setIsTemplateSaved(true)
      }
    });
  };
  const handleChangeTemplateName = e => {
    const name = e.target.value.length === 1 ? e.target.value.trim() : e.target.value
    if (!name.replace(/^[0-9a-zA-Z- ]+$/, '')) {
      dispatch(PostJobActions.setTemplateName(name));
    }
  };

  const isBreakRequired = () => {
    if (duration && duration.hours) {
      const hours = (duration.hours * 60) + duration.minutes;
      const durationHr = Math.round((hours / 60) * 100) / 100
      if (durationHr > 5) {
        return true;
      }
    }
    return false;
  }

  const handleClickOpenFeesDialog = () => {
    setFeesDialog(true)
  }

  const handleClickCloseFeesDialog = () => {
    setFeesDialog(false)
  }

  return (
    <>
      <h3>Review and post job</h3>
      <Divider />
      <div className={classes.workType}>{workType.label}</div>

      <div className={classes.location}>
        <div className="icon">
          <img src={iconPinLocation} />
        </div>
        <div className="address">
          <div>{address && address.label}</div>
          <div>
            {unit && (
              <span>
                <b>Unit: </b> {unit}{" "}
              </span>
            )}
            {floor && (
              <span>
                <b>Floor: </b> {floor}{" "}
              </span>
            )}
          </div>
          {locationInstruction && (
            <div>
              <b>Additional instructions:</b> {locationInstruction}
            </div>
          )}
        </div>
      </div>
      <div className={classes.dateTime}>
        <div className="icon">
          <img src={iconDateTime} />
        </div>
        <div className="detail">
          <div className="date">
            {convertUTCToTimezoneWithOffset(date, timezone, "dddd, MMMM Do, YYYY")}
          </div>
          <div className="time">{formattedTimeWithOffset(startTime, endTime, timezone)}</div>
          <div className="duration">
            ({duration.hours} hrs, {duration.minutes} mins){" "}
          </div>
          <div className="button">
            {isBreakRequired() && (
              <div className="break">
                {" "}
                {hours < 5 ?
                  breakType === "unpaid" ? "Unpaid Break" : '' :
                  breakType === "paid" ? `Paid Break(${breakDuration} mins)` : `Unpaid Break(${breakDuration} mins)`
                }
              </div>
            )}
            <div className="recurring">{isRecurring === "false" ? "One Time" : "Recurring"}</div>
          </div>
          {isRecurring === "true" && (
            <React.Fragment>
              {recurringType === "Daily" && <div className="time">Every {repeatEvery} Day </div>}
              {recurringType === "Weekly" && (
                <div className="time">
                  Every {repeatEvery > 1 ? repeatEvery + " " : ""}
                  Week, &nbsp;
                  <b>Days:</b> &nbsp;
                  {weekDays.map((m, ind) => (
                    <span key={ind}>{(ind != 0 ? ", " : "") + m.label}</span>
                  ))}
                </div>
              )}
              {recurringType === "Monthly" && (
                <div className="time">
                  Every {repeatEvery > 1 ? repeatEvery + " " : ""}
                  Month, &nbsp;
                  <b>Date:</b> &nbsp;
                  {monthDate.map((m, ind) => (
                    <span key={ind}>{(ind != 0 ? ", " : "") + m}</span>
                  ))}
                </div>
              )}
              <div className="time">
                <b>Recurring End:</b>{" "}
                {convertUTCToTimezoneWithOffset(recurringEndDate, timezone, "dddd, MMMM Do, YYYY")}{" "}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      {requiredGear &&
        requiredGear.length > 0 && (
          <React.Fragment>
            <br />
            <Divider />
            <div className={classes.requiredGear}>
              <br />
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                justifyContent={"space-between"}
              >
                REQUIRED GEAR
              </Typography>
              {requiredGear &&
                requiredGear.map((n, index) => <Chip key={index} label={n} variant="outlined" />)}
            </div>
            <br />
          </React.Fragment>
        )}
      {skills &&
        skills.length > 0 && (
          <React.Fragment>
            <Divider />
            <div className={classes.requiredGear}>
              <br />
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                justifyContent={"space-between"}
              >
                SUGGESTED SKILLS {tags && tags.length > 0 && 'AND TAGS'}
              </Typography>
              <ul>{skills && skills.map((n, index) => <li key={index}>{n}</li>)}</ul>
              {tags && tags.length > 0 && (
                <div style={{ marginBottom: "20px", paddingLeft: "20px" }}>
                  {tags.map((n, index) => (
                    <Chip key={index} label={n} variant="outlined" />
                  ))}
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      <Divider />
      <div className={classes.requiredGear}>
        <br />
        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
          justifyContent={"space-between"}
        >
          DESCRIPTION
        </Typography>
        <ul>
          <li style={{ wordBreak: "break-word" }}>{description}</li>
        </ul>
      </div>
      {selectedManagers && (
        <React.Fragment>
          <Divider />
          <div className={classes.requiredGear}>
            <br />
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
              justifyContent={"space-between"}
            >
              PROJECT ROLES
            </Typography>
            <Grid container spacing={2} justifyContent={"flex-start"}>
              {!isEmpty(siteManager) && <Grid item md={4} sm={6} xs={12}>
                <Card>
                  <CardHeader
                    avatar={<Avatar alt={siteManager.manager?.firstName} src={siteManager.manager?.signedProfileImageUrl} />}
                    title={
                      `${siteManager.manager.firstName} ${siteManager.manager.lastName}`
                    }
                    subheader="Site manager"
                  />
                </Card>
              </Grid>}
              {!isEmpty(checkInManager) && <Grid item md={4} sm={6} xs={12}>
                <Card>
                  <CardHeader
                    avatar={<Avatar alt={checkInManager.manager?.firstName} src={checkInManager.manager?.signedProfileImageUrl} />}
                    title={
                      `${checkInManager.manager.firstName} ${checkInManager.manager.lastName}`
                    }
                    subheader="Check-in manager"
                  />
                </Card>
              </Grid>}
              {!isEmpty(checkOutManager) && <Grid item md={4} sm={6} xs={12}>
                <Card>
                  <CardHeader
                    avatar={<Avatar alt={checkOutManager.manager?.firstName} src={checkOutManager.manager?.signedProfileImageUrl} />}
                    title={
                      `${checkOutManager.manager.firstName} ${checkOutManager.manager.lastName}`
                    }
                    subheader="Check-out manager"
                  />
                </Card>
              </Grid>}
            </Grid>
          </div>
          <br />
        </React.Fragment>
      )}

      {announcement.length &&
        announcement[0].title && (
          <React.Fragment>
            <Divider />
            <div className={classes.requiredGear}>
              <br />
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                gutterBottom
                justifyContent={"space-between"}
              >
                ANNOUNCEMENT ({announcement.length})
              </Typography>
              <Grid container spacing={2} justifyContent={"space-around"}>
                <Grid item md={12} sm={12} xs={12}>
                  {announcement.map((n, index) => (
                    <Card key={index} className={classes.announcementReview}>
                      <CardContent>
                        <div className="title">{n.title}</div>
                        <p className="description">{n.description}</p>
                        <div className="recurring">{n.repeatType}</div>
                        {n.repeatType === "Once" && <div> {n.before} minutes before</div>}
                        {n.repeatType === "Weekly" && (
                          <div>
                            Time: {moment(n.time).format("hh:mm a")}
                            &nbsp; (
                            {n.days.map((m, ind) => (
                              <span key={ind}>{(ind != 0 ? ", " : "") + m.label}</span>
                            ))}
                            )
                          </div>
                        )}
                      </CardContent>
                    </Card>
                  ))}
                </Grid>
              </Grid>
            </div>
            <br />
          </React.Fragment>
        )}

      <Divider />
      <div className={classes.requiredGear}>
        <br />
        <Typography
          sx={{ fontSize: 14 }}
          color="text.secondary"
          gutterBottom
          justifyContent={"space-between"}
        >
          BUDGET
        </Typography>
        <Grid container spacing={2}>
          {!canTemplateSelected && <Grid item md={6} sm={6} xs={12}>
            <Card>
              <CardHeader
                avatar={<img src={iconFloppyDisk} width={30} />}
                className={classes.templateTitle}
                title={`Save as a template`}
              />
              <div className={classes.template}>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  justifyContent={"space-between"}
                >
                  Save this job as a template for quick access in the future.
                </Typography>
                <div className={classes.templateForm}>
                  <TextField
                    id="template-name"
                    name="template-name"
                    placeholder="Template Name"
                    value={templateName}
                    onChange={handleChangeTemplateName}
                    fullWidth
                    variant="standard"
                    margin="none"
                  />
                  <Button
                    className={classes.continueButton}
                    size="small"
                    disabled={!templateName || isTemplateSaved}
                    onClick={saveTemplate}
                    label={"SAVE"}
                  />
                </div>
              </div>
            </Card>
          </Grid>}
          <Grid item md={canTemplateSelected ? 12 : 6} sm={canTemplateSelected ? 12 : 6} xs={12} justifyContent={"start"}>
            <Grid container xs={12} sm={12}>
              <Grid item xs={6} sm={10}>
                Hourly pay - {RegularPayHours.Hours} hrs {RegularPayHours.Minutes > 0 ? RegularPayHours.Minutes + ' mins' : ''}  x {noOfWorkers} worker
              </Grid>
              <Grid item xs={6} sm={2} sx={{ textAlign: "right" }}>
                {formatMoney(calculatedBill.hourlyPay)}
              </Grid>
            </Grid>
            {calculatedBill.overtimePay > 0 && (
              <Grid container xs={12} sm={12}>
                <Grid item xs={6} sm={10}>
                  Overtime pay - {OvertimePayHours.Hours} hrs {OvertimePayHours.Minutes > 0 ? OvertimePayHours.Minutes + ' mins' : ''} x {noOfWorkers} worker
                  <Tooltip title={overTimeText}>
                    <IconButton
                      style={{ padding: "5px", marginTop: "-3px" }}
                    >
                      <InfoRounded style={{ fontSize: "14px" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} sm={2} sx={{ textAlign: "right" }}>
                  {formatMoney(calculatedBill.overtimePay)}
                </Grid>
              </Grid>
            )}
            {calculatedBill.doublePay > 0 && (
              <Grid container xs={12} sm={12}>
                <Grid item xs={6} sm={10}>
                  Doubletime pay - {DoublePayHours.Hours} hrs {DoublePayHours.Minutes > 0 ? DoublePayHours.Minutes + ' mins' : ''}  x {noOfWorkers} worker
                  <Tooltip title={doubleTimeText}>
                    <IconButton
                      style={{ padding: "5px", marginTop: "-3px" }}
                    >
                      <InfoRounded style={{ fontSize: "14px" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} sm={2} sx={{ textAlign: "right" }}>
                  {formatMoney(calculatedBill.doublePay)}
                </Grid>
              </Grid>
            )}
            <Grid container xs={12} sm={12}>
              <Grid item xs={6} sm={10}>
                Grizzly Force Fee
                <IconButton
                  style={{ padding: "5px", marginTop: "-3px" }}
                  onClick={handleClickOpenFeesDialog}>
                  <InfoRounded style={{ fontSize: "14px" }} />
                </IconButton>
              </Grid>
              <Grid item xs={6} sm={2} sx={{ textAlign: "right" }}>
                {formatMoney(calculatedBill.gfFee)}
              </Grid>
            </Grid>
            <Grid container xs={12} sm={12}>
              <Grid item xs={6} sm={10}>
                GST ({calculatedBill.gstPercentage}
                %)
              </Grid>
              <Grid item xs={6} sm={2} sx={{ textAlign: "right" }}>
                {formatMoney(calculatedBill.gstAmount)}
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              className={classes.total}
              xs={12}
              sm={12}
            >
              <Grid item xs={6} sm={9}>
                TOTAL AMOUNT DUE
              </Grid>
              <Grid item xs={6} sm={3} sx={{ textAlign: "right" }}>
                {formatMoney(calculatedBill.total)}
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </div>
      <Button
        className={classes.continueButton}
        size="small"
        variant="outlined"
        onClick={handleClickOpen}
        label={"Post job"}
        isLoading={isPostJobLoading}
      />
      {openConfirmJob &&
        <PostJobDialog
          handleClose={handleClose}
          Time={countedHours}
          openPostJob={openConfirmJob}
          confirmJob={confirmJob}
        />
      }
      <Dialog
        open={isPostJobDialog}
        onConfirm={postJobConfirm}
        confirmText="Ok"
        alertType="success"
        title="This Job is successfully created."
      >
      </Dialog>
      <FeesDialog
        feesDialog={feesDialog}
        handleClickCloseFeesDialog={handleClickCloseFeesDialog}
      />
    </>
  );
}

export default Review;