import {gql} from "@apollo/client";

export const createJobTemplateMutation = gql`
    mutation createJobTemplate($data: CreateJobTemplateInput!) {
      createJobTemplate(data: $data) {
        id
      }
    }
`;

export default createJobTemplateMutation;