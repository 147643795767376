import React from "react";
import Typography from "@mui/material/Typography";
import Line from "components/Placeholders/Line";
import DisableCellOverlay from "../DisableCellOverlay";

export default function useName(disabled, isRatingAll = false) {
  return {
    Header: "Worker",
    id: "fullName",
    styles: {
      minWidth: '80px'
    },
    sortable: !isRatingAll,
    Cell: ({ row: { original: { fullName } } }) => {
      return (
        <>
          {fullName ? (
              <Typography variant="body1">{fullName}</Typography>
          ) : (
            <Line />
          )}
          <DisableCellOverlay visible={disabled} />
        </>
      );
    },
  };
}
