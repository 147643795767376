/*eslint-disable*/
import React from "react";
import { withStyles } from '@mui/styles';
import MUIDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Button from "components/Button/Button";
import Icon from "@mui/material/Icon";
import { NoteAdd } from "@mui/icons-material";
const isMobile = false;
const styles = ({ spacing }) => ({
  modal: {
    padding: isMobile ? spacing(2) : `${spacing(2)} ${spacing(6)}`,
    // paddingBottom: isMobile ? spacing(2) : spacing(1),
    margin: isMobile ? 0 : spacing(2),

    alignItems: "center",
    // width: spacing(60),
    overflowY: "unset",
  },
  editEmployerModal: {
    padding: isMobile ? spacing(2) : spacing(3),
    margin: isMobile ? 0 : spacing(2),

    alignItems: "center",
    // width: spacing(60
    // overflowY: "unset",
  },
  qbConfirmModal: {
    padding: spacing(2),
    alignItems: "center",

    overflowY: "unset",
  },
  title: {
    padding: `${spacing(3)} 0 ${spacing(1)} 0`,
    alignItems: "flex-start",
    width: "100%",
    marginTop: isMobile ? "inherit" : spacing(-2),
  },
  content: {
    padding: `${spacing(1)} 0 ${spacing(1)} 0`,
    // overflowY: "auto",
    overflowY: "unset",
    width: "100%",
  },
  footer: {
    margin: 0,
  },
});

const variantIcon = {
  success: "check_circle",
  warning: "error",
  error: "cancel",
  cancelTransfer: "account_balance_wallet_two_tone",
  info: "info_outline",
  question: "help_outline",
  save: "help_outline",
};

function Dialog(props) {
  const {
    size,
    classes,
    cancelText,
    disabled,
    confirmText,
    title,
    description,
    icon,
    onConfirm,
    onCancel,
    onClose,
    open,
    disableBackdropClick,
    alertType,
    modalType,
    children,
    qbIcon,
    formContent,
    hideActions,
    payload,
    pmt,
    fullWidth,
    showPlaceholderIcon,
    contentStyles
  } = props;

  const variantFooter = {
    success: (
      <Button
        label={confirmText || "Got It"}
        onClick={onConfirm}
        color="secondary"
        disabled={disabled}
      />
    ),
    cancelTransfer: (
      <React.Fragment>
        <Button label={cancelText || "close"} onClick={onCancel} color="transparent" />
        <Button
          label={confirmText || "Cancel eTransfer"}
          onClick={onConfirm}
          color="error"
          disabled={disabled}
        />
      </React.Fragment>
    ),
    warning: (
      <React.Fragment>
        <Button label={cancelText || "Cancel"} onClick={onCancel} color="transparent" />
        <Button
          label={confirmText || "Yes"}
          onClick={onConfirm}
          color="error"
          disabled={disabled}
        />
      </React.Fragment>
    ),
    error: <Button label={confirmText || "Ok"} onClick={onConfirm} color="secondary" />,
    info: (
      <Button
        label={confirmText || "Ok"}
        onClick={onConfirm}
        color="secondary"
        disabled={disabled}
      />
    ),
    question: (
      <React.Fragment>
        <Button label={cancelText || "Cancel"} onClick={onCancel} color="transparent" />
        <Button
          label={confirmText || "Yes"}
          onClick={onConfirm}
          color="secondary"
          disabled={disabled}
        />
      </React.Fragment>
    ),
    save: (
      <React.Fragment>
        <Button label={cancelText || "Cancel"} onClick={onCancel} color="error" />
        <Button
          label={confirmText || "Yes"}
          onClick={onConfirm}
          color="secondary"
          disabled={disabled}
        />
      </React.Fragment>
    ),
    form: (
      <React.Fragment>
        <Button label={cancelText || "Cancel"} onClick={onCancel} color="transparent" />
        <Button
          disabled={disabled}
          label={confirmText || "Update"}
          onClick={onConfirm}
          color="secondary"
        />
      </React.Fragment>
    ),
  };

  return (
    <MUIDialog
      classes={{
        paper: qbIcon
          ? classes.qbConfirmModal
          : modalType === "scrollable"
          ? classes.editEmployerModal
          : classes.modal,
      }}
      open={open}
      fullWidth={fullWidth ? fullWidth : false}
      fullScreen={isMobile}
      disablePortal={true}
      style={{ alignItems: "flex-start" }}
      maxWidth={size ? size : "md"}
      size={size ? size : "sm"}
      onClose={(event, reason) => {
        if ((reason !== 'backdropClick' || disableBackdropClick) && onClose) {
          onClose(event, reason);
        }
      }}>
      {alertType !== "form" && !qbIcon && (
        <Icon color="disabled" style={{ fontSize: props.iconFontSize ? props.iconFontSize : 96 }}>
          {icon ? icon : variantIcon[alertType]}
          {/* {qbIcons.invoice} */}
        </Icon>
      )}

      {formContent && showPlaceholderIcon && <NoteAdd fontSize="large" color="secondary" />}

      {qbIcon && <img src={qbIcon} width={162} style={{ marginRight: 0 }} />}
      <DialogTitle className={classes.title} align="center">
          {title}
      </DialogTitle>

      <DialogContent
        className={classes.content}
        style={{ textAlign: "center", maxWidth: isMobile ? "100%" : "100%", ...(contentStyles ? contentStyles : {}) }}>
        {description ? (
          <Typography variant="body1" align="center" justifyContent="center">
            {description}
          </Typography>
        ) : null}
        {children ? <div>{children}</div> : null}
        {formContent ? <div>{formContent}</div> : null}
      </DialogContent>

      <DialogActions
        style={{
          visibility: hideActions ? "hidden" : "visible",
          height: hideActions ? 0 : "inherit",
          ...(hideActions ? { padding: 0 } : {}),
        }}
        hidden
        className={classes.footer}>
        {variantFooter[alertType]}
      </DialogActions>
    </MUIDialog>
  );
}
Dialog.defaultProps = {
  disableBackdropClick: true
};
export default withStyles(styles)(Dialog);
