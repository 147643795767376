import ApolloClient from "utils/apollo";
import { getMe } from "../login/selectors";
import FetchAnnouncements from "./graphql/queries/fetch-announcements";
import CreateAnnouncement from "./graphql/mutations/create-announcement";
import { actions as MetaActions } from "store/modules/meta";
import types from "./types";

const setAnnouncements = (data) => ({ type: types.FETCH_ANNOUNCEMENTS_LIST_SUCCESS, payload: { data } })
const setArchiveAnnouncements = (data) => ({ type: types.FETCH_ARCHIVE_ANNOUNCEMENTS_LIST_SUCCESS, payload: { data } })

//set loading state
const setLoadingState = key => value => ({
    type: types.SET_LOADING_STATE,
    payload: { key, value },
});
const setIsPageLoading = setLoadingState("page");
const setIsCreateAnnouncementLoading = setLoadingState("createAnnouncement");

//set dialog state
const setDialogState = key => value => ({
    type: types.SET_DIALOG_STATE,
    payload: { key, value },
});

export const setOpenAnnouncementCreateDialog = setDialogState("newAnnouncement");

export const createAnnouncement = (announcementData, job) => (dispatch, getState) => {
    dispatch(setIsCreateAnnouncementLoading(true));

    const state = getState();
    const { employerId } = getMe(state);

    const variable = {
        ...announcementData,
        employerId: employerId,
        jobId: job.data.id,
        workers: announcementData.workers?.map(a => a.id) || [],
        beforeAfter: "Before",
        sendAt: announcementData.sendAt.format("HH:mm"),
        dayOrWeekDay: announcementData.dayOrWeekDay?.map(a => a.value) || []
    };


    return ApolloClient.mutate({
        mutation: CreateAnnouncement,
        variables: { data: variable },
    })
        .then(({ data, errors }) => {
            if (!data && errors.length > 0) {
                throw new Error(errors[0].message);
            }
            else {
                dispatch(MetaActions.successToast('This announcement is successfully created.'));
                dispatch(setOpenAnnouncementCreateDialog(false));
                dispatch(fetchAnnouncements(0));
            }
            dispatch(setIsCreateAnnouncementLoading(false));
            return data;
        })
        .catch((e) => {
            dispatch(setIsCreateAnnouncementLoading(false))
            dispatch(MetaActions.errorToast(`${e.message}`));
        });
}

export const fetchAnnouncements = timeframe => (dispatch, getState) => {
    dispatch(setIsPageLoading(true));
    const state = getState();
    const isArchived = Boolean(+timeframe);
    const { employerId } = getMe(state);
    return ApolloClient.query({
        query: FetchAnnouncements,
        variables: {
            employerId: employerId,
            archive: isArchived
        },
    })
        .then(({ data: raw }) => {
            dispatch(isArchived ? setArchiveAnnouncements(raw.jobAnnouncementByEmployerId) : setAnnouncements(raw.jobAnnouncementByEmployerId));
            dispatch(setIsPageLoading(false));
        })
        .catch((er) => {
            dispatch(MetaActions.errorToast(`${e.message}`));
            dispatch(setIsPageLoading(false));
        });
};