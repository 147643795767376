import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Dialog from "components/Dialog/Dialog";

function IncreasePositionsDialog(props) {
  const { open, onClose, onCancel, onConfirm, value, onChange } = props;

  return (
    <Dialog
      open={open}
      title="How many more positions?"
      description={null}
      onClose={onClose}
      onConfirm={onConfirm}
      onCancel={onCancel}
      alertType="question"
      icon="exposure_plus_1">
      <Grid container direction="column" alignItems="center">
        <Typography variant="body1" align="center">
          {`This action will affect billing.`}
        </Typography>
        <TextField
          placeholder="Increase by..."
          value={value}
          onChange={onChange}
          type="number"
          margin="normal"
          inputProps={{ min: 1 }}
        />
      </Grid>
    </Dialog>
  );
}

export default IncreasePositionsDialog;
