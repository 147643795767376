import { createNetworkTypes } from "utils/redux";


export const networkTypes = {
    FETCH_ANNOUNCEMENTS_LIST: "FETCH_ANNOUNCEMENTS_LIST",
    FETCH_ARCHIVE_ANNOUNCEMENTS_LIST: "FETCH_ARCHIVE_ANNOUNCEMENTS_LIST",
};

export default {
    ...createNetworkTypes(networkTypes.FETCH_ANNOUNCEMENTS_LIST, "communication"),
    ...createNetworkTypes(networkTypes.FETCH_ARCHIVE_ANNOUNCEMENTS_LIST, "communication"),
    SET_LOADING_STATE: "communication/SET_LOADING_STATE",
    SET_DIALOG_STATE: "communication/SET_DIALOG_STATE",
};