import { Campaign,Block, FavoriteSharp, Group, Work, Settings, Chat } from "@mui/icons-material";
import ChatSupport from "views/ChatSupport";
import Communication from "views/Communication";
import JobDetails from "views/Job/JobDetails/JobDetails";
import JobOverview from "views/Job/JobOverview/JobOverview";
import PostJob from "views/Job/PostJob";
import Logout from "views/Login/Logout";
import Managers from "views/Managers";
import Profile from "views/Profile";
import Setting from "views/Settings";
import EditJobTemplate from "views/Settings/EditJobTemplate";
import BanWorkers from "views/Worker/BanWorkers";
import PreferredWorkers from "views/Worker/PreferredWorkers";

const newDashRoutes = [
  //   {
  //     path: "/finance/payments/batch/:id",
  //     name: "Batch Details",
  //     component: BatchDetails,
  //     hidden: true,
  //     backButton: true,
  //     permission: "finance:view:base",
  //   },
  {
    path: "/profile",
    name: "Profile",
    hidden: true,
    component: Profile,
  },
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   icon: Home,
  //   component: Dashboard,
  // },
  {
    path: "/job-overview",
    name: "Jobs",
    icon: Work,
    component: JobOverview,
    // permission: "job:read:list",
  },
  {
    path: "/jobs/post",
    name: "Post Job",
    state: "postJob",
    component: PostJob,
    hidden: true,
    backButton: true,
    // permission: "job:create:new",
  },
  {
    path: "/jobs/:id",
    name: "Job Details",
    state: "viewJobDetails",
    component: JobDetails,
    hidden: true,
    backButton: true,
    // permission: "job:view:detail",
  },
  {
    path: "/communication",
    name: "Communication",
    icon: Campaign,
    component: Communication,
  },
  {
    path: "/worker-banned",
    name: "Banned Worker",
    icon: Block,
    component: BanWorkers,
  },
  {
    path: "/worker-preferred",
    name: "Preferred Worker",
    icon: FavoriteSharp,
    component: PreferredWorkers,
  },
  {
    path: "/managers",
    name: "Managers",
    icon: Group,
    component: Managers,
    permission: "manager:view:list",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: Settings,
    component: Setting,
    // permission: "settings:view:base",
  },
  {
    path: "/edit-template",
    name: "Edit Template",
    hidden: true,
    backButton: true,
    component: EditJobTemplate,
    // permission: "settings:view:base",
  },
  {
    path: "/duplicate-template",
    name: "Duplicate Template",
    hidden: true,
    backButton: true,
    component: EditJobTemplate,
    permission: "settings:view:base",
  },
  {
    path: "/logout",
    name: "Logout",
    state: "logout",
    hidden: true,
    component: Logout,
  },
  {
    path: "/chat-with-us",
    name: "Chat with us",
    icon: Chat,
    component: ChatSupport,
  },
  { redirect: true, path: "/", pathTo: "/job-overview", name: "Dashboard" },
];

export default newDashRoutes;
