import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Line from "components/Placeholders/Line";
import { jobRequiresBreak } from "utils/time";
import { actions as JobDetailActions, selectors as JobDetailSelectors } from "store/modules/jobDetails";
import DisableCellOverlay from "../DisableCellOverlay";

const useStyles = makeStyles(() => ({
  selectRoot: {
    margin: 0,
  },
  selectInput: {
    border: "none !important",
  },
}));

export default function useBreakMins(isCheckingOutAll, disabled) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [batchBreakValue, setBatchBreakValue] = useState("");

  const canAddWorker = useSelector(JobDetailSelectors.getCanAddWorker);
  const positionUpdates = useSelector(JobDetailSelectors.getPositionUpdates);

  const handleBatchApply = event => {
    const breakMins = parseInt(event.target.value, 10);
    if (isNaN(breakMins)) {
      setBatchBreakValue("");
    } else {
      dispatch(JobDetailActions.batchUpdatePositionData("breakMins")(breakMins));
      setBatchBreakValue(breakMins);
    }
  };

  const updatePositionBreakMins = JobDetailActions.updatePositionData("breakMins");

  return {
    Header: "Break",
    id: "breakMins",
    filterable: isCheckingOutAll,
    sortable: false,
    style: isCheckingOutAll ? { position: "unset" } : {},
    headerStyle: isCheckingOutAll ? { position: "unset" } : {},
    Filter: () => {
      const hasRequiredBreaks = positionUpdates.some(({ requiresBreak }) => requiresBreak);

      if (!hasRequiredBreaks) {
        return <Line />;
      }

      return (
        <select onChange={handleBatchApply} style={{ width: "100%" }} value={batchBreakValue}>
          <option value="">Set All</option>
          {[0, 15, 30, 45, 60].map(mins => (
            <option key={`breakMins-all-${mins}`} value={mins}>
                { mins===0 ? 'No Break' : `${mins} mins`}
            </option>
          ))}
        </select>
      );
    },
    Cell: ({ row: { original: { id, startShift, endShift, breakMins, isEditable } } }) => {
      if (isCheckingOutAll && isEditable) {
        const { breakMins, requiresBreak } = positionUpdates.find(
          info => info.id === id,
        );

        if (!requiresBreak) {
          return <Line />;
        }

        const onChange = event => {
          dispatch(updatePositionBreakMins(id, event.target.value));
        };

        return (
          <TextField
            select
            variant="standard"
            value={breakMins}
            onChange={onChange}
            margin="normal"
            classes={{
              root: classes.selectRoot,
            }}
            InputProps={{
              classes: {
                root: classes.selectInput,
                focused: classes.selectInput,
              },
            }}
          >
            {[0, 15, 30, 45, 60].map(mins => (
              <MenuItem key={`breakMins-${mins}`} value={mins}>
                { mins===0 ? 'No Break' : `${mins} mins`}
              </MenuItem>
            ))}
          </TextField>
        );
      }

      const requiredBreak = jobRequiresBreak(startShift, endShift);

      return (
        <div style={{ position: "relative" }}>
          {breakMins === null || breakMins === undefined || !requiredBreak ? (
            <Line />
          ) : (
            <Typography variant="body1">{breakMins === 0 ? 'No Break' : `${breakMins} mins`}</Typography>
          )}
          <DisableCellOverlay visible={disabled || (isCheckingOutAll && !isEditable)} />
        </div>
      );
    },
    Footer: canAddWorker && !disabled ? <Line /> : undefined,
  };
}
