import * as pagination from "utils/graphql-pagination";
import { createReducer } from "utils/redux";
import { paginationSettings } from "./constants";
import types from "./types";

const initialBaseListStats = {
  count: 0,
  bookings: 0,
  revenue: 0,
  hours: 0,
};

const initialListStats = {
  total: { ...initialBaseListStats },
  active: { ...initialBaseListStats },
  filled: { ...initialBaseListStats },
  unfilled: { ...initialBaseListStats },
  completed: { ...initialBaseListStats },
};

const initialListState = {
  sort: [],
  filter: [],
  data: [],
};

const initialState = {
  timeframeStats: {
    today: 0,
    tomorrow: 0,
    upcoming: 0,
    drafts: 0,
  },
  listStats: {
    today: { ...initialListStats },
    tomorrow: { ...initialListStats },
    upcoming: { ...initialListStats },
  },
  today: {
    total: { ...initialListState },
    active: { ...initialListState },
    unfilled: { ...initialListState },
    filled: { ...initialListState },
    completed: { ...initialListState },
  },
  tomorrow: {
    total: { ...initialListState },
    unfilled: { ...initialListState },
    filled: { ...initialListState },
  },
  upcoming: {
    total: { ...initialListState },
    unfilled: { ...initialListState },
    filled: { ...initialListState },
  },
  history: { ...initialListState },
  drafts: { ...initialListState },
  queued: { ...initialListState },
  ...pagination.initialState(paginationSettings),
};

const actionsMap = {
  [types.FETCH_TIMEFRAME_STATS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      timeframeStats: payload,
    };
  },
  [types.FETCH_LIST_STATS_SUCCESS]: (state, { payload }) => {
    const { timeframe, data } = payload;
    return {
      ...state,
      listStats: {
        ...state.listStats,
        [timeframe]: data,
      },
    };
  },
  [types.SET_LIST_DATA]: (state, { payload }) => {
    const { data, timeframe, status, part } = payload;
    if (!timeframe) {
      return state;
    }

    if (!status) {
      if (state[timeframe].data) {
        return {
          ...state,
          [timeframe]: {
            ...state[timeframe],
            [part]: data,
          },
        };
      }

      return state;
    }

    if (!state[timeframe][status].data) {
      return state;
    }

    return {
      ...state,
      [timeframe]: {
        ...state[timeframe],
        [status]: {
          ...state[timeframe][status],
          [part]: data,
        },
      },
    };
  },
  ...pagination.createPaginationActions(paginationSettings),
};

export default createReducer(initialState, actionsMap);
