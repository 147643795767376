export const getTimezoneListData = (state) => {
  return state.settings.timezoneList;
};

export const getMyJobTemplateListData = (state) => {
  return state.settings.myJobTemplateList;
};
export const getCompanyJobTemplateListData = (state) => {
  return state.settings.companyJobTemplateList;
};

export const getJobTemplateDetail= (state) => {
  return state.settings.templateDetail;
};

export const getJobTemplateAddress= (state) => {
  let address = '';
  if(state.settings.templateDetail.street){
      address += state.settings.templateDetail.street + ' '
  }
  if(state.settings.templateDetail.city){
    address += state.settings.templateDetail.city + ' '
}
if(state.settings.templateDetail.region){
  address += state.settings.templateDetail.region + ' '
}
  return {label: address };
};

// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.settings.loading[loader] || false;
};

export const getIsPageLoading = getLoadingStateFactory("page");
export const getIsUpdatingProfileLoading = getLoadingStateFactory("updateTimezone");
export const getIsUpdateTemplateLoading = getLoadingStateFactory("updateTemplate");