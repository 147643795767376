import {gql} from "@apollo/client";

export default gql`
  fragment EmployerFields on Employer {
    email
    firstName
    id
    lastName
    companyName
    businessPhone
    mobilePhone
    rating
    jobCount
    isActive
    profileImageUrl
    signedProfileImageUrl
    logoImageUrl
    signedLogoImageUrl
    payRate
    billingRate
    allowPreferredOnly
  }
`;
