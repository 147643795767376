/* eslint-disable import-helpers/order-imports */
import React, { useEffect, useState } from "react";
import { Avatar, Chip, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import Dialog from "components/Dialog/Dialog";
import { useStyles } from "./styles";
import JobAutocomplete from "components/Autocomplete/JobAutocomplete";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { weekDaysList } from "utils/constant";
import { convertUTCToTimezone, convertUTCToTimezoneWithOffset, formattedTimeWithOffset, getDaysOfWeekBetweenDates, getTimeZone, getTotalTime } from "utils/time";
import moment from "moment";
import iconPinLocation from "assets/img/iconPinLocation.svg";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { selectors as communicationSelectors } from "store/modules/communication";
import { actions as MetaActions } from "store/modules/meta";
import { useDispatch } from "react-redux";
import MobileTimePicker from "components/DateTimePicker/MobileTimePicker";

const initialState = {
    title: "",
    description: "",
    frequency: "Once",
    dayOrWeekDay: [],
    sendAt: "",
    beforeAfter: "",
    minutes: 15,
    workers: [],
};

function AnnouncementFormDialog(props) {
    const classes = useStyles();
    const { open, onCancel, onConfirm } = props;
    const [selectedJob, setSelectedJob] = useState(null);
    const [totalTimeInHours, setTotalTimeInHours] = useState(0);
    const [announcement, setAnnouncement] = useState(initialState);
    const [selectedWeekDays, setSelectedWeekDays] = useState([]);
    const [timezone, setTimezone] = useState('');
    const [isValidTime, setIsValidTime] = useState(true);
    const [workers, setWorkers] = useState([]);
    const createAnnouncementLoading = useSelector(communicationSelectors.getIsCreateAnnouncementLoading);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!open) {
            setAnnouncement(initialState);
            setSelectedJob(null);
            setSelectedWeekDays([]);
        }
    }, [open]);

    useEffect(() => {
        setTimezone(getTimeZone());
    }, []);

    useEffect(() => {
        setWorkers(selectedJob?.data?.positions?.filter(({ worker }) => !announcement.workers.find(w => w.id === worker.id)));
    }, [announcement.workers]);

    useEffect(() => {
        if (selectedJob) {
            setSelectedWeekDays(getDaysOfWeekBetweenDates(moment.tz(selectedJob.data.timezone), moment(selectedJob.data.startUtc).tz(selectedJob.data.timezone)));
            setTimezone(selectedJob.data.timezone);
            setAnnouncement(p => ({ ...p, workers: [], sendAt: cloneDeep(moment(selectedJob.data.startUtc)) }));
            setTotalTimeInHours(getTotalTime(selectedJob.data.startUtc, moment.tz()));
            setWorkers(selectedJob?.data?.positions);
        }
    }, [selectedJob]);

    const handleChange = (key, value) => {
        if (announcement.workers?.find(a => a.id === value)) {
            dispatch(MetaActions.errorToast(`Worker already added`));
            removeFocus();
            return;
        }
        if (key === "workers") {
            removeFocus();
            const data = workers.find(a => a.worker.id === value);
            if (data?.worker)
                value = [...announcement.workers, data.worker]
        }
        setAnnouncement((data) => ({ ...data, [key]: key === "sendAt" ? cloneDeep(value) : value }))
    };

    const handleRemoveWorker = (e, worker) => {
        setAnnouncement((data) => ({ ...data, workers: data.workers.filter(a => a.id !== worker.id) }))
    };

    const handleChangeJob = (job) => {
        setSelectedJob(job)
    }

    const handleChangeRepeatDays = (e) => {
        const dayOrWeekDay = Object.assign([], announcement.dayOrWeekDay);
        const isExist = dayOrWeekDay.find(a => a.value === e.value);
        if (!isExist) dayOrWeekDay.push(e)
        setAnnouncement(p => ({ ...p, dayOrWeekDay: isExist ? dayOrWeekDay.filter(a => a.value !== e.value) : dayOrWeekDay }));
    };

    //Address formatter
    const formatAddress = (...rest) => rest.filter(a => a).join(", ");

    //remove focuse handler
    const removeFocus = () => setTimeout(() => document.activeElement.blur(), 0);

    return (
        <>
            <Dialog
                disabled={
                    createAnnouncementLoading ||
                    !announcement.title
                    || !announcement.description
                    || (announcement.frequency === "Weekly" && (!announcement.dayOrWeekDay.length || !isValidTime))}
                title={"Add Announcement"}
                description={"Add an announcement that will notify the workers of this job "}
                open={open}
                modalType={"scrollable"}
                showPlaceholderIcon
                onConfirm={() => onConfirm(announcement, selectedJob)}
                onCancel={!createAnnouncementLoading && onCancel}
                onClose={!createAnnouncementLoading ? onCancel : () => 0}
                confirmText={createAnnouncementLoading ? <CircularProgress size={20} /> : "Create"}
                alertType="form" >
                <div className={classes.formContainer}>
                    <Grid container spacing={2}>
                        <Grid item md={12} sm={12} xs={12}>
                            <JobAutocomplete
                                searchWithEmployeId
                                margin="none"
                                id="selectJob"
                                name="selectJob"
                                {...(selectedJob ? {
                                    value: selectedJob
                                } : {})}
                                label="Select Job"
                                onChange={handleChangeJob}
                            />
                            {selectedJob && <div>
                                <div className={classes.jobInfo}>
                                    <div className={classes.JobTitleContainer}>
                                        <Typography sx={{ fontSize: 14, marginBottom: 0 }} gutterBottom align="left">
                                            {selectedJob.data.workType.label}
                                        </Typography>
                                        <div className={classes.location}>
                                            <div className="icon">
                                                <img src={iconPinLocation} />
                                            </div>
                                            <div className={classes.address}>
                                                {formatAddress(selectedJob.data?.unit, selectedJob.data?.street, selectedJob.data?.city, selectedJob.data?.region)}
                                            </div>
                                        </div>
                                    </div>
                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom align="left">
                                        {convertUTCToTimezoneWithOffset(selectedJob.data.startUtc, selectedJob.data.timezone, "MMM Do, YYYY")}
                                    </Typography>
                                    <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom align="left">
                                        {formattedTimeWithOffset(selectedJob.data.startUtc, selectedJob.data.endUtc, selectedJob.data.timezone)}
                                    </Typography>
                                </div>
                            </div>}
                        </Grid>

                        <Grid item md={12} sm={12} xs={12}>
                            <TextField
                                inputProps={{ maxLength: 100 }}
                                id="title"
                                name="title"
                                label="Announcement Title"
                                value={announcement.title}
                                onChange={e => handleChange("title", e.target.value)}
                                fullWidth
                                margin="none"
                            />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <TextField
                                inputProps={{ maxLength: 2000 }}
                                id="description"
                                name="description"
                                label="Description"
                                value={announcement.description}
                                onChange={e => handleChange("description", e.target.value)}
                                fullWidth
                                margin="none"
                                multiline
                                minRows={3}
                            />
                        </Grid>
                        {selectedJob && (
                            <>
                                <Grid item md={12} sm={12} xs={12} textAlign="left">
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-multiple-chip-label">Select Workers</InputLabel>
                                        <Select
                                            fullWidth
                                            labelId="demo-multiple-chip-label"
                                            id="demo-multiple-chip"
                                            onClose={removeFocus}
                                            value={''}
                                            label="Select Workers"
                                            onChange={(e) => {
                                                handleChange("workers", e.target.value)
                                            }}
                                        >
                                            {workers?.map((pos) => (
                                                <MenuItem
                                                    key={pos.worker?.id}
                                                    value={pos.worker?.id}
                                                >
                                                    {pos.worker?.fullName}
                                                </MenuItem>
                                            ))}
                                            {(!workers || !workers.length) && <MenuItem disabled style={{ justifyContent: "center" }}>Workers not found</MenuItem>}
                                        </Select>
                                    </FormControl>
                                    <div className={classes.workersContainer}>
                                        {announcement.workers.map((worker) => {
                                            return (
                                                <Chip
                                                    avatar={<Avatar alt={worker?.fullName} src={worker?.signedProfileImageUrl} />}
                                                    key={worker}
                                                    className={classes.workerChip}
                                                    label={worker?.fullName}
                                                    onDelete={(e) => handleRemoveWorker(e, worker)} />
                                            )
                                        })}
                                    </div>
                                </Grid>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom align="left">
                                        REPEAT
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} textAlign="left">
                                    <Select
                                        labelId="repeat-type-select-label"
                                        id="repeat-type-select-label"
                                        value={announcement.frequency}
                                        onChange={e => handleChange("frequency", e.target.value)}
                                        fullWidth
                                    >
                                        <MenuItem value={"Once"}>Once</MenuItem>
                                        <MenuItem value={"Weekly"}>Weekly</MenuItem>
                                    </Select>
                                    <br />
                                    {announcement.frequency === "Weekly" && (
                                        <div className={classes.weekDays} style={{ marginTop: "10px" }}>
                                            {weekDaysList.map(day => (
                                                <div
                                                    key={day.value}
                                                    onClick={() => handleChangeRepeatDays(day)}
                                                    className={`${classes.days} ${!selectedWeekDays.includes(day.value) && classes.disabledDay} ${announcement.dayOrWeekDay.indexOf(day) > -1 ? classes.selectedDays : ""}`}>
                                                    {day.label}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12} textAlign="left">
                                    {announcement.frequency === "Once" && (
                                        <Select
                                            labelId="before-select-label"
                                            id="before-select-label"
                                            value={announcement.minutes}
                                            onChange={e => handleChange("minutes", e.target.value)}
                                            fullWidth
                                        >
                                            <MenuItem value={15}>15 minutes before</MenuItem>
                                            <MenuItem value={30}>30 minutes before</MenuItem>
                                            <MenuItem value={45}>45 minutes before</MenuItem>
                                            <MenuItem value={60}>60 minutes before</MenuItem>
                                        </Select>
                                    )}

                                    {announcement.frequency === "Weekly" && (
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <MobileTimePicker
                                                onError={(e) => setIsValidTime(!e)}
                                                label="Time"
                                                minTime={totalTimeInHours < 24 && convertUTCToTimezone(moment.tz(),timezone)}
                                                maxTime={totalTimeInHours < 24 && convertUTCToTimezone(selectedJob.data?.startUtc, timezone)}
                                                value={convertUTCToTimezone(announcement.sendAt, timezone)}
                                                onChange={e => handleChange("sendAt", e)}
                                                renderInput={params => <TextField fullWidth {...params} />}
                                            />
                                        </LocalizationProvider>
                                    )}
                                </Grid>
                            </>)}
                    </Grid>
                </div>
            </Dialog>
        </>
    )
}

export default AnnouncementFormDialog;