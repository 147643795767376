import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import TableComponent from "../TableComponent";

const UnFilledPage = (props) => {
  const dispatch = useDispatch();
  const pageInfo = useSelector(JobSelectors.getUpcomingUnfilledPaginationData)
  const jobListData = useSelector(JobSelectors.getListData({ timeframe: 'upcoming', status: 'unfilled', part: "data" }))
  const pageFilter = useSelector(JobSelectors.getListData({ timeframe: 'upcoming', status: 'unfilled', part: "filter" }))
  const pageSort = useSelector(JobSelectors.getListData({ timeframe: 'upcoming', status: 'unfilled', part: "sort" }))
  const fetchData = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
    dispatch(JobActions.setUpcomingUnfilledPageSize(pageSize));
    dispatch(JobActions.setUpcomingUnfilledFilter(filters));
    dispatch(JobActions.setUpcomingUnfilledSort(sortBy));
    dispatch(JobActions.fetchUpcomingUnfilledJobs(pageIndex));
  }, []);

  return <TableComponent pageInfo={pageInfo} jobListData={jobListData} pageFilter={pageFilter} pageSort={pageSort} fetchData={fetchData} history={props.history}/>
}

export default UnFilledPage