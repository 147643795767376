import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import { getTimeZone } from "utils/time";
import fetchJobQuery from "../graphql/fetchJobQuery";
import query from "../graphql/queries";

const autocompleteService = new window.google.maps.places.AutocompleteService();
const placesService = new window.google.maps.places.PlacesService(document.createElement("div"));

const formattedSuggestion = structured_formatting => ({
  mainText: structured_formatting.main_text,
  secondaryText: structured_formatting.secondary_text,
});

export const fetchJobs = (pageFilter, user) => {
  const extraPayload = pageFilter ? {
    like: [
      {
        field: "id",
        value: pageFilter,
      }
    ]
  } : {};
  return ApolloClient.query({
    query: fetchJobQuery,
    variables: {
      first: 5,
      [user.accountType === "employermanager" ? "managerId" : "employerId"]: user.accountId,
      status: "NotStarted",
      isActive: true,
      timezone: getTimeZone(),
      ...extraPayload
    },
  })
    .then(({ data }) => {
      const jobs = flattenGraphQLArray(data.jobs).map(job => ({
        value: job.id,
        label: `${job.id} - ${job.workType.label}`,
        data: job,
      }));
      return jobs;
    })
    .catch(console.log);
};

export const fetchEmployers = pageFilter => {
  return ApolloClient.query({
    query: query,
    variables: {
      first: 5,
      order: [
        {
          field: "companyName",
          direction: "ASC",
        },
      ],
      like: [
        {
          field: "companyName",
          value: pageFilter,
        },
      ],
      canPost: true,
    },
  })
    .then(({ data }) => {
      const employers = flattenGraphQLArray(data.employers).map(employer => ({
        value: employer.id,
        label: employer.companyName,
        data: employer,
      }));
      return employers;
    })
    .catch(console.log);
};

export const fetchPlaceDetails = placeId => {
  return new Promise((resolve, reject) => {
    placesService.getDetails(
      { fields: ["address_components", "geometry", "utc_offset_minutes"], placeId },
      (result, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          reject();
          return;
        }

        const addressParts = result.address_components.reduce((acc, { types, ...rest }) => {
          if (types.includes("street_number") && rest.short_name) {
            acc.streetNumber = rest.short_name;
          }

          if (types.includes("route") && rest.long_name) {
            acc.streetName = rest.long_name;
          }

          if (types.includes("locality") && rest.long_name) {
            acc.city = rest.long_name;
          }

          if (types.includes("administrative_area_level_1") && rest.short_name) {
            acc.region = rest.short_name;
          }

          if (types.includes("country") && rest.long_name) {
            acc.country = rest.long_name;
          }

          if (types.includes("postal_code") && rest.long_name) {
            acc.postalCode = rest.long_name;
          }
          return acc;
        }, {});

        resolve({
          street: `${addressParts.streetNumber ? addressParts.streetNumber + " " : ""}${addressParts.streetName
            }`,
          city: addressParts.city,
          region: addressParts.region,
          country: addressParts.country,
          postalCode: addressParts.postalCode,
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
          utc_offset_minutes: result.utc_offset_minutes,
        });
      }
    );
  });
};

export const fetchPlacePredictions = filter => {
  if (filter.length) {
    return new Promise((resolve, reject) => {
      autocompleteService.getPlacePredictions(
        {
          componentRestrictions: {
            country: "ca",
          },
          types: ["address"],
          language: "en",
          input: filter,
        },
        (predictions, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            reject();
            return;
          }

          const suggestions = predictions.map((p, idx) => ({
            value: p.id,
            label: p.description,
            data: {
              id: p.id,
              description: p.description,
              placeId: p.place_id,
              index: idx,
              formattedSuggestion: formattedSuggestion(p.structured_formatting),
              matchedSubstrings: p.matched_substrings,
              terms: p.terms,
              types: p.types,
            },
          }));

          resolve(suggestions);
        }
      );
    });
  }

  return Promise.resolve([]);
};
