import ApolloClient from "utils/apollo";
import { actions as MetaActions } from "store/modules/meta";
import { logout } from "../login/actions";
import { getMe } from "../login/selectors";
import updateProfileMutation, {
  updateManagerProfileImageMutation,
  updateManagerProfileMutation,
  uploadEmployerFileToS3Mutation,
} from "./graphql/mutations/update-profile";
import GetEmployerQuery from "./graphql/queries/get-employer";
import GetManagerQuery from "./graphql/queries/get-manager";
import types from "./types";

const setLoadingState = key => value => ({
  type: types.SET_LOADING_STATE,
  payload: { key, value },
});
const setDialogState = key => value => ({
  type: types.SET_DIALOG_STATE,
  payload: { key, value },
});
const setIsUpdateProfileLoading = setLoadingState("updateProfile");
const setIsChangeCompanyLoading = setLoadingState("changeCompany");
export const setIsProfileDialog = setDialogState("profileDialog");
export const setIsCompanyDialog = setDialogState("companyDialog");
export const setIsBillingInfoDialog = setDialogState("billingInfoDialog");

export const fetchProfile = () => (dispatch, getState) => {
  const state = getState();
  const { accountType, accountId } = getMe(state);
  if (accountType === 'employermanager') {
    dispatch(fetchManagerProfile(accountId))
  } else if (accountType === 'employer') {
    dispatch(fetchEmployerProfile(accountId))
  }
}
export const fetchEmployerProfile = (id) => (dispatch, getState) => {
  return ApolloClient.query({
    query: GetEmployerQuery,
    variables: {
      id,
    },
  })
    .then(({ data, errors }) => {
      if ((!data || !data.employer) && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch({
        type: types.SET_EMPLOYER_PROFILE_DATA,
        payload: {
          data: data.employer,
        },
      });
    })
    .catch(e => {
      dispatch(logout());
      console.log(e);
    });
};
export const fetchEmployerDetail = () => (dispatch, getState) => {
  const state = getState();
  const { employerId } = getMe(state);

  return ApolloClient.query({
    query: GetEmployerQuery,
    variables: {
      id: employerId,
    },
  })
    .then(({ data, errors }) => {
      if ((!data || !data.employer) && errors.length > 0) {
        throw new Error(errors[0].message);
      }

      dispatch({
        type: types.SET_EMPLOYER_DETAIL_DATA,
        payload: {
          data: data.employer,
        },
      });
    })
    .catch(e => {
      dispatch(logout());
      console.log(e);
    });
};
export const fetchManagerProfile = (id) => (dispatch, getState) => {
  const state = getState();
  const { accountId } = getMe(state);

  return ApolloClient.query({
    query: GetManagerQuery,
    variables: {
      id
    },
  })
    .then(({ data, errors }) => {
      if ((!data || !data.getManager) && errors.length > 0) {
        throw new Error(errors[0].message);
      }

      dispatch({
        type: types.SET_EMPLOYER_PROFILE_DATA,
        payload: {
          data: data.getManager,
        },
      });
    })
    .catch(e => {
      dispatch(logout())
      console.log(e);
    });
};

export const uploadEmployerProfileImage = file => (dispatch, getState) => {
  const { employerId, accountType, accountId } = getMe(getState());
  dispatch(setIsUpdateProfileLoading(true));
  return ApolloClient.mutate({
    mutation: (accountType === "employermanager" ? updateManagerProfileImageMutation : uploadEmployerFileToS3Mutation),
    variables:
      accountType === "employermanager" ? {
        id: accountId,
        file,
      } : {
        data: { employerId: employerId },
        isProfile: true,
        file,
      },
  })
    .then(() => {
      dispatch(fetchProfile());
      dispatch(MetaActions.successToast(`Profile photo has been updated.`));
      dispatch(setIsUpdateProfileLoading(false));
    })
    .catch(e => {
      dispatch(setIsUpdateProfileLoading(false));
      dispatch(MetaActions.errorToast(`Updating profile has failed. ${e}`));
    });
};

export const uploadCompanyLogoImage = file => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  dispatch(setIsUpdateProfileLoading(true));
  return ApolloClient.mutate({
    mutation: uploadEmployerFileToS3Mutation,
    variables: {
      data: { employerId: employerId },
      isProfile: false,
      file,
    },
  })
    .then(() => {
      dispatch(MetaActions.successToast(`Logo has been updated.`));
      dispatch(fetchEmployerDetail());
      dispatch(setIsUpdateProfileLoading(false));
    })
    .catch(e => {
      dispatch(setIsUpdateProfileLoading(false));
      dispatch(MetaActions.errorToast(`Updating logo has failed. ${e}`));
    });
};

export const updateProfile = data => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  const { accountType, accountId } = getMe(getState());
  dispatch(setIsUpdateProfileLoading(true));
  return ApolloClient.mutate({
    mutation:
      accountType === "employermanager" ? updateManagerProfileMutation : updateProfileMutation,
    variables: {
      data: {
        ...data,
        id: accountType === "employermanager" ? accountId : employerId,
      },
    },
  })
    .then(() => {
      dispatch(fetchProfile());
      if (accountType === "employer") {
        dispatch(fetchEmployerDetail());
      }
      dispatch(MetaActions.successToast(`Profile has been updated.`));
      dispatch(setIsUpdateProfileLoading(false));
      dispatch(setIsProfileDialog(false));
      dispatch(setIsCompanyDialog(false));
      dispatch(setIsBillingInfoDialog(false));
    })
    .catch(e => {
      dispatch(setIsUpdateProfileLoading(false));
      dispatch(MetaActions.errorToast(`Updating profile has failed. ${e}`));
    });
};

export const changeCompany = defaultEmployerId => (dispatch, getState) => {
  const { accountId } = getMe(getState());
  dispatch(setIsChangeCompanyLoading(true));
  return ApolloClient.mutate({
    mutation: updateManagerProfileMutation,
    variables: {
      data: {
        defaultEmployerId,
        id: accountId,
      },
    },
  })
    .then(() => {
      dispatch(MetaActions.successToast(`Company has been changed.`));
      dispatch(setIsChangeCompanyLoading(false));
      window.location.replace("/");
    })
    .catch(e => {
      dispatch(setIsChangeCompanyLoading(false));
      dispatch(MetaActions.errorToast(`Changing company has failed. ${e}`));
    });
};
