import {gql} from "@apollo/client";

export const updateTimezoneMutation = gql`
    mutation updateEmployerTimezone($data: EmployerTimezoneInput!) {
      updateEmployerTimezone(data: $data) {
            id
        }
    }
`;

export default updateTimezoneMutation;