import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Close } from "@mui/icons-material";
import {
  Autocomplete,
  Chip,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Switch
} from "@mui/material";
import { isEmpty, remove } from "lodash";
import useStyles from "views/Job/PostJob/components/styles";
import AddressAutocomplete from "components/Autocomplete/AddressAutocomplete";
import Button from "components/Button/Button";
import MainContent from "components/Containers/MainContent";
import { actions as PostJobActions, selectors as PostJobSelectors } from "store/modules/postJob";
import { actions as SettingsActions, selectors as SettingsSelectors } from "store/modules/settings";
import { actions as ProfileActions, selectors as ProfileSelectors } from "store/modules/profile";

function EditJobTemplate(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const workType = useSelector(PostJobSelectors.getWorkTypeList);
  const [gear, setGear] = useState("");
  const [skill, setSkill] = useState("");
  const employerData = useSelector(ProfileSelectors.getEmployerDetail);
  const canPostPrivateJob = employerData.canPostPrivateJob;
  const skills = useSelector(PostJobSelectors.getSkills);
  const templateDetail = useSelector(SettingsSelectors.getJobTemplateDetail);
  const address = useSelector(SettingsSelectors.getJobTemplateAddress);
  const isLoading = useSelector(SettingsSelectors.getIsUpdateTemplateLoading);
  const params = new URLSearchParams(props.history.location.search);
  const jobTemplateId = params.get("id");
  const type = params.get("type");
  const [tag, setTag] = useState("");
  const [duplicateTemplateName, setDuplicateTemplateName] = useState('');
  const [validDuplicateTemplateName, setValidDuplicateTemplateName] = useState(type === 'duplicate' ? false : true);

  useEffect(() => {
    dispatch(PostJobActions.fetchWorkTypes());
    if (jobTemplateId) {
      dispatch(SettingsActions.fetchJobTemplateById(Number(jobTemplateId)));
    }
    dispatch(ProfileActions.fetchEmployerDetail());
  }, []);

  const handleChangeTemplateName = e => {
    if (e.target.value.length <= 50) {
      setDuplicateTemplateName(e.target.value);
      setValidDuplicateTemplateName(true)
      templateDetail.templateName = e.target.value;
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    }
  };
  const handleChangeUnit = e => {
    if (e.target.value.length <= 10) {
      if (e.target.value.match(/[0-9]/g)) {
        templateDetail.unit = e.target.value;
        dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
      }
      else {
        templateDetail.unit = "";
        dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
      }
    }
  };
  const handleChangeFloor = e => {
    if (e.target.value.length <= 10) {
      templateDetail.floor = e.target.value;
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    }
  };
  const handleChangeInstruction = e => {
    if (e.target.value.length <= 100) {
      templateDetail.locationInstruction = e.target.value;
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    }
  };
  const handleChangeWorkType = (e, newValue) => {
    templateDetail.workType = newValue;
    templateDetail.workTypeId = newValue.value
    dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
  };
  const handleChangeDescription = e => {
    if (e.target.value.length <= 250) {
      templateDetail.description = e.target.value;
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    }
  };
  const handleChangeGear = e => {
    if (e.target.value.length <= 50) {
      setGear(e.target.value);
    }
  };
  const addGear = () => {
    if (templateDetail.gears.indexOf(gear) < 0) {
      const seleted = [...templateDetail.gears];
      seleted.push(gear);
      templateDetail.gears = seleted;
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
      setGear("");
    }
  };
  const handleDelete = value => {
    const seleted = [...templateDetail.gears];
    remove(seleted, n => n === value);
    templateDetail.gears = seleted;
    dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
  };
  const handleChangeTag = (e) => {
    if (e.target.value.length <= 50) {
      setTag(e.target.value);
    }
  }
  const addTag = () => {
    if (templateDetail.tags.indexOf(tag) < 0) {
      const seleted = [...templateDetail.tags];
      seleted.push(tag);
      templateDetail.tags = seleted;
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
      setTag("");
    }
  };
  const handleDeleteTags = value => {
    const seleted = [...templateDetail.tags];
    remove(seleted, n => n === value);
    templateDetail.tags = seleted;
    dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
  };
  const handleChangeSkill = e => {
    if (e.target.value.length <= 50) {
      setSkill(e.target.value);
    }
  };
  const addSkill = () => {
    if (templateDetail.skills.indexOf(skill) < 0) {
      if (skill.replace(/^\s+|\s+$/gm, '')) {
        const seleted = [...templateDetail.skills];
        seleted.push(skill);
        templateDetail.skills = seleted;
        dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
        setSkill("");
      }
    }
  };
  const handleDeleteSkill = value => {
    const seleted = [...templateDetail.skills];
    remove(seleted, n => n === value);
    templateDetail.skills = seleted;
    dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
  };
  const onIsPrivateChange = (isPrivate) => {
    templateDetail.isPrivate = isPrivate;
    dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    if (isPrivate === false) {
      templateDetail.tags = [];
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    }
  };
  const onAddressChange = (selectedLocation, location) => {
    if (location) {
      templateDetail.street = location.street;
      templateDetail.city = location.city;
      templateDetail.region = location.region;
      templateDetail.latitude = location.lat;
      templateDetail.longitude = location.lng;
      templateDetail.country = location.country;
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    } else {
      templateDetail.street = '';
      templateDetail.city = '';
      templateDetail.region = '';
      templateDetail.latitude = '';
      templateDetail.longitude = '';
      templateDetail.country = '';
      dispatch(SettingsActions.setTemplateDetail({ ...templateDetail }));
    }
  };
  const updateTemplate = () => {
    if (type === 'duplicate') {
      dispatch(SettingsActions.duplicateTemplate())
        .then(data => {
          if (data) {
            props.history.push(`/settings`);
          }
        });
    }
    else {
      dispatch(SettingsActions.updateTemplate())
        .then(data => {
          if (data) {
            props.history.push(`/settings`);
          }
        });
    }
  }

  const isValidTemplate = () => {
    return (
      templateDetail &&
      templateDetail.templateName &&
      templateDetail.workType &&
      templateDetail.description &&
      address &&
      address.label &&
      validDuplicateTemplateName &&
      templateDetail.gears.length > 0 &&
      templateDetail.skills.length > 0 &&
      !(templateDetail.isPrivate && templateDetail.tags.length == 0)
    );
  }

  return (
    <React.Fragment>
      {!isEmpty(templateDetail) && (
        <MainContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <h3>Template Name</h3>
              <div>
                <TextField
                  id="templateName"
                  name="templateName"
                  placeholder="Template Name"
                  value={type === 'duplicate' ? duplicateTemplateName : templateDetail.templateName}
                  onChange={handleChangeTemplateName}
                  fullWidth
                  margin="none"
                />
              </div>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <h3>Type Of Work</h3>
              <div>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={workType}
                  value={templateDetail && templateDetail.workType && templateDetail.workType.label}
                  // sx={{ width: 300 }}
                  onChange={handleChangeWorkType}
                  fullWidth
                  renderInput={params => <TextField {...params} />}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <h3>Description</h3>
              <div className={classes.gearTextBox}>
                <TextField
                  id="description"
                  name="description"
                  // label="Description"
                  placeholder="Write here"
                  value={templateDetail.description}
                  onChange={handleChangeDescription}
                  fullWidth
                  margin="none"
                  multiline
                  minRows={4}
                />
              </div>
            </Grid>
          </Grid>
          <br />
          <Divider />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <h3>Required gear</h3>
              <div className={classes.gearTextBox}>
                <TextField
                  id="gear"
                  name="gear"
                  placeholder="Enter required gear"
                  value={gear}
                  onChange={handleChangeGear}
                  fullWidth
                  margin="none"
                  onKeyPress={e => {
                    if (e.key === "Enter" && e.target.value) {
                      addGear();
                    }
                  }}
                />
                <Button
                  // size="small"
                  disabled={!gear}
                  onClick={addGear}
                  label={"Add"}
                />
              </div>
              {templateDetail &&
                templateDetail.gears &&
                templateDetail.gears.length > 0 && (
                  <div className={classes.selectedSection}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                      justifyContent={"space-between"}
                    >
                      Selected ({templateDetail.gears.length})
                    </Typography>
                    {templateDetail.gears.map((n, index) => (
                      <Chip
                        key={index}
                        label={n}
                        variant="outlined"
                        onDelete={() => handleDelete(n)}
                      />
                    ))}
                  </div>
                )}
            </Grid>
            <Grid item xs={12} md={6}>
              <h3>Suggested skills {canPostPrivateJob && 'and tags'}</h3>

              {canPostPrivateJob && (
                <div style={{ marginBottom: "20px" }}>
                  <div className={classes.tags}>
                    <h6 style={{ marginRight: "3px", marginTop: '12px' }}>This Job is private</h6>
                    <Switch
                      id="isPrivate"
                      defaultChecked={templateDetail.isPrivate}
                      onChange={(e) => onIsPrivateChange(e.target.checked)}
                      name="isPrivate"
                      color="secondary"
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </div>

                  {templateDetail.isPrivate && (
                    <>
                      <div style={{ marginTop: "10px" }}>Tags</div>
                      <div className={classes.gearTextBox}>
                        <TextField
                          id="tag"
                          name="tag"
                          placeholder="Enter tags individually"
                          value={tag}
                          onChange={handleChangeTag}
                          fullWidth
                          margin="none"
                          onKeyPress={e => {
                            if (e.key === "Enter" && e.target.value) {
                              addTag();
                            }
                          }}
                        />
                        <Button
                          // size="small"
                          disabled={!tag}
                          onClick={addTag}
                          label={"Add"}
                        />
                      </div>

                      {templateDetail &&
                        templateDetail.tags &&
                        templateDetail.tags.length > 0 && (
                          <div className={classes.selectedSection}>
                            <Typography
                              sx={{ fontSize: 14 }}
                              color="text.secondary"
                              gutterBottom
                              justifyContent={"space-between"}
                            >
                              Selected ({templateDetail.tags.length})
                            </Typography>
                            {templateDetail.tags.map((n, index) => (
                              <Chip
                                key={index}
                                label={n}
                                variant="outlined"
                                onDelete={() => handleDeleteTags(n)}
                              />
                            ))}
                          </div>
                        )}

                    </>
                  )}
                </div>
              )}

              {canPostPrivateJob && <div style={{ marginTop: "10px" }}>Skills</div>}
              <div className={classes.gearTextBox}>
                <TextField
                  id="skill"
                  name="skill"
                  placeholder="Enter skills individually"
                  value={skill}
                  onChange={handleChangeSkill}
                  fullWidth
                  margin="none"
                  onKeyPress={e => {
                    if (e.key === "Enter" && e.target.value) {
                      addSkill();
                    }
                  }}
                />
                <Button
                  // size="small"
                  disabled={!skill}
                  onClick={addSkill}
                  label={"Add"}
                />
              </div>
              <div className={classes.selectedSection}>
                {templateDetail &&
                  templateDetail.skills &&
                  templateDetail.skills.length > 0 && (
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                      gutterBottom
                      justifyContent={"space-between"}
                    >
                      Added Skills
                    </Typography>
                  )}
                {templateDetail.skills &&
                  templateDetail.skills.map((n, index) => (
                    <div key={index} className={classes.addedSkillList}>
                      <div className="content">{n}</div>
                      <IconButton
                        className="closeButton"
                        color="primary"
                        onClick={() => handleDeleteSkill(n)}
                        aria-label="delete"
                        component="span"
                      >
                        <Close />
                      </IconButton>
                    </div>
                  ))}
              </div>
            </Grid>
          </Grid>

          <br />
          <Divider />
          <h3>Job Location</h3>
          <div className={classes.gearTextBox}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <AddressAutocomplete
                  id="address"
                  name="address"
                  label="Address"
                  value={address}
                  margin="none"
                  onChange={onAddressChange}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="unit"
                  name="unit"
                  label="Unit #"
                  value={templateDetail.unit}
                  onChange={handleChangeUnit}
                  fullWidth
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="floor"
                  name="floor"
                  label="Floor #"
                  value={templateDetail.floor}
                  onChange={handleChangeFloor}
                  fullWidth
                  margin="none"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  id="locationInstruction"
                  name="locationInstruction"
                  label="Additional instructions"
                  value={templateDetail.locationInstruction}
                  onChange={handleChangeInstruction}
                  fullWidth
                  margin="none"
                />
              </Grid>
            </Grid>
          </div>
          <br />
          <Button
            className={classes.continueButton}
            size="small"
            disabled={!isValidTemplate()}
            isLoading={isLoading}
            onClick={updateTemplate}
            label={"Save"}
          />
        </MainContent>
      )}
    </React.Fragment>
  );
}

export default EditJobTemplate;
