import React, { useState } from "react";
import { useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import { find } from "lodash";
import Dialog from "components/Dialog/Dialog";
import Rating from "components/Rating/Rating";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";

function RatingDialog(props) {
  const { open, onClose, onCancel, position, onConfirm } = props;
  const [rating, setValue] = useState(position.jobRating ? position.jobRating.ratings : []);
  const [comment, setComment] = useState(position.jobRating ? position.jobRating.workerRatingComment : '');
  const ratingOption = useSelector(JobDetailSelectors.getRatingOption);

  const onRatingChange = (value, ratingOptionId) => {
    let isUpdate = false;
    rating.forEach(element => {
      if (element.ratingOptionId === ratingOptionId) {
        isUpdate = true;
        element.rating = value;
      }
    });
    if (!isUpdate) {
      rating.push({ rating: value, ratingOptionId })
    }
    setValue([...rating]);
  };

  const getRating = (ratingOptionId) => {
    const ratingObj = find(rating, { ratingOptionId })
    if (ratingObj)
      return ratingObj
    return { rating: 3 }
  }

  const handleChangeComment = (e) => {
    if (e.target.value.length <= 250) {
      setComment(e.target.value)
    }
  }

  return (
    <Dialog
      open={open}
      title={`Rate ${position.fullName}?`}
      description="Rate workers based off of their performance on the job."
      onClose={onClose}
      onConfirm={() => onConfirm(rating, comment)}
      onCancel={onCancel}
      alertType="question"
      disableBackdropClick
      modalType="scrollable"
      icon="star">
      {ratingOption && ratingOption.length > 0 && (
        ratingOption.map((n) => (
          <React.Fragment key={n.id}>
            <h5 style={{ marginBottom: 0 }}>{n.option}</h5>
            <Rating size={25} value={getRating(n.id).rating} onChange={(e) => onRatingChange(e, n.id)} />
          </React.Fragment>
        ))
      )}
      <TextField
        fullWidth
        value={comment}
        placeholder="Comment"
        label="Comment"
        onChange={handleChangeComment}
        margin="normal"
      />
    </Dialog>
  );
}

export default RatingDialog;
