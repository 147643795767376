import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "components/Dialog/Dialog";

function DisableDialog(props) {
  const { open, onClose, onCancel, onConfirm, fullName } = props;
  const [disableReason, setDisableReason] = useState();

  const onChange = event => {
    if (event.target.value.length <= 100) {
      setDisableReason(event.target.value);
    }
  };

  const handleDisable = () => {
    onConfirm(disableReason);
    setDisableReason("");
  };

  return (
    <Dialog
      open={open}
      title={`Disable ${fullName}?`}
      onClose={onClose}
      onConfirm={handleDisable}
      onCancel={onCancel}
      alertType="warning"
      icon="not_interested"
      disabled={!disableReason}>
      <TextField
        fullWidth
        placeholder="Reason"
        value={disableReason}
        onChange={onChange}
        margin="normal" />
    </Dialog>
  );
}

export default DisableDialog;
