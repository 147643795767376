import React from "react";
import moment from "moment-timezone";
import {
    convertUTCToLocal,
    convertUTCToTimezoneWithOffset,
    displayLocalDateTime,
    formattedTimeWithOffset,
} from "utils/time";
import JobListItemMenu from "../components/JobListItemMenu";

export const sharedColumns = [
  {
    Header: "ID",
    id: "id",
    accessor: "id",
    className: "-cursor-pointer",
    styles: {
      width: '100px'
    },
  },
  {
    Header: "Work Type",
    id: "label",
    accessor: "workType.label",
    className: "-cursor-pointer",
    Cell: ({ row: { original } }) => {
      // console.log("original", original.workType)
      return original.workType ? original.workType.label : ''
    }
  },
  {
    Header: "Start Date",
    id: "startUtc",
    accessor: "startUtc",
    disableFilters: true,
    styles: {
      minWidth: '125px'
    },
    Cell: ({ row: { original } }) => {
      return (
        <div>
          {convertUTCToTimezoneWithOffset(original.startUtc, original.timezone, "MMM Do, YYYY")}
          <br />
          {displayLocalDateTime(original.startUtc, original.timezone, "MMM Do, YYYY")}
        </div>
      );
    },
    sortable: false,
    className: "-cursor-pointer",
  },
  {
    Header: "Shift",
    id: "Shift",
    disableSortBy: true,
    Cell: ({ row: { original } }) => {
      return formattedTimeWithOffset(original.startUtc, original.endUtc, original.timezone);
    },
    accessor: ({ startUtc, endUtc }) => {
      const startTime = moment(startUtc).format("h:mm a");
      const endTime = moment(endUtc).format("h:mm a");
      return `${startTime} - ${endTime}`;
    },
    disableFilters: true,
    className: "-cursor-pointer",
    styles: {
      minWidth: '150px'
    },
  },
  {
    Header: "Skills",
    id: "skills",
    accessor: ({ skills }) => skills,
    maxWidth: 200,
    filteredRows: row => row,
    className: "-cursor-pointer",
    styles: {
      minWidth: '100px',
      textAlign: "left"
    },
    Cell: ({ row: { original } }) => {
      return original.skills && original.skills.map(n => <li key={n}>{n}</li>)
    }
  },
  {
    Header: "Location",
    id: "city",
    accessor: ({ city, region }) => `${city}, ${region}`,
    maxWidth: 200,
    filteredRows: row => row,
    className: "-cursor-pointer",
    styles: {
      minWidth: '100px'
    },
  },
  {
    Header: "Workers",
    id: "peopleNeeded",
    disableFilters: true,
    sortable: false,
    width: 100,
    accessor: ({ peopleNeeded, positionsFilled }) => `${positionsFilled} / ${peopleNeeded}`,
    style: { cursor: "pointer", textAlign: "center" },
    headerStyle: { textAlign: "center" },
  },
];

export const createActionColumn = ({
  canDelete = false,
  canEnable = false,
} = {}) => {
  return {
    // Header: (
    //   <Tooltip title={`Toggle Filters`} placement="bottom">
    //     <FilterList
    //       style={{ float: "right", marginTop: 5 }}
    //       color={showFilters ? "secondary" : "primary"}
    //       onClick={() => toggleFilters()}
    //       fontSize="large"
    //     />
    //   </Tooltip>
    // ),
    id: "actions",
    disableSortBy: true,
    filterable: false,
    width: 70,
    // eslint-disable-next-line react/display-name, react/prop-types
    Cell: ({ row: { original } }) => {
      let ensureCanEnable = canEnable;
      if (convertUTCToLocal(original.startUtc).isBefore(moment())) {
        ensureCanEnable = false;
      }

      return (
        <JobListItemMenu jobId={original.id} canDelete={canDelete} canEnable={ensureCanEnable} />
      );
    },
  };
};

export const checkInOutColumn = {
  Header: "Ins / Outs",
  id: "inAndOut",
  disableFilters: true,
  sortable: false,
  width: 100,
  accessor: ({ checkedInCount, checkedOutCount }) => `${checkedInCount} / ${checkedOutCount}`,
  className: "-cursor-pointer",
};

export const confirmedColoumn = {
  Header: "Confirmed",
  id: "confirmed",
  disableFilters: true,
  sortable: false,
  styles: {
    minWidth: '70px'
  },
  accessor: ({ positions, positionsFilled }) => {
    const confirmedCount = (positions || []).filter(({ isConfirmed }) => isConfirmed === true)
      .length;
    return `${confirmedCount}/${positionsFilled}`;
  },
  style: { cursor: "pointer", textAlign: "center" },
  headerStyle: { textAlign: "center" },
};

export const reconfirmedColoumn = {
  Header: "Re confirmed",
  id: "reconfirmed",
  disableFilters: true,
  sortable: false,
  styles: {
    minWidth: '100px'
  },
  accessor: ({ positions, positionsFilled }) => {
    const reconfirmedCount = (positions || []).filter(({ isReconfirmed }) => isReconfirmed === true)
      .length;
    return `${reconfirmedCount}/${positionsFilled}`;
  },
  style: { cursor: "pointer", textAlign: "center" },
  headerStyle: { textAlign: "center" },
};
