import { makeStyles } from "@mui/styles";

export const tabStyles = makeStyles(({ spacing, shape, palette }) => ({
  tabHeader: {},
  tabLabel: {
    lineHeight: 1.5,
    marginRight: `${spacing(2)}`,
    "& + span": {
      fontWeight: 500,
    },
  },
  tabRoot: {
    marginRight: `${spacing(3)}`,
    padding: `${spacing(1)} ${spacing(2)}`,
    borderRadius: `${shape.borderRadius}px`,
    border: '1px solid #f7f7fb',
    opacity: 1,
    minWidth: `${spacing(15)}`,
    "&:hover": {
      border: `1px solid ${palette.secondary.main}`,
      padding: `${spacing(1) - 1} ${spacing(2) - 1}`,
    },
  },
  tabSelected: {
    padding: `${spacing(1)} ${spacing(2)}`,

    border: `2px solid ${palette.secondary.main}`,
    backgroundColor: '#FFF',
    "&:hover": {
      border: `2px solid ${palette.secondary.main}`,
      padding: `${spacing(1)} ${spacing(2)}`,
    },
    "& $tabHeader, & $tabLabel": {
      color: palette.secondary.main,
    },
  },
  tabTotalWrapper: {
    padding: `${spacing(2)} ${spacing(3)}`,
    borderRight: `2px solid ${palette.action.disabled}`,
    maxWidth: `${spacing(26)}`,
    "& span": {
      color: palette.text.primary,
    },
  },
  tabIndicator: {
    display: "none !important",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: `0px`,
  },
  progress: {
    color: palette.secondary.light,
    position: "absolute",
    top: "50%",
    left: "45%",
  },
}));
