import * as pagination from "utils/graphql-pagination";
import { selectors as MetaSelectors } from "store/modules/meta";
import { paginationSettings } from "./constants";
import { networkTypes } from "./types";

export const createLoadingSelector = MetaSelectors.createLoadingSelector("jobs");
export const createErrorMessageSelector = MetaSelectors.createErrorMessageSelector("jobs");

export const getJobTimeframeCountsLoader = createLoadingSelector([
  networkTypes.FETCH_TIMEFRAME_STATS,
]);

export const getJobListStatsLoader = createLoadingSelector([networkTypes.FETCH_LIST_STATS]);

export const getTimeframeStats = state => state.jobs.timeframeStats;
export const getListStats = (timeframe) => state => state.jobs.listStats[timeframe];
export const getListData = ({ timeframe, status, part }) => (state) => {
  if (!status) {
    return state.jobs[timeframe][part];
  }
  return state.jobs[timeframe][status][part];
};

export const getTodayTotalPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.todayTotal)];
};

export const getTodayActivePaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.todayActive)];
};

export const getTodayUnfilledPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.todayUnfilled)];
};

export const getTodayFilledPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.todayFilled)];
};

export const getTodayCompletedPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.todayCompleted)];
};

export const getTomorrowTotalPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.tomorrowTotal)];
};

export const getTomorrowUnfilledPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.tomorrowUnfilled)];
};

export const getTomorrowFilledPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.tomorrowFilled)];
};

export const getUpcomingTotalPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.upcomingTotal)];
};

export const getUpcomingUnfilledPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.upcomingUnfilled)];
};

export const getUpcomingFilledPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.upcomingFilled)];
};

export const getHistoryPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.history)];
};

export const getDraftsPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.drafts)];
};

export const getQueuedPaginationData = state => {
  return state.jobs[pagination.getPageInfoAttr(paginationSettings.queued)];
};
