// Define a default UI for filtering
import React from 'react';
import { TextField } from '@mui/material';

function DefaultColumnFilter({
  // eslint-disable-next-line react/prop-types
  column: { filterValue, setFilter },
}) {
  return (
    <TextField
      name="name"
      size="small"
      variant="standard"
      className="filter-textfield"
      value={filterValue || ''}
      autoComplete="off"
      onChange={e => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder="Search..."
    />
  );
}
export default DefaultColumnFilter;
