import { ApolloClient, InMemoryCache, concat, ApolloLink } from "@apollo/client";
import "whatwg-fetch";
import { createUploadLink } from "apollo-upload-client";
import { store } from "../store";

window.global = window;
// @ts-ignore
window.Buffer = window.Buffer || require('buffer').Buffer;


const uri = process.env.REACT_APP_API_ENDPOINT || `https://ebs-app.dev.gfca.aws.grizzlyforce.com/graphql`;
const defaultOptions = {
  watchQuery: { errorPolicy: "ignore" },
  query: { errorPolicy: "all", fetchPolicy: "no-cache" },
  mutate: { errorPolicy: "all" },
  connectToDevTools: true,
};

export const getApolloClient = (options = {}) => {
  const UploadLink = createUploadLink({ uri });
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const { accessToken } = store.getState().login;
      return {
        headers: {
          ...headers,
          authorization: accessToken ? `Bearer ${accessToken}` : "",
        },
      };
    })
    return forward(operation);
  });
  const links = concat(authLink, UploadLink);
  const apolloConfig = {
    name: "Grizzly-Employer-Client",
    version: "1.0",
    queryDeduplication: false,
    link: links,
    cache: new InMemoryCache(),
    connectToDevTools: true,
    defaultOptions: {
      ...defaultOptions,
      ...options,
    },
  };
  return new ApolloClient(apolloConfig);
};

export default getApolloClient();
