import ApolloClient from "utils/apollo";
import { flattenGraphQLArray } from "utils/graphql";
import { convertUTCToTimezoneWithOffset, getTimeZone } from "utils/time";
import { actions as MetaActions } from "store/modules/meta";
import { getMe } from "../login/selectors";
import BanWorkerMutation from "./graphql/mutations/ban-worker";
import CancelJobMutation from "./graphql/mutations/cancel-job";
import CancelRecurringJobMutation from "./graphql/mutations/cancel-recurring-job";
import CheckInAllMutation from "./graphql/mutations/checkin-all-workers";
import CheckInMutation from "./graphql/mutations/checkin-worker";
import CheckOutAllMutation from "./graphql/mutations/checkout-all-workers";
import CheckOutMutation from "./graphql/mutations/checkout-worker";
import ClearExceptionMutation from "./graphql/mutations/clear-exception";
import DecreaseJobPositionsMutation from "./graphql/mutations/decrease-positions";
import DeleteJobMutation from "./graphql/mutations/delete-job";
import IncreaseJobPositionsMutation from "./graphql/mutations/increase-positions";
import NoShowPositionMutation from "./graphql/mutations/noshow-worker";
import PreferWorkerMutation from "./graphql/mutations/prefer-worker";
import RateAllWorkersMutation from "./graphql/mutations/rating-all-workers";
import RatingWorkerMutation from "./graphql/mutations/rating-worker";
import FetchJobQuery from "./graphql/queries/fetch-job";
import FetchJobWorkersQuery from "./graphql/queries/fetch-job-workers";
import FetchPositionsQuery from "./graphql/queries/fetch-positions";
import FetchRatingOptionQuery from "./graphql/queries/fetch-rating-option";
import FetchRecurringJobQuery from "./graphql/queries/fetch-recurring-job";
import types from "./types";

const setLoadingState = key => value => ({
  type: types.SET_LOADING_STATE,
  payload: { key, value },
});

const setIsPageLoading = setLoadingState("page");
const setIsUpdatingPositions = setLoadingState("updatingPositions");

export const setIsCancelJobDialogState = value => ({
  type: types.SET_IS_CANCEL_JOB_DIALOG_STATE,
  payload: { value },
});

const reset = () => ({
  type: types.RESET,
});

const setJob = job => ({
  type: types.SET_JOB,
  payload: { job },
});

export const removePosition = workerId => ({
  type: types.REMOVE_POSITION,
  payload: { workerId },
});

const setJobWorkers = workers => ({
  type: types.SET_JOB_WORKERS,
  payload: { workers },
});

export const fetchJobWorkers = (jobId, employerId) => dispatch => {
  return ApolloClient.query({
    query: FetchJobWorkersQuery,
    variables: { jobId, employerId },
  }).then(({ data }) => {
    const workers = flattenGraphQLArray(data.workers);
    dispatch(setJobWorkers(workers));
  });
};

export const fetchJob = (id, isPageLoading = true) => dispatch => {
  dispatch(setIsPageLoading(isPageLoading));
  return ApolloClient.query({
    query: FetchJobQuery,
    variables: { id: id, timezone: getTimeZone() },
  })
    .then(({ data: { job } }) => {
      dispatch(setJob(job));
      return dispatch(fetchJobWorkers(job.id, job.employerId));
    })
    .then(() => {
      dispatch(setIsPageLoading(false));
    })
    .catch(e => {
      dispatch(setIsPageLoading(false));
      dispatch(MetaActions.errorToast(`Could not get job information: ${e.message}`));
    });
};


export const updatePositions = positions => ({
  type: types.UPDATE_POSITIONS,
  payload: { positions },
});

export const updatePosition = position => ({
  type: types.UPDATE_POSITION,
  payload: { position },
});

export const fetchPositions = () => (dispatch, getState) => {
  const { id: jobId, employerId } = getState().jobDetails;
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.query({
    query: FetchPositionsQuery,
    variables: {
      jobId,
      employerId,
    },
  })
    .then(({ data }) => {
      dispatch(updatePositions(data.job.positions));
      dispatch(setIsUpdatingPositions(false));
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.errorToast(`Failed to update positions. (${e.message})`));
    });
};

const removeJobFactory = (mutation, type) => (jobId) => (dispatch, getState) => {
  const { positionsFilled } = getState().jobDetails;
  const { employerId } = getMe(getState());
  dispatch(setIsPageLoading(true));
  return ApolloClient.mutate({
    mutation,
    variables: {
      data: {
        employerId,
        jobId: Number(jobId),
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(reset());
        dispatch(setIsPageLoading(false));
        let message;
        if (type === "delete") {
          message = "Job was successfully deleted.";
        }

        if (type === "cancel") {
          message = "This job will be cancelled and deleted.";
          if (positionsFilled) {
            message = `${message} ${positionsFilled} worker(s) will be notified.`;
          }
        }
        dispatch(MetaActions.successToast(message));
        return data
      }
    })
    .catch(e => {
      dispatch(setIsPageLoading(false));
      dispatch(MetaActions.errorToast(`Failed to ${type} job. (${e.message})`));
    });
};

export const deleteJob = removeJobFactory(DeleteJobMutation, "delete");
export const cancelJob = removeJobFactory(CancelJobMutation, "cancel");

const setRecurringJobList = (data) => ({
  type: types.SET_RECURRING_JOB_LIST,
  payload: data
});

export const fetchJobRecurringJob = (jobDetail) => dispatch => {
  return ApolloClient.query({
    query: FetchRecurringJobQuery,
    variables: {
      jobId: jobDetail.id,
      isFuture: true
    },
  }).then(({ data: { recurringJobsByJobId } }) => {
    dispatch(setRecurringJobList(recurringJobsByJobId));
  }).catch(e => {
    dispatch(MetaActions.errorToast(`Could not get data: ${e.message}`));
  });
}

export const cancelRecurringJob = (recurringJobIds, jobId) => (dispatch, getState) => {
  const { positionsFilled } = getState().jobDetails;
  const { employerId } = getMe(getState());
  dispatch(setIsPageLoading(true));
  return ApolloClient.mutate({
    mutation: CancelRecurringJobMutation,
    variables: {
      data: {
        employerId,
        jobId: Number(jobId),
        recurringJobIds: [...recurringJobIds]
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(setIsPageLoading(false));
        dispatch(setIsCancelJobDialogState(false));
        let message;
        message = "This job will be cancelled and deleted.";
        if (positionsFilled) {
          message = `${message} ${positionsFilled} worker(s) will be notified.`;
        }
        dispatch(MetaActions.successToast(message));
        return data
      }

    })
    .catch(e => {
      dispatch(setIsPageLoading(false));
      dispatch(setIsCancelJobDialogState(false));
      dispatch(MetaActions.errorToast(`${e.message}`));
    });
}

export const updatePositionData = field => (positionId, value) => {
  return {
    type: types.UPDATE_POSITION_DATA,
    payload: { positionId, field, value },
  };
};

export const batchUpdatePositionData = field => value => ({
  type: types.BATCH_UPDATE_POSITION_DATA,
  payload: { field, value },
});

export const resetPositionUpdates = () => ({
  type: types.RESET_POSITION_UPDATES,
});

export const updateBanList = workerId => ({
  type: types.UPDATE_BAN_LIST,
  payload: { workerId },
});

export const updatePreferList = workerId => ({
  type: types.UPDATE_PREFER_LIST,
  payload: { workerId },
});

export const rateAllWorkers = () => (dispatch, getState) => {
  const { id, positionUpdates } = getState().jobDetails;
  const { employerId } = getMe(getState());
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: RateAllWorkersMutation,
    variables: {
      data: {
        jobId: id,
        workerRatings: positionUpdates.map(({ workerId, ratings }) => ({ workerId, workerRatingComment: '', ratings: ratings })),
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(setIsUpdatingPositions(false));
        dispatch(fetchJobWorkers(id, employerId))
        dispatch(MetaActions.successToast("Workers have been rated!"));
      }
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      // Failed so reset to actual data
      dispatch(resetPositionUpdates());
      dispatch(MetaActions.errorToast(`Failed to rate workers. (${e.message})`));
    });
};

export const checkinAllWorkers = () => (dispatch, getState) => {
  const { id, positionUpdates, employerId } = getState().jobDetails;
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: CheckInAllMutation,
    variables: {
      employerId,
      data: {
        jobId: id,
        times: positionUpdates.map(({ workerId, startShift, startShiftUtc, id }) => ({
          positionId: id,
          workerId,
          startShift,
          startShiftUtc,
        })),
      },
    },
  })
    .then(({ data }) => {
      dispatch(updatePositions(data.checkInAllWorkers));
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.successToast("Workers have been checked in."));
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      // Failed so reset to actual data
      dispatch(resetPositionUpdates());
      dispatch(MetaActions.errorToast(`Failed to check in workers. (${e.message})`));
    });
};

export const checkoutAllWorkers = () => (dispatch, getState) => {
  const { id, positionUpdates, employerId } = getState().jobDetails;
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: CheckOutAllMutation,
    variables: {
      employerId,
      data: {
        jobId: id,
        times: positionUpdates.map(({ workerId, endShift, breakMins, endShiftUtc, id }) => ({
          positionId: id,
          workerId,
          endShift,
          breakMins,
          endShiftUtc,
        })),
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        dispatch(resetPositionUpdates());
        throw new Error(errors[0].message);
      }
      dispatch(updatePositions(data.checkOutAllWorkers));
      dispatch(fetchJob(id, false));
      dispatch(MetaActions.successToast("Workers have been checked out."));
      dispatch(setIsUpdatingPositions(false));
    })
    .catch(e => {
      console.log(e)
      dispatch(setIsUpdatingPositions(false));
      // Failed so reset to actual data
      dispatch(resetPositionUpdates());
      dispatch(MetaActions.errorToast(`Failed to check out workers. (${e.message})`));
    });
};

export const increasePositions = increaseBy => (dispatch, getState) => {
  const { id: jobId, employerId } = getState().jobDetails;
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: IncreaseJobPositionsMutation,
    variables: {
      data: {
        employerId,
        jobId,
        increaseBy,
      },
    },
  })
    .then(({ data: { increasePositions } }) => {
      dispatch(setJob(increasePositions));
      return dispatch(fetchJobWorkers(increasePositions.id, increasePositions.employerId));
    })
    .then(() => {
      const { peopleNeeded } = getState().jobDetails;
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.successToast(`Job now has ${peopleNeeded} position(s) available.`));
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.errorToast(`Could not increase positions for job. (${e.message})`));
    });
};

export const decreasePositions = decreaseBy => (dispatch, getState) => {
  const { id: jobId, employerId } = getState().jobDetails;
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: DecreaseJobPositionsMutation,
    variables: {
      data: {
        employerId,
        jobId,
        decreaseBy,
      },
    },
  })
    .then(({ data: { decreasePositions } }) => {
      dispatch(setJob(decreasePositions));
      return dispatch(fetchJobWorkers(decreasePositions.id, decreasePositions.employerId));
    })
    .then(() => {
      const { peopleNeeded } = getState().jobDetails;
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.successToast(`Job now has ${peopleNeeded} position(s) available.`));
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.errorToast(`Could not decrease positions for job. (${e.message})`));
    });
};

export const checkinWorker = (positionId) => (dispatch, getState) => {
  const { id, employerId, positions, positionUpdates, timezone } = getState().jobDetails;
  const { fullName } = positions.find(info => info.id === positionId);
  const { startShift, startShiftUtc, workerId } = positionUpdates.find(info => info.id === positionId);
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: CheckInMutation,
    variables: {
      employerId,
      data: {
        jobId: id,
        workerId,
        startShift,
        startShiftUtc,
        positionId
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(updatePosition(data.checkInWorker));
      dispatch(setIsUpdatingPositions(false));
      dispatch(
        MetaActions.successToast(
          `${fullName} has been checked in at ${convertUTCToTimezoneWithOffset(startShiftUtc, timezone, 'MMM Do h:mm a')}.`
        )
      );
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      // Failed so reset to actual data
      dispatch(resetPositionUpdates());
      dispatch(MetaActions.errorToast(`Failed to check in ${fullName}. (${e.message})`));
    });
};

export const checkoutWorker = positionId => (dispatch, getState) => {
  const { id, positionUpdates, employerId, positions, timezone } = getState().jobDetails;
  const { fullName } = positions.find(info => info.id === positionId);
  const { endShift, endShiftUtc, breakMins, workerId } = positionUpdates.find(info => info.id === positionId);
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: CheckOutMutation,
    variables: {
      employerId,
      data: {
        jobId: id,
        workerId,
        endShift,
        endShiftUtc,
        breakMins,
        positionId,
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(updatePosition(data.checkOutWorker));
      dispatch(fetchJob(id, false));
      dispatch(setIsUpdatingPositions(false));
      dispatch(
        MetaActions.successToast(
          `${fullName} has been checked out at ${convertUTCToTimezoneWithOffset(endShiftUtc, timezone, 'MMM Do h:mm a')}.`,
        ),
      );
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      // Failed so reset to actual data
      dispatch(resetPositionUpdates());
      dispatch(MetaActions.errorToast(`Failed to check out ${fullName}. (${e.message})`));
    });
};

export const noShowWorker = (workerId, ban) => (dispatch, getState) => {
  const { id, positions, employer } = getState().jobDetails;
  const { fullName } = positions.find(info => info.workerId === workerId);
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: NoShowPositionMutation,
    variables: {
      data: {
        jobId: id,
        workerId,
        ban,
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(fetchPositions());
        dispatch(setIsUpdatingPositions(false));
        if (ban) {
          dispatch(
            MetaActions.successToast(
              `${fullName} has been removed, suspended, and banned from ${employer.companyName}'s job site`
            )
          );
        } else {
          // dispatch(suspendWorker(workerId, 7, `Flagged as a no show. Job [${id}]`));
          dispatch(
            MetaActions.successToast(
              `${fullName} has been removed from the job and suspended for 7 days`
            )
          );
        }
      }
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      // Failed so reset to actual data
      dispatch(resetPositionUpdates());
      dispatch(MetaActions.errorToast(`Failed to flag ${fullName} as a no show. (${e.message})`));
    });
};

export const preferWorker = workerId => (dispatch, getState) => {
  const { positions, employerId, employer, id } = getState().jobDetails;
  const { fullName } = positions.find(info => info.workerId === workerId);
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: PreferWorkerMutation,
    variables: {
      data: {
        workerId,
        employerId,
      },
    },
  })
    .then(({ data }) => {
      return Promise.all([data, dispatch(fetchJobWorkers(id, employerId))]);
    })
    .then(([data]) => {
      dispatch(updatePreferList(data.preferWorker.workerId));
      dispatch(setIsUpdatingPositions(false));
      dispatch(
        MetaActions.successToast(`${fullName} has been preferred by ${employer.companyName}`)
      );
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.errorToast(`Failed to prefer ${fullName}. (${e.message})`));
    });
};

export const banWorker = (workerId, reason) => (dispatch, getState) => {
  const { positions, employerId, employer, id } = getState().jobDetails;
  const { fullName } = positions.find(info => info.workerId === workerId);
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: BanWorkerMutation,
    variables: {
      data: {
        workerId,
        employerId,
        reason,
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(fetchJob(id, false));
        dispatch(updateBanList(data.banWorker.workerId));
        dispatch(setIsUpdatingPositions(false));
        dispatch(MetaActions.successToast(`You've banned ${fullName} from ${employer.companyName}`));
      }
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.errorToast(`Failed to ban ${fullName}. (${e.message})`));
    });
};

export const clearException = (workerId, type) => (dispatch, getState) => {
  const { positions, employerId, id } = getState().jobDetails;
  const { fullName } = positions.find(info => info.workerId === workerId);
  dispatch(setIsUpdatingPositions(true));
  return ApolloClient.mutate({
    mutation: ClearExceptionMutation,
    variables: {
      data: {
        workerId,
        employerId,
      },
    },
  })
    .then(() => {
      return dispatch(fetchJobWorkers(id, employerId));
    })
    .then(() => {
      dispatch({
        type: types.REMOVE_PREFER_BAN,
        payload: { workerId },
      });
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.successToast(`${fullName} is no longer ${type}`));
    })
    .catch(e => {
      dispatch(setIsUpdatingPositions(false));
      dispatch(MetaActions.errorToast(`${fullName} is still ${type}. (${e.message})`));
    });
};

const setRatingOption = (data) => ({
  type: types.SET_RATING_OPTION_LIST,
  payload: data
});

export const fetchRatingOption = () => dispatch => {
  return ApolloClient.query({
    query: FetchRatingOptionQuery,
    variables: { platform: "Worker" },
  }).then(({ data: { ratingOption } }) => {
    dispatch(setRatingOption(ratingOption))
  }).catch(e => {
    dispatch(MetaActions.errorToast(`Could not get rate type: ${e.message}`));
  });
};

export const ratingWorker = (workerId, ratings, workerRatingComment) => (dispatch, getState) => {
  const { id, positions } = getState().jobDetails;
  const { fullName } = positions.find(info => info.workerId === workerId);
  const { employerId } = getMe(getState());

  return ApolloClient.mutate({
    mutation: RatingWorkerMutation,
    variables: { data: { jobId: id, workerId, ratings, workerRatingComment } },
  }).then(({ data, errors }) => {
    if (!data && errors.length > 0) {
      throw new Error(errors[0].message);
    } else {
      dispatch(MetaActions.successToast(`You've given rating to ${fullName}`));
      dispatch(fetchJobWorkers(id, employerId))
    }
  }).catch(e => {
    dispatch(MetaActions.errorToast(`Could not save rating: ${e.message}`));
  });
};