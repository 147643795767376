import {gql} from "@apollo/client";

export default gql`
query($email: String, $countryCode: String, $mobilePhone: String) {
    employerOrManagerIsExists(email: $email, mobilePhone: $mobilePhone, countryCode: $countryCode) {
      isEmail
      isPhone
      userExist
      isActive
      isRemoved
    }
  }
`;
