import {gql} from "@apollo/client";

export default gql`
  query getJobs(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $status: JobStatuses
    $employerId: String
    $start: DateRangeInput
    $end: DateRangeInput
    $timeframe: Timeframes
    $visibility: JobVisibility
    $like: [JobFilterInput!]
    $order: [JobOrderInput!]
    $filled: Boolean
    $actionRequired: Boolean
    $isActive: Boolean
    $timezone: String
  ) {
    jobs(
      employerId: $employerId
      first: $first
      last: $last
      after: $after
      before: $before
      status: $status
      start: $start
      end: $end
      timeframe: $timeframe
      visibility: $visibility
      like: $like
      order: $order
      filled: $filled
      actionRequired: $actionRequired
      isActive: $isActive
      timezone: $timezone
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          id
          employerId
          start
          end
          startUtc
          endUtc
          timezone
          isRecurring
          peopleNeeded
          city
          region
          positionsFilled
          checkedInCount
          checkedOutCount
          skills
          description
          workType{
            id
            label
          }
          positions {
            worker{
                fullName
                signedProfileImageUrl
                id
            }
          }
          employer {
            id
            companyName
          }
        }
      }
    }
  }
`;
