import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { actions as JobActions, selectors as JobSelectors } from "store/modules/jobs";
import TableComponent from "../TableComponent";

const TotalPage = ({ history }) => {
  const dispatch = useDispatch();
  const pageInfo = useSelector(JobSelectors.getTodayTotalPaginationData)
  const jobListData = useSelector(JobSelectors.getListData({ timeframe: 'today', status: 'total', part: "data" }))
  const pageFilter = useSelector(JobSelectors.getListData({ timeframe: 'today', status: 'total', part: "filter" }))
  const pageSort = useSelector(JobSelectors.getListData({ timeframe: 'today', status: 'total', part: "sort" }))
  const fetchData = useCallback(({ pageSize, pageIndex, filters, sortBy }) => {
    dispatch(JobActions.setTodayTotalPageSize(pageSize));
    dispatch(JobActions.setTodayTotalFilter(filters));
    dispatch(JobActions.setTodayTotalSort(sortBy));
    dispatch(JobActions.fetchTodayTotalJobs(pageIndex));
  }, []);

  return <TableComponent pageInfo={pageInfo} jobListData={jobListData} pageFilter={pageFilter} pageSort={pageSort} fetchData={fetchData} history={history} />
}

export default TotalPage