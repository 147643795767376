import { makeStyles } from "@mui/styles";

const styles = ({ spacing, palette }) => ({
    dateTimePicker: {
        "& .MuiDialogContent-root .MuiClockPicker-root": {
            "&>div": {
                "&>button": {
                    height: 35,
                    width: 35,
                    "&>span": {
                        fontWeight: "bold",
                        color: "inherit"
                    }
                }
            }
        }
    }
});

export const useStyles = makeStyles(styles);
export default styles;
