import { createReducer } from "utils/redux";
import types from "./types";

const initialListState = {
    data: [],
};

const initialLoadingState = {
    page: true,
    createAnnouncement: false,
};

const initialDialogState = {
    newAnnouncement: false
};

const initialState = {
    loading: { ...initialLoadingState },
    dialog: { ...initialDialogState },
    announcements: {
        ...initialListState
    },
    archiveAnnouncements: {
        ...initialListState
    },
};

const actionsMap = {
    [types.SET_LOADING_STATE]: (state, { payload: { key, value } }) => {
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: value,
            },
        };
    },
    [types.SET_DIALOG_STATE]: (state, { payload: { key, value } }) => {
        return {
            ...state,
            dialog: {
                ...state.dialog,
                [key]: value,
            },
        };
    },
    [types.FETCH_ANNOUNCEMENTS_LIST_SUCCESS]: (state, { payload: { data } }) => {
        return {
            ...state,
            announcements: {
                ...state.announcements,
                data
            },
        };
    },
    [types.FETCH_ARCHIVE_ANNOUNCEMENTS_LIST_SUCCESS]: (state, { payload: { data } }) => {
        return {
            ...state,
            archiveAnnouncements: {
                ...state.archiveAnnouncements,
                data
            },
        };
    },

};

export default createReducer(initialState, actionsMap);