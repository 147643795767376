import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { actions as JobDetailActions } from "store/modules/jobDetails";
import PositionTable from "./PositionTable";

function useEditTableField(saveFn) {
    const dispatch = useDispatch();
    const [isEditable, setIsEditable] = useState(false);
    const onCancel = useCallback(() => {
        setIsEditable(false);
        dispatch(JobDetailActions.resetPositionUpdates());
    }, [dispatch]);
    const handleEdit = useCallback(() => setIsEditable(true), []);
    const onSave = useCallback(() => {
        dispatch(saveFn());
        setIsEditable(false);
    }, [dispatch, saveFn]);

    return [isEditable, handleEdit, onCancel, onSave];
}
export default function JobPositions() {
    const [isRatingAll, handleRatingAll, onCancelRatingAll, onSaveRatingAll] = useEditTableField(
        JobDetailActions.rateAllWorkers
    );
    const [
        isCheckingInAll,
        handleCheckingInAll,
        onCancelCheckingInAll,
        onSaveCheckingInAll,
    ] = useEditTableField(JobDetailActions.checkinAllWorkers);
    const [
        isCheckingOutAll,
        handleCheckingOutAll,
        onCancelCheckingOutAll,
        onSaveCheckingOutAll,
    ] = useEditTableField(JobDetailActions.checkoutAllWorkers);

    let onEditCancel = null;
    let onSaveEdit = null;
    if (isRatingAll) {
        onEditCancel = onCancelRatingAll;
        onSaveEdit = onSaveRatingAll;
    } else if (isCheckingInAll) {
        onEditCancel = onCancelCheckingInAll;
        onSaveEdit = onSaveCheckingInAll;
    } else if (isCheckingOutAll) {
        onEditCancel = onCancelCheckingOutAll;
        onSaveEdit = onSaveCheckingOutAll;
    }
    return (
        <PositionTable
            isRatingAll={isRatingAll}
            isCheckingInAll={isCheckingInAll}
            isCheckingOutAll={isCheckingOutAll}
            handleRatingAll={handleRatingAll}
            handleCheckingInAll={handleCheckingInAll}
            handleCheckingOutAll={handleCheckingOutAll}
            onEditCancel={onEditCancel}
            onEditSave={onSaveEdit}
        />
    );
}