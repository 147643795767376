import {gql} from "@apollo/client";

export const updateJobTemplateMutation = gql`
    mutation editJobTemplate($data: EditJobTemplateInput!) {
        editJobTemplate(data: $data) {
            id
        }
    }
`;

export default updateJobTemplateMutation;