import React from 'react'
import { MobileDatePicker as MUIMobileDatePicker } from '@mui/x-date-pickers'
import moment from 'moment'

export default function MobileDatePicker({ onChange, ...props }) {
    return (
        <MUIMobileDatePicker
            {...props}
            onChange={e => onChange(moment(e))}
        />
    )
}
