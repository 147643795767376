import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { JobStatusesMap, TimeframeLabel } from "helpers/constants";
import MainContent from "components/Containers/MainContent";
import { MuiButton } from "components/CustomButtons/Button";
import Dialog from "components/Dialog/Dialog";
import SubNav from "components/Navigation/SubNav";
import SubNavTab from "components/Navigation/SubNavTab";
import{
  actions as JobDetailActions,
  selectors as JobDetailSelectors,
} from "store/modules/jobDetails";
import { selectors as JobSelectors } from "store/modules/jobs";
import { fetchRatingOption } from "store/modules/jobDetails/actions";
import JobHeader from "./components/JobHeader";
import JobPositions from "./components/JobPositions";
import JobSummary from "./components/JobSummary";
import JobCancelTable from "./components/JobCancelTable";

const useStyles = makeStyles(({ palette, spacing }) => ({
  header: {
    background: "#FFF",
  },
  summary: {
    "@media (max-width: 600px)": {
      padding: '0px',
    }
  },
  progress: {
    color: palette.secondary.light,
    position: "absolute",
    top: "50%",
    left: "45%",
  },
  visibility: {
    paddingTop: spacing(2),
  },
  visibilityFont: {
    color: "#6e7a8a",
    paddingLeft: spacing(1),
  },
}));
export default function JobDetails(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isInitialized } = useSelector(JobDetailSelectors.getJobDetails);
  const isLoading = useSelector(JobDetailSelectors.getIsPageLoading);
  const jobDetail = useSelector(JobDetailSelectors.getJobDetails);
  const timeframeState = useSelector(JobSelectors.getTimeframeStats);
  const [value, setValue] = useState(0);
  useEffect(() => {
    if (props.match.params && props.match.params.id) {
      dispatch(JobDetailActions.fetchJob(props.match.params.id));
    }
    dispatch(fetchRatingOption());
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showCancelDialog = () => {
    dispatch(JobDetailActions.setIsCancelJobDialogState(true));
  };

  const cancelJob = (recurringJobList, id) => {
    dispatch(JobDetailActions.cancelRecurringJob(recurringJobList, id)).then(data => {
      if (data) {
        let jobType = TimeframeLabel[jobDetail.timeframe]
        if (jobType === 'Upcoming' && timeframeState.today > 0 && timeframeState.tomorrow > 0) {
          props.history.push(`/job-overview?timeframe=2`);
        }
        else if (jobType === 'Upcoming' && timeframeState.today == 0 && timeframeState.tomorrow > 0) {
          props.history.push(`/job-overview?timeframe=1`);
        }
        else if (jobType === 'Upcoming' && timeframeState.today == 0 && timeframeState.tomorrow == 0) {
          props.history.push(`/job-overview?timeframe=0`);
        }
        else {
          props.history.push(`/job-overview`)
        }
      }
    });
  };

  if (isLoading) {
    return <CircularProgress classes={{ root: classes.progress }} />;
  }

  if (!isInitialized) {
    return null;
  }
  const tabs = () => {
    return [
      <SubNavTab key="Detail" label="Detail" />,
      <SubNavTab key="Workers" label="Workers" />,
      ...(jobDetail.status === JobStatusesMap.NotStarted
        ? [
          <MuiButton
            type="Button"
            key="cancel1"
            variant="text"
            color="error"
            onClick={showCancelDialog}
          >
            Cancel Job
          </MuiButton>,
        ]
        : []),
    ];
  };
  return (
    <React.Fragment>
      <MainContent className={classes.header}>
        <JobHeader />
      </MainContent>
      <SubNav value={value} onChange={handleChange} tabs={tabs()} />

      {value === 0 && (
        <MainContent className={classes.summary}>
          <JobSummary />
        </MainContent>
      )}
      {value === 1 && (
        <MainContent>
          <JobPositions />
        </MainContent>
      )}

      <JobCancelTable jobDetail={jobDetail} cancelJob={cancelJob} />

    </React.Fragment>
  );
}