import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DialogActions, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import iconEdit from "assets/img/iconEdit.svg";
import avatar from "assets/img/profile-placeholder.png";
import { Form, Formik } from "formik";
import * as yup from 'yup';
import Button from "components/CustomButtons/Button";
import Dialog from "components/Dialog/Dialog";
import { actions as ProfileActions, selectors as ProfileSelectors } from "store/modules/profile";

const useStyles = makeStyles(() => ({
  avatarImg: {
    margin: '10px auto',
    width: '100px',
    position: 'relative',
    '& img': {
      borderRadius: '100px',
      border: '2px solid #c4c4c4'
    },
    '& label': {
      position: 'absolute',
      right: '6px',
      background: '#ffffff',
      bottom: '0',
      padding: '0px',
      '& img': {
        padding: '2px',
      },
    }
  }
}));
function EditCompanyDialog(props) {
  const { open, onClose, onCancel, onConfirm, profileData } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [logoURL, setLogoURL] = useState();
  const isLoading = useSelector(ProfileSelectors.getIsUpdatingProfileLoading)
  const handleProfileFile = file => {
    const item = new Blob([file], { type: file.type });
    item.name = `logo_${file.name}`;
    setLogoURL(URL.createObjectURL(item))
    dispatch(ProfileActions.uploadCompanyLogoImage(item));
  }
  useEffect(() => {
    setLogoURL(profileData.signedLogoImageUrl)
  }, [profileData.signedLogoImageUrl]);

  return (
    <Dialog
      open={open}
      title="Update Company Detail"
      onClose={onClose}
      onCancel={onCancel}
      alertType={'form'}
      hideActions
      size="sm"
      modalType="scrollable"
      disableBackdropClick={false}
    >
      <Grid item xs={12} style={{ width: "100%", paddingLeft: 0, paddingRight: 0 }}>
        <Formik
          initialValues={{
            title: profileData.title,
            companyName: profileData.companyName,
            workEmail: profileData.workEmail,
          }}
          onSubmit={(values, actions) => {
            onConfirm(values);
            actions.setSubmitting(false);
          }}
          validationSchema={yup.object().shape({
            workEmail: yup.string().email('Please enter valid email').max(100, 'Email address must be less than 100 characters').required("Required"),
            title: yup.string().required("Required").max(55, 'Title must be less than 55 characters'),
            companyName: yup.string().required("Required").max(55, 'Company Name must be less than 55 characters'),
          })}>
          {props => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
            } = props;
            return (
              <Form>
                <Grid
                  item
                  container
                  direction="row"
                  xs={12}
                >
                  <Grid container item xs={12} md={12} spacing={1}>
                    <div className={classes.avatarImg}>
                      <img width={'100'} height={'100'} src={logoURL} onError={(e) => { e.target.onerror = null; e.target.src = avatar }} alt="..." />
                      <IconButton className="edit-icon" disableRipple component="label">
                        <img src={iconEdit} />
                        <input type="file" hidden onChange={files => handleProfileFile(files.target.files[0])} />
                      </IconButton>
                    </div>
                  </Grid>
                  <Grid container item xs={12} md={12} spacing={1}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        id="companyName"
                        name="companyName"
                        label="Company Name *"
                        className={classes.textField}
                        value={values.companyName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        disabled={!!profileData.companyName}
                        error={errors.companyName && touched.companyName}
                        helperText={errors.companyName && touched.companyName && errors.companyName}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12} >
                    <TextField
                      id="title"
                      name="title"
                      label="Title *"
                      className={classes.textField}
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      disabled={!!profileData.title}
                      error={errors.title && touched.title}
                      helperText={errors.title && touched.title && errors.title}
                      margin="normal"
                    />
                  </Grid>
                  <Grid container item xs={12} md={12}>
                    <TextField
                      id="workEmail"
                      name="workEmail"
                      label="Work Email Address *"
                      className={classes.textField}
                      value={values.workEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      disabled={!!profileData.workEmail}
                      error={errors.workEmail && touched.workEmail}
                      helperText={errors.workEmail && touched.workEmail && errors.workEmail}
                      margin="normal"
                    />
                  </Grid>
                </Grid>
                <DialogActions className={classes.footer}>
                  <Button
                    type="button"
                    className="outline"
                    onClick={onCancel}
                    color="transparent"
                    round
                  >
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    round
                    disabled={!_.isEmpty(errors) || isLoading}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Grid>
    </Dialog>
  );
}

export default EditCompanyDialog;
