import {gql} from "@apollo/client";

export default gql`
  fragment JobFields on Job {
    id
    createdAt
    createdAtUtc
    employerId
    start
    end
    startUtc
    endUtc
    timezone
    workTypeId
    peopleNeeded
    hourlyRate
    street
    unit
    floor
    city
    region
    locationInstruction
    description
    status
    isBreakRequired
    isRecurring
    timeframe(timezone: $timezone)
    durationMins
    positionsFilled
    durationMins
    estimatedCost
    isActive
    isPrivate
    tags
    breakMins
    paidBreakMins
    visibility
    notifyEmployer
    workType {
      id
      label
    }
    banned {
      workerId
    }
    preferred {
      workerId
    }
    notificationLog {
      id
      workerRating
      totalSuccess
      totalFailed
      sentOn
      sentFrom
    }
    gears
    skills
    jobRecurringId
    referenceJobId
    jobRecurring{
      id
      frequency
      frequencyEndAt
      dayOrWeekDay
      frequencyInterval
    }
    jobAnnouncement{
      id
      title
      description
      frequency
      beforeAfter
      dayOrWeekDay
    }
    jobManager{
      id
      role
      managerId
      manager{
        id
        firstName
        lastName
        signedProfileImageUrl
      }
    }
    calculateEmployerBillDetail{
      hourlyPay
      regularPayHours
      overtimePay
      overtimePayHours
      doublePay
      doublePayHours
      breakPay
      gfFee
      gstPercentage
      gstAmount
      total
    }
  }
`;
