export const generateZenDeskScript = (onSuccess = () => 0, onError = () => 0) => {
    const externalScript = document.createElement("script");
    externalScript.onerror = onError;
    externalScript.id = "ze-snippet";
    externalScript.defer = true;
    externalScript.type = "text/javascript";
    document.head.appendChild(externalScript);
    externalScript.src = `https://static.zdassets.com/ekr/snippet.js?key=bc01349c-b505-493f-babb-23c8ac7b69a7`;
    externalScript.onload = onSuccess;
};

export const removeZenDesk = () => {
    if (typeof zE !== "undefined")
        zE(function () {
            zE('messenger', 'close')
        });
}

export const openZenDesk = () => {
    if (typeof zE !== "undefined")
        zE(function () {
            zE('messenger', 'open')
        });
}

export const hideZenDeskHeader = () => {
    if (typeof zE !== "undefined")
        zE('messenger:on', 'unreadMessages', () => {
            setTimeout(() => {
                Object.values(document.getElementsByTagName("iframe")).map(({ contentDocument: { head, body } }) => {
                    if (!head.getElementsByTagName("link").length) {
                        const link = document.createElement("link");
                        link.href = "./zenDeskStyle.css";
                        link.rel = "stylesheet";
                        link.type = "text/css";
                        head.appendChild(link);
                    }
                });
            });
        });
}