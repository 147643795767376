import ApolloClient from "utils/apollo";
import { actions as MetaActions } from "store/modules/meta";
import { getMe } from "../login/selectors";
import AddManagerMutation from "./graphql/mutations/add-manager";
import DisableManagerMutation from "./graphql/mutations/disable-manager";
import EditManagerMutation from "./graphql/mutations/edit-manager-by-employer";
import EnableManagerMutation from "./graphql/mutations/enable-manager";
import RemoveManagerMutation from "./graphql/mutations/remove-manager";
import uploadManagerProfileImageMutation from "./graphql/mutations/update-manager-profile-image";
import GetManagersQuery from "./graphql/queries/get-managers";
import types from "./types";

const setLoadingState = key => value => ({
  type: types.SET_LOADING_STATE,
  payload: { key, value },
});

const setIsAddingManager = setLoadingState("addManager");

export const setIsAddManagerDialogState = value => ({
  type: types.SET_IS_ADD_MANAGER_DIALOG_STATE,
  payload: { value },
});

export const fetchManagers = (isAll = false) => (dispatch, getState) => {
  const state = getState();
  const { employerId } = getMe(state);

  return ApolloClient.query({
    query: GetManagersQuery,
    variables: {
      employerId: employerId,
      isAll
    },
  })
    .then(({ data }) => {
      if(isAll){
        dispatch({
          type: types.SET_ALL_MANAGER_LIST_DATA,
          payload: {
            data: data.managerByEmployerId,
          },
        });
      } else {
        dispatch({
          type: types.SET_MANAGER_LIST_DATA,
          payload: {
            data: data.managerByEmployerId,
          },
        });
      }
    })
    .catch(e => {
      console.log(e);
    });
};

export const enableManager = managerId => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  return ApolloClient.mutate({
    mutation: EnableManagerMutation,
    variables: {
      managerId,
      employerId: employerId,
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(MetaActions.successToast(`Manager is now enable`));
      dispatch(fetchManagers(true));
    })
    .catch(e => {
      dispatch(MetaActions.errorToast(`${e.message}`));
    });
};

export const disableManager = (managerId, reason) => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  return ApolloClient.mutate({
    mutation: DisableManagerMutation,
    variables: {
      managerId,
      employerId: employerId,
      reason,
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(MetaActions.successToast(`Manager is now disable`));
      dispatch(fetchManagers(true));
    })
    .catch(e => {
      dispatch(MetaActions.errorToast(`${e.message}`));
    });
};

export const addManager = data => (dispatch) => {
  dispatch(setIsAddingManager(true));
  if(!data.profileImage){
    delete data.profileImage;
  }
  return ApolloClient.mutate({
    mutation: AddManagerMutation,
    variables: {
      data,
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(setIsAddingManager(false));
        dispatch(setIsAddManagerDialogState());
        dispatch(MetaActions.successToast(`Manager added successfully`));
        dispatch(fetchManagers(true));
      }
    })
    .catch(e => {
      dispatch(setIsAddingManager(false));
      dispatch(MetaActions.errorToast(`${e.message}`));
    });
};

export const editManager = data => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  dispatch(setIsAddingManager(true));
  return ApolloClient.mutate({
    mutation: EditManagerMutation,
    variables: {
      data: {
        employerId,
        managerId: data.managerId,
        firstName: data.firstName,
        lastName: data.lastName,
        title: data.title,
      },
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      } else {
        dispatch(setIsAddingManager(false));
        dispatch(setIsAddManagerDialogState());
        dispatch(MetaActions.successToast(`Manager update successfully`));
        dispatch(fetchManagers(true));
      }
    })
    .catch(e => {
      dispatch(setIsAddingManager(false));
      dispatch(MetaActions.errorToast(`${e.message}`));
    });
};

export const removeManager = managerId => (dispatch, getState) => {
  const { employerId } = getMe(getState());
  return ApolloClient.mutate({
    mutation: RemoveManagerMutation,
    variables: {
      managerId,
      employerId: employerId,
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(MetaActions.successToast(`Manager deleted successfully`));
      dispatch(fetchManagers(true));
    })
    .catch(e => {
      dispatch(MetaActions.errorToast(`${e.message}`));
    });
};

export const uploadManagerProfileImage = (id, file) => (dispatch) => {
  return ApolloClient.mutate({
    mutation: uploadManagerProfileImageMutation,
    variables: {
      id,
      file,
    },
  })
    .then(({ data, errors }) => {
      if (!data && errors.length > 0) {
        throw new Error(errors[0].message);
      }
      dispatch(fetchManagers(true));
      dispatch(MetaActions.successToast(`Profile photo has been updated.`));
    })
    .catch(e => {
      dispatch(MetaActions.errorToast(`Updating profile has failed. ${e}`));
    });
};
