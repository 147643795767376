import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { withStyles } from "@mui/styles";

const styles = {
  container: {
    position: "relative",
    top: "-1px",
    zIndex: "1030",
    paddingLeft: "32px",
    backgroundColor: "#FFF",
    borderBottom: "1px solid #d5dde5",
  },
  appBar: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  indicator: {
    backgroundColor: "#11b2a8",
  },
  tabRroot: {
    textTransform: "none",
    marginRight: "22px",
    fontSize: "10px",
    paddingTop: "0",
    paddingBottom: "0",
  },
  buttonSection: {
    width: "100%",
    textAlign: "right",
    paddingRight: "30px",
  },
};

function SubNav({ classes, value, onChange, tabs }) {
  return (
    <div className={classes.container}>
      <AppBar position="static" classes={{ root: classes.appBar }}>
        <Tabs
          value={value}
          onChange={onChange}
          TabIndicatorProps={{ className: classes.indicator }}
          textColor="primary"
          variant="scrollable"
        >
          {tabs.map((el, i) => {
            if (el.props && el.props.type === "Button") {
              return ;
            }
            return (
              <Tab
                key={i}
                label={el}
                classes={{
                  root: classes.tabRroot,
                }}
              />
            );
          })}
          <div className={classes.buttonSection}>
            {tabs.map((el) => {
              if (el.props && el.props.type === "Button") {
                return el;
              }
            })}
          </div>
        </Tabs>
      </AppBar>
    </div>
  );
}

SubNav.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default withStyles(styles)(SubNav);
