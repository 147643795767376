import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Avatar, Card, CardHeader, Chip, Divider, Grid, Typography, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import iconDateTime from "assets/img/iconDateTime.svg";
import iconPinLocation from "assets/img/iconPinLocation.svg";
import { ManagerRoleLabel } from "helpers/constants";
import { find, isEmpty } from "lodash";
import { weekDaysList } from "utils/constant";
import { formatMoney } from "utils/stringUtils";
import { convertUTCToTimezoneWithOffset, formattedTimeWithOffset, SplitTime } from "utils/time";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";
import { InfoRounded } from "@mui/icons-material";
import FeesDialog from "views/Job/PostJob/components/FeesDialog";
import Tooltip from '@mui/material/Tooltip';
import { overTimeText, doubleTimeText } from "helpers/constants"

const useStyles = makeStyles(({ spacing, palette, shape }) => ({
  root: {
    backgroundColor: `${palette.common.white}`,
    border: `1px solid ${palette.grey[500]}`,
    borderRadius: shape.borderRadius / 2,
    padding: spacing(3),
    marginTop: spacing(2),
    paddingLeft: spacing(3),
  },
  totalLine: {
    background: palette.grey[500],
    marginBottom: spacing(2),
    width: "50%",
    height: "1px",
  },
  paymentStatusContainer: {
    paddingTop: spacing(2),
  },
  workType: {
    fontSize: "20px",
    marginTop: "25px",
  },
  location: {
    color: "#202326",
    display: "flex",
    width: "100%",
    "& .icon": {
      width: "30px",
    },
  },
  address: {
    wordBreak: "break-word"
  },
  addressFloor: {
    wordBreak: "break-word",
    width: "100%",
  },
  dateTime: {
    display: "flex",
    "& .icon": {
      width: "30px",
    },
    "& .date": {
      fontSize: "16px",
      fontWeight: "bold",
    },
    "& .duration": {
      color: "#848499",
    },
    "& .button": {
      display: "flex",
      marginTop: "10px",
      marginBottom: "10px",
      "& .break": {
        borderRadius: "10px",
        padding: "2px 8px",
        border: "1px solid",
        color: "#00a25b",
        background: "rgb(0 162 91 / 10%)",
        marginRight: "10px",
      },
      "& .recurring": {
        borderRadius: "10px",
        padding: "2px 8px",
        border: "1px solid",
        color: "#0074c8",
        background: "rgb(0 116 200 / 10%)",
      },
    },
  },
  requiredGear: {
    "& .MuiChip-root": {
      marginRight: "8px",
      marginBottom: "8px"
    },
  },
  total: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  jobDescription:{
    wordBreak: "break-word",
  }
}));
export default function JobSummary() {
  const classes = useStyles();
  const jobStart = useSelector(JobDetailSelectors.getJobStartUtc);
  const jobEnd = useSelector(JobDetailSelectors.getJobEndUtc);
  const jobTimezone = useSelector(JobDetailSelectors.getJobTimezone);
  const isBreakRequiredForJob = useSelector(JobDetailSelectors.getIsBreakRequiredForJob);
  const breakInfo = useSelector(JobDetailSelectors.getJobBreakInfoLabel);
  const jobDescription = useSelector(JobDetailSelectors.getJobDescription);
  const peopleNeeded = useSelector(JobDetailSelectors.getPeopleNeeded);
  const jobTotalDuration = useSelector(JobDetailSelectors.getJobDurationMins);
  const [feesDialog, setFeesDialog] = useState(false);

  const {
    gears,
    skills,
    jobManager,
    street,
    unit,
    city,
    tags,
    region,
    locationInstruction,
    isRecurring,
    jobRecurring,
    calculateEmployerBillDetail,
    floor
  } = useSelector(JobDetailSelectors.getJobDetails);

  const RegularPayHours = (SplitTime(calculateEmployerBillDetail.regularPayHours));
  const OvertimePayHours = (SplitTime(calculateEmployerBillDetail.overtimePayHours));
  const DoublePayHours = (SplitTime(calculateEmployerBillDetail.doublePayHours));

  const duration = Math.round((jobTotalDuration / 60) * 100) / 100;

  const handleClickOpenFeesDialog = () => {
    setFeesDialog(true)
  }

  const handleClickCloseFeesDialog = () => {
    setFeesDialog(false)
  }

  return (
    <>
      <Grid container item xs={12} className={classes.root}>
        <Grid container item xs={12} sm={6} md={6}>
          <div className={classes.dateTime}>
            <div className="icon">
              <img src={iconDateTime} />
            </div>
            <div className="detail">
              <div className="date">
                {convertUTCToTimezoneWithOffset(jobStart, jobTimezone, "dddd, MMMM Do, YYYY")}
              </div>
              <div className="time">{formattedTimeWithOffset(jobStart, jobEnd, jobTimezone)}</div>
              <div className="duration">({duration} Hours Duration) </div>
              <div className="button">
                {isBreakRequiredForJob && <div className="break">{breakInfo}</div>}
                <div className="recurring">{isRecurring === false ? "One Time" : "Recurring"}</div>
              </div>
              {isRecurring && jobRecurring && (
                <React.Fragment>
                  {jobRecurring.frequency === "Daily" && (
                    <div className="time">Every {jobRecurring.frequencyInterval} Day </div>
                  )}
                  {jobRecurring.frequency === "Weekly" && (
                    <div className="time">
                      Every{" "}
                      {jobRecurring.frequencyInterval > 1
                        ? jobRecurring.frequencyInterval + " "
                        : ""}
                      Week, &nbsp;
                      <b>Days:</b> &nbsp;
                      {jobRecurring.dayOrWeekDay.map((m, ind) => {
                        const day = find(weekDaysList, { value: m });
                        if (day) {
                          return <span key={ind}>{(ind != 0 ? ", " : "") + day.label}</span>;
                        }
                      })}
                    </div>
                  )}
                  {jobRecurring.frequency === "Monthly" && (
                    <div className="time">
                      Every{" "}
                      {jobRecurring.frequencyInterval > 1
                        ? jobRecurring.frequencyInterval + " "
                        : ""}
                      Month, &nbsp;
                      <b>Date:</b> &nbsp;
                      {jobRecurring.dayOrWeekDay.map((m, ind) => (
                        <span key={ind}>{(ind != 0 ? ", " : "") + m}</span>
                      ))}
                    </div>
                  )}
                  <div className="time">
                    <b>Recurring End:</b>{" "}
                    {convertUTCToTimezoneWithOffset(
                      jobRecurring.frequencyEndAt,
                      jobTimezone,
                      "dddd, MMMM Do, YYYY",
                    )}{" "}
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </Grid>
        <Grid container item xs={12} sm={6} md={6} direction="column">
          <div className={classes.location}>
            <div className="icon">
              <img src={iconPinLocation} />
            </div>
            <div className={classes.address}>{`${unit || ""} ${street || ""} ${city || ""} ${region || ""}`}</div>
          </div>
          <br />
          {/* {unit && (
            <div>
              <b>Unit: </b> {unit}{" "}
            </div>
          )} */}
          {floor && (
            <div className={classes.addressFloor}>
              <b>Floor: </b> {floor}{" "}
            </div>
          )}
          {locationInstruction && (
            <div className={classes.addressFloor}>
              <b>Additional instructions:</b> {locationInstruction}
            </div>
          )}
        </Grid>
        <Grid container item xs={12}>
          {gears &&
            gears.length > 0 && (
              <Grid container item xs={12} sm={6} md={6} direction="column">
                <div className={classes.requiredGear}>
                  <br />
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                    justifyContent={"space-between"}
                  >
                    REQUIRED GEAR
                  </Typography>
                  {gears &&
                    gears.map((n, index) => <Chip key={index} label={n} variant="outlined" />)}
                </div>
              </Grid>
            )}
          {skills &&
            skills.length > 0 && (
              <Grid container item xs={12} sm={6} md={6} direction="column">
                <div className={classes.requiredGear}>
                  <br />
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                    justifyContent={"space-between"}
                  >
                    SUGGESTED SKILLS {tags && tags.length > 0 && "AND TAGS"}
                  </Typography>
                  <ul>{skills && skills.map((n, index) => <li key={index}>{n}</li>)}</ul>
                  <div style={{ paddingLeft: "20px" }}>
                    {tags && tags.map((n, index) => (
                      <Chip key={index} label={n} variant="outlined" />
                    ))}
                  </div>
                </div>
              </Grid>
            )}
        </Grid>
        <Divider />
        <Grid container item xs={12} sm={12} md={12} direction="column">
          <div className={classes.requiredGear}>
            <br />
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
              justifyContent={"space-between"}
            >
              DESCRIPTION
            </Typography>
            {jobDescription && (
              <ul>
                <li className={classes.jobDescription}>{jobDescription}</li>
              </ul>
            )}
          </div>
        </Grid>
        <Divider />
        {jobManager &&
          jobManager.length > 0 && (
            <Grid container item xs={12} sm={12} md={12} direction="column">
              <div className={classes.requiredGear}>
                <br />
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                  justifyContent={"space-between"}
                >
                  PROJECT ROLES
                </Typography>
                <Grid container spacing={2} justifyContent={"start"}>
                  {jobManager.map(n => (
                    <Grid key={n.id} item md={4} sm={6} xs={12}>
                      <Card>
                        <CardHeader
                          avatar={<Avatar alt={n.manager?.firstName} src={n.manager?.signedProfileImageUrl} />}
                          title={
                            !isEmpty(n) &&
                            n.manager &&
                            `${n.manager.firstName} ${n.manager.lastName}`
                          }
                          subheader={ManagerRoleLabel[n.role]}
                        />
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          )}
        <Grid container item xs={12} sm={12} md={12}>

          <Grid item md={12} sm={12} xs={12} justifyContent={"start"}>
            <br />
            <Divider />
            <br />
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
              justifyContent={"space-between"}
            >
              BUDGET
            </Typography>

            <Grid container xs={12}>
              <Grid item xs={6}>
                Hourly pay - {RegularPayHours.Hours} hrs {RegularPayHours.Minutes > 0 ? RegularPayHours.Minutes + ' mins' : ''}  x {peopleNeeded} worker
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                {formatMoney(calculateEmployerBillDetail.hourlyPay)}
              </Grid>
            </Grid>
            {calculateEmployerBillDetail.overtimePay > 0 && (
              <Grid container xs={12}>
                <Grid item xs={6}>
                  Overtime pay - {OvertimePayHours.Hours} hrs {OvertimePayHours.Minutes > 0 ? OvertimePayHours.Minutes + ' mins' : ''} x {peopleNeeded}{" "} worker
                  <Tooltip title={overTimeText}>
                    <IconButton
                      style={{ padding: "5px", marginTop: "-3px" }}
                    >
                      <InfoRounded style={{ fontSize: "14px" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  {formatMoney(calculateEmployerBillDetail.overtimePay)}
                </Grid>
              </Grid>
            )}
            {calculateEmployerBillDetail.doublePay > 0 && (
              <Grid container xs={12}>
                <Grid item xs={6}>
                  Doubletime pay - {DoublePayHours.Hours} hrs {DoublePayHours.Minutes > 0 ? DoublePayHours.Minutes + ' mins' : ''} x {peopleNeeded}{" "}
                  worker
                  <Tooltip title={doubleTimeText}>
                    <IconButton
                      style={{ padding: "5px", marginTop: "-3px" }}
                    >
                      <InfoRounded style={{ fontSize: "14px" }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  {formatMoney(calculateEmployerBillDetail.doublePay)}
                </Grid>
              </Grid>
            )}
            <Grid container xs={12}>
              <Grid item xs={6}>
                Grizzly Force Fee
                <IconButton
                  style={{ padding: "5px", marginTop: "-3px" }}
                  onClick={handleClickOpenFeesDialog}
                >
                  <InfoRounded style={{ fontSize: "14px" }} />
                </IconButton>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                {formatMoney(calculateEmployerBillDetail.gfFee)}
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={6}>
                GST ({calculateEmployerBillDetail.gstPercentage}
                %)
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                {formatMoney(calculateEmployerBillDetail.gstAmount)}
              </Grid>
            </Grid>
            <Divider />
            <br />
            <Grid
              container
              className={classes.total}
              xs={12}
            >
              <Grid item xs={6}>
                TOTAL AMOUNT DUE
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                {formatMoney(calculateEmployerBillDetail.total)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <FeesDialog
        feesDialog={feesDialog}
        handleClickCloseFeesDialog={handleClickCloseFeesDialog}
      />
    </>
  );
}
