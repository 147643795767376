import {gql} from "@apollo/client";
export default gql`
  query jobTemplate($employerId: ID!){
    jobTemplate(employerId: $employerId) {
      id
      templateName
      employerId
      managerId
      workTypeId
      peopleNeeded
      street
      city
      region
      country
      postalCode
      latitude
      longitude
      locationInstruction
      unit
      floor
      description
      isActive
      skills
      gears
      isPrivate
      tags
      breakMins
      isBreakPaid
      manager {
        id
        firstName
        lastName
      }
      workType {
        id
        label
      }
    }
  }
`;