/* eslint-disable import-helpers/order-imports */
import React, { useEffect, useState } from 'react'
import { useStyles } from './styles';
import ReactTableSimple from 'components/ReactTable/ReactTableSimple';
import { convertUTCToTimezoneWithOffset, displayLocalDateTime } from 'utils/time';
import { Avatar, Chip, Tooltip } from '@mui/material';

export default function AnnouncementSubTable({ data, ...props }) {
    const classes = useStyles();
    const [flatData, setFlatData] = useState([]);

    useEffect(() => {
        setFlatData(data?.jobAnnouncement.flatMap((d) => d?.jobAnnouncementTime.map(j => ({
            ...j,
            title: d.title,
            description: d.description,
            timezone: data.timezone,
            createdBy: d.managerId ? d.manager?.firstName + " " + d.manager?.lastName : d.employer?.fullName,
            workers: d.workers
        }))));
    }, []);


    const columns = [
        {
            Header: "Title",
            id: "title",
            accessor: "title",
            canFilter: true,
            className: "-cursor-pointer",
            Cell: ({ row: { original } }) => {
                return original.title || ''
            }
        },
        {
            Header: "Description",
            id: "description",
            accessor: "description",
            styles: {
                minWidth: '125px'
            },
            Cell: ({ row: { original } }) => {
                return original.description || ''
            },
            sortable: false,
            className: "-cursor-pointer",
        },
        {
            Header: "Created By",
            id: "createdBy",
            accessor: "createdBy",
            styles: {
                minWidth: '125px'
            },
            Cell: ({ row: { original } }) => {
                return original.createdBy || ''
            },
            sortable: false,
            className: "-cursor-pointer",
        },
        {
            Header: "Send At",
            id: "announcementStartDate",
            Cell: ({ row: { original } }) => {
                return (
                    <div>
                        {convertUTCToTimezoneWithOffset(original.sendAt, original.timezone, "MMM Do, YYYY hh:mm a")}
                        <br />
                        {displayLocalDateTime(original.sendAt, original.timezone, "MMM Do, YYYY")}
                    </div>
                )
            },
            accessor: "sendAt",
            className: "-cursor-pointer",
            styles: {
                minWidth: '150px'
            },
        },
        {
            Header: "Workers",
            id: "workers",
            Cell: ({ row: { original } }) => {
                if (!original.workers || !original.workers.length) return <Chip
                    size="small"
                    style={{ margin: '1px' }}
                    label={"All"}
                />
                return (
                    <div>
                        {original.workers && original.workers.map(({ worker: { firstName, signedProfileImageUrl } }, index) => {
                            if (index < 2) {
                                return <Chip
                                    key={index}
                                    avatar={<Avatar alt={firstName} src={signedProfileImageUrl} />}
                                    size="small"
                                    style={{ margin: '1px' }}
                                    label={firstName}
                                />
                            }
                        })}
                        {original.workers && original.workers.length > 2 && (
                            <Tooltip title={
                                <div>
                                    {original.workers && original.workers.map(({ worker: { firstName, signedProfileImageUrl } }, index) => {
                                        if (index >= 2) {
                                            return <Chip
                                                key={index}
                                                avatar={<Avatar alt={firstName} src={signedProfileImageUrl} />}
                                                size="small"
                                                style={{ margin: '1px' }}
                                                label={firstName}
                                            />
                                        }
                                    })}
                                </div>
                            } placement="top">
                                <Chip
                                    size="small"
                                    style={{ margin: '1px' }}
                                    label={'More'}
                                />
                            </Tooltip>
                        )}
                    </div>
                )
            },
            accessor: "sendAt",
            className: "-cursor-pointer",
            styles: {
                minWidth: '150px'
            },
        },
    ];

    return (
        <div style={{ padding: 10 }}>
            <ReactTableSimple
                classes={classes}
                columns={columns}
                data={flatData}
            />
        </div>
    )
}
