export const getManagersDialogState = (state) => {
  return state.managers.isOpenAddManagerDialog;
};

export const getManagersListData = () => (state) => {
  return state.managers.list;
};

export const getManagersListAllData = (state) => {
  return state.managers.allList;
};
// Loading selectors
export const getLoadingStateFactory = loader => state => {
  return state.managers.loading[loader] || false;
};

export const getIsPageLoading = getLoadingStateFactory("page");
export const getIsAddingManager = getLoadingStateFactory("addManager");