import React from "react";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { selectors as JobDetailSelectors } from "store/modules/jobDetails";

const useStyles = makeStyles(({ spacing }) => ({
  filledBreakdown: {
    marginRight: spacing(1),
  },
  title: {
    padding: '0.5em 0em 0em 1em',
    fontSize: '20px',
    fontWeight: 'normal'
  }
}));

function PositionStatus() {
  const classes = useStyles();
  const positionsFilled = useSelector(JobDetailSelectors.getPositionsFilled);
  const peopleNeeded = useSelector(JobDetailSelectors.getPeopleNeeded);
  const isSearching = useSelector(JobDetailSelectors.getIsSearchingForWorkers);

  return (
    <Grid container item xs={6} direction="row" alignItems="center" className={classes.title}>
      <Typography className={classes.filledBreakdown} variant="subtitle2">
        {`Workers (${positionsFilled}/${peopleNeeded})`}
      </Typography>
      {isSearching && (
        <Typography variant="body1" color="textSecondary">
          {"Searching..."}
        </Typography>
      )}
    </Grid>
  );
}

export default PositionStatus;
