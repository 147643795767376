import Auth0 from "auth0-js";
import jwtDecode from "jwt-decode";
import ApolloClient from "utils/apollo";
import history from "utils/history";
import { mobileValidator, verifyEmail } from "utils/stringUtils";
import { actions as MetaActions } from "store/modules/meta";
import { fetchProfile } from "../profile/actions";
import CheckUserExistQuery from "./graphql/queries/check-user-exist";
import FetchMeQuery from "./graphql/queries/fetch-me";
import FetchTimezoneQuery from "./graphql/queries/fetch-timezone";
import types from "./types";
const setLoadingState = key => value => ({
  type: types.SET_LOADING_STATE,
  payload: { key, value },
});
const setIsLoginLoading = setLoadingState("login");
const setIsOtpLoading = setLoadingState("otp");

const REDIRECT_URI = `${window.location.origin}/callback`;
const auth0 = new Auth0.WebAuth({
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  responseType: process.env.REACT_APP_AUTH0_RESPONSE_TYPE,
  scope: process.env.REACT_APP_AUTH0_SCOPE,
  redirectUri: REDIRECT_URI,
});
// auth0.crossOriginVerification(); http://*.grizzlyforce.com
const loginParams = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  realm: process.env.REACT_APP_AUTH0_REALM,
  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
};
export const resetForm = () => ({ type: types.RESET });
export const forgetUser = () => ({ type: types.FORGET_USER });
export const clearCredentials = () => ({ type: types.CLEAR_CREDENTIALS });
export const setCredentials = auth => ({ type: types.SET_CREDENTIALS, payload: { auth } });
export const setMe = me => ({ type: types.SET_ME, payload: { me } });
export const setEmail = email => ({ type: types.SET_LOGIN_EMAIL, payload: { email } });
export const setIsLoggingOut = isLoggingOut => ({ type: types.SET_IS_LOGGING_OUT, payload: { isLoggingOut } });
export const setOTP = otp => ({ type: types.SET_OTP, payload: { otp } });
export const setIsUsernameVerify = isUsernameVerify => ({
  type: types.SET_IS_USERNAME_VERIFY,
  payload: { isUsernameVerify },
});
export const setIsCheckingSession = isCheckingSession => ({
  type: types.IS_CHECKING_SESSION,
  payload: { isCheckingSession },
});

const handleAuth0Error = dispatch => e => {
  if (e.error === "login_required") {
    history.replace("/");
    return;
  }

  let message = `Error: ${e}`;
  if (e.error && (e.errorDescription || e.error_description)) {
    const description = e.errorDescription ? e.errorDescription : e.error_description;
    message = `Error: ${e.error} - ${description}`;
  }

  dispatch(MetaActions.errorToast(message));
};

export const checkSession = () => dispatch => {
  return new Promise((resolve, reject) => {
    auth0.checkSession({ redirectUri: REDIRECT_URI, ...loginParams }, (err, auth) => {
      if (err) {
        return reject(err);
      }

      if (!auth || !auth.idToken) {
        return reject(err);
      }
      resolve(auth);
    });
  })
    .then(auth => {
      // const roles = jwtDecode(auth.accessToken)[process.env.REACT_APP_ROLES_ENDPOINT];
      // if (roles.includes("Employer") || roles.includes("EmployerManager")) {
      dispatch(setCredentials(auth));
      return auth;
      // } else {
      //   history.replace("/");
      //   return dispatch(MetaActions.infoToast("Not Authorized!"));
      // }
    })
    .then(auth => {
      return ApolloClient.query({
        query: FetchMeQuery,
        variables: { authId: auth.idTokenPayload.sub },
      });
    })
    .then(({ data: me }) => me.me)

    .then(me => {
      if (me.accountId && (me.accountType === "employer" || me.accountType === "employermanager")) {
        dispatch(setMe(me));
        dispatch(fetchProfile());
      } else {
        history.replace("/");
        dispatch(MetaActions.errorToast("Not Authorized!"));
        dispatch(logout())
      }
    })
    .catch(handleAuth0Error(dispatch));
};

export const handleAuthentication = () => dispatch => {
  return new Promise((resolve, reject) => {
    auth0.parseHash((err, auth) => {
      if (err) {
        return reject(err);
      }

      if (!auth || !auth.idToken) {
        return reject(err);
      }

      resolve(auth);
    });
  })
    .then(auth => {
      // const roles = jwtDecode(auth.accessToken)[process.env.REACT_APP_ROLES_ENDPOINT];
      // if (roles.includes("Employer") || roles.includes("EmployerManager")) {
      dispatch(setCredentials(auth));
      return auth;
      // } else {
      //   history.replace("/");
      //   return dispatch(MetaActions.infoToast("Not Authorized!"));
      // }
    })
    .then(auth => {
      return ApolloClient.query({
        query: FetchMeQuery,
        variables: {
          authId: auth.idTokenPayload.sub,
        },
      });
    })
    .then(({ data: me }) => me.me)
    .then(me => {
      if (me.accountId && (me.accountType === "employer" || me.accountType === "employermanager")) {
        // dispatch(setMe(me));
        // dispatch(fetchProfile());
        history.replace("/");
        dispatch(checkSession());
      } else {
        history.replace("/");
        dispatch(MetaActions.errorToast("Not Authorized!"));
        dispatch(logout())
      }
    })
    .catch(e => {
      let message = `Error: ${e}`;
      if (e.error && (e.errorDescription || e.error_description)) {
        const description = e.errorDescription ? e.errorDescription : e.error_description;
        message = `Error: ${e.error} - ${description}`;
      }
      history.replace("/");
      dispatch(MetaActions.errorToast(message));
    });
};

export const login = (isResend = false) => (dispatch, getState) => {
  const { email } = getState().login;
  const payload = { send: "code" };
  dispatch(setIsLoginLoading(true));
  if (verifyEmail(email)) {
    return ApolloClient.query({
      query: CheckUserExistQuery,
      variables: {
        email,
      },
    })
      .then(({ data, errors }) => {
        if ((!data || !data.employerOrManagerIsExists) && errors.length > 0) {
          throw new Error(errors[0].message);
        }
        if (data.employerOrManagerIsExists && data.employerOrManagerIsExists.isRemoved && data.employerOrManagerIsExists.userExist) {
          dispatch(MetaActions.errorToast("Your account is removed. Please contact grizzly force support team."));
          dispatch(setIsLoginLoading(false));
          dispatch(setEmail(""))
        } else if (data.employerOrManagerIsExists && data.employerOrManagerIsExists.isActive === false) {
          dispatch(MetaActions.errorToast("Your account is disabled or not activated. Please contact grizzly force support team."));
          dispatch(setIsLoginLoading(false));
          dispatch(setEmail(""))
        } else {
          if (data.employerOrManagerIsExists && data.employerOrManagerIsExists.userExist) {
            auth0.passwordlessStart({ connection: "email", email: email, ...payload }, function (
              err,
              res,
            ) {
              if (res && res.Id && res.email) {
                dispatch(setIsUsernameVerify(true));
                if (isResend) {
                  dispatch(MetaActions.successToast("Code resend successfully"));
                }
              } else {
                dispatch(MetaActions.errorToast("Invalid Email"));
              }
              dispatch(setIsLoginLoading(false));
            });
          } else {
            dispatch(MetaActions.errorToast("Email not registred with Grizzlyforce"));
            dispatch(setIsLoginLoading(false));
          }
        }
      })
      .catch(e => {
        console.log(e);
        dispatch(setIsLoginLoading(false));
      });
  } else if (mobileValidator(email)) {
    return ApolloClient.query({
      query: CheckUserExistQuery,
      variables: {
        mobilePhone: email,
        countryCode: '+1'
      },
    })
      .then(({ data, errors }) => {
        if ((!data || !data.employerOrManagerIsExists) && errors.length > 0) {
          throw new Error(errors[0].message);
        }
        if (data.employerOrManagerIsExists && data.employerOrManagerIsExists.isRemoved && data.employerOrManagerIsExists.userExist) {
          dispatch(MetaActions.errorToast("Your account is removed. Please contact grizzly force support team."));
          dispatch(setIsLoginLoading(false));
          dispatch(setEmail(""))
        } else if (data.employerOrManagerIsExists && data.employerOrManagerIsExists.isActive === false) {
          dispatch(MetaActions.errorToast("Your account is disabled or not activated. Please contact grizzly force support team."));
          dispatch(setIsLoginLoading(false));
          dispatch(setEmail(""))
        } else {
          if (data.employerOrManagerIsExists && data.employerOrManagerIsExists.userExist) {
            auth0.passwordlessStart(
              { connection: "sms", phoneNumber: `+1${email}`, ...payload },
              function (err, res) {
                if (res && res.Id && res.phoneNumber) {
                  dispatch(setIsUsernameVerify(true));
                  if (isResend) {
                    dispatch(MetaActions.successToast("Code resend successfully"));
                  }
                } else {
                  dispatch(MetaActions.errorToast("Invalid Phone"));
                }
                dispatch(setIsLoginLoading(false));
              },
            );
          } else {
            dispatch(MetaActions.errorToast("Phone not registred with Grizzlyforce"));
            dispatch(setIsLoginLoading(false));
          }
        }
      })
      .catch(e => {
        console.log(e);
        dispatch(setIsLoginLoading(false));
      });
  } else {
    dispatch(MetaActions.errorToast("Invalid Email Or Phone"));
    dispatch(setIsLoginLoading(false));
    return;
  }
};

export const submitOTP = () => (dispatch, getState) => {
  const { otp, email } = getState().login;
  const payload = { verificationCode: otp };
  dispatch(setIsOtpLoading(true));
  if (verifyEmail(email)) {
    payload.connection = "email";
    payload.email = email;
  }
  if (mobileValidator(email)) {
    payload.connection = "sms";
    payload.phoneNumber = `+1${email}`;
  }
  auth0.passwordlessLogin(payload, function (err) {
    if (err) {
      // dispatch(setIsUsernameVerify(false));
      dispatch(setOTP(''));
      dispatch(MetaActions.errorToast(`Error: ${err.error} - ${err.error_description}`));
    }
    dispatch(setIsOtpLoading(false));
  });
};

export const sendPasswordReset = () => (dispatch, getState) => {
  const body = { email: getState().login.email, connection: process.env.REACT_APP_AUTH0_REALM };
  return new Promise((resolve, reject) => {
    auth0.changePassword(body, (err, resp) => {
      if (err) {
        return reject(`Error: ${err.description}`);
      }

      return resolve(resp);
    });
  })
    .then(resp => {
      dispatch(MetaActions.successToast(resp));
    })
    .catch(err => {
      dispatch(MetaActions.errorToast(`Error: ${err.description}`));
    });
};

export const logout = () => async dispatch => {
  dispatch(setIsLoggingOut(true));
  await auth0.logout({
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    returnTo: window.location.origin,
  });
  window.localStorage.setItem("logout", Date.now());
  window.localStorage.removeItem("persist:root");
  window.localStorage.removeItem("persist:subscriptions");
  sessionStorage.clear();
  setTimeout(() => {
    dispatch(resetForm());
    dispatch(setIsLoggingOut(false));
  }, 5000);
};

export const setTimezoneList = () => dispatch =>
  ApolloClient.query({ query: FetchTimezoneQuery })
    .then(({ data }) => dispatch({ type: types.SET_TIMEZONE_LIST, payload: data.timezones }))
    .catch(e => console.log(`getTimezoneList error: ${e}`));
