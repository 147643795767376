import React from "react";
import { ArrowBack } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import MobileStepper from '@mui/material/MobileStepper';
import { makeStyles } from "@mui/styles";
import MainContent from "components/Containers/MainContent";
import AddAnnouncement from "./components/AddAnnouncement";
import Budget from "./components/Budget";
import DateDetail from "./components/DateDetail";
import Location from "./components/Location";
import RequiredGear from "./components/RequiredGear";
import Review from "./components/Review";
import WorkType from "./components/WorkType";
import Template from "./components/Template";

const useStyles = makeStyles(() => ({
  stepperSection: {
    display: 'flex',
    justifyContent: 'end',
    padding: '6px',
    fontWeight: 'bold',
    '& .selected': {
      color: '#00c4a6'
    }
  },
  root: {
    padding: '0 8px',
    width: '200px'
  },
  progress: {
    height: '10px',
    borderRadius: '5px',
    backgroundColor: '#e3e3ec',
    width: '100%',
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#00c4a6'
    }
  }
}));
function PostJob(props) {
  const classes = useStyles();
  
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 8;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      <MainContent>
        {activeStep > 0 && (
          <Grid container direction="row" justifyContent={'space-between'}>
            <Grid item>
              <IconButton color="primary" onClick={handleBack} aria-label="back" component="span">
                <ArrowBack />
              </IconButton>
            </Grid>
            <Grid item>
              <div className={classes.stepperSection}>
                <span color="textSecondary" className="selected">{activeStep}</span>&nbsp;OF 7
                <MobileStepper
                  variant="progress"
                  steps={maxSteps}
                  position="static"
                  activeStep={activeStep}
                  sx={{ maxWidth: 200, flexGrow: 1 }}
                  classes={classes}
                />
              </div>
            </Grid>
          </Grid>
        )}
        {activeStep === 0 && <Template handleNext={handleNext} />}
        {activeStep === 1 && <WorkType handleNext={handleNext} handleBack={handleBack} />}
        {activeStep === 2 && <RequiredGear handleNext={handleNext} handleBack={handleBack} />}
        {/* {activeStep === 3 && <SuggestedSkills handleNext={handleNext} handleBack={handleBack} />} */}
        {activeStep === 3 && <Location handleNext={handleNext} handleBack={handleBack} />}
        {activeStep === 4 && <DateDetail handleNext={handleNext} handleBack={handleBack} />}
        {activeStep === 5 && <Budget handleNext={handleNext} handleBack={handleBack} />}
        {/* {activeStep === 7 && <AssignRoles  handleNext={handleNext} handleBack={handleBack} />} */}
        {activeStep === 6 && <AddAnnouncement handleNext={handleNext} handleBack={handleBack} />}
        {activeStep === 7 && <Review handleNext={handleNext} handleBack={handleBack} history={props.history} />}
      </MainContent>
    </>
  );
}

export default PostJob;
